
// const BASE_BUCKET_URL = 'https://asu-s3dl-global-scope.s3.amazonaws.com/public/';
export const BASE_BUCKET_URL = process.env.VUE_APP_BUCKET_URL;
export async function getBucketData(bucketData) {
  const response = await fetch(`${BASE_BUCKET_URL}${bucketData.filename}`,);
  if (!response.ok) {
    console.error(`Error fetching data - ${bucket}`)
    return [];
  }
  return response.json();
}
