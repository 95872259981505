// convert different spellings of country names into the standardized 2 letter country code
const countryToIsoCode = {
  'AFGHANISTAN': 'AF',
  'ALBANIA': 'AL',
  'ALGERIA': 'DZ',
  'AMERICAN SAMOA': 'AS',
  'ANDORRA': 'AD',
  'ANGOLA': 'AO',
  'ANTARCTICA': 'AQ',
  'ANTIGUA AND BARBUDA': 'AG',
  'ARGENTINA': 'AR',
  'ARMENIA': 'AM',
  'ARUBA': 'AW',
  'AUSTRALIA': 'AU',
  'AUSTRIA': 'AT',
  'AZERBAIJAN': 'AZ',
  'BAHAMAS': 'BS',
  'BAHRAIN': 'BH',
  'BANGLADESH': 'BD',
  'BARBADOS': 'BB',
  'BELARUS': 'BY',
  'BELGIUM': 'BE',
  'BELIZE': 'BZ',
  'BENIN': 'BJ',
  'BERMUDA': 'BM',
  'BHUTAN': 'BT',
  'BOLIVIA': 'BO',
  'BONAIRE, SINT EUSTATIUS AND SABA': 'BQ',
  'BONAIRE': 'BQ',
  'BOSNIA AND HERZEGOVINA': 'BA',
  'BOTSWANA': 'BW',
  'BRAZIL': 'BR',
  'BRITISH VIRGIN ISLANDS': 'VG',
  'BRUNEI': 'BN',
  'BRUNEI DARUSSALAM': 'BN',
  'BULGARIA': 'BG',
  'BURKINA FASO': 'BF',
  'MYANMAR': 'MM',
  'BURUNDI': 'BI',
  'CAMBODIA': 'KH',
  'CAMEROON': 'CM',
  'CANADA': 'CA',
  'CAPE VERDE': 'CV',
  'CAYMAN ISLANDS': 'KY',
  'CENTRAL AFRICAN REPUBLIC': 'CF',
  'CHAD': 'TD',
  'CHILE': 'CL',
  'CHINA': 'CN',
  'CHINA.': 'CN',
  'CHRISTMAS ISLAND': 'CX',
  'COLOMBIA': 'CO',
  'COMOROS': 'KM',
  'CONGO': 'CG',
  'CONGO,  DEMOCRATIC REPUBLIC OF': 'CD',
  'CONGO, DEMOCRATIC REPUBLIC OF': 'CD',
  'COSTA RICA': 'CR',
  "COTE D'IVOIRE": 'CI',
  'CROATIA': 'HR',
  'CUBA': 'CU',
  'CURACAO': 'CW',
  'CURAÇAO': 'CW',
  'CYPRUS': 'CY',
  'CZECH REPUBLIC': 'CZ',
  'DENMARK': 'DK',
  'DJIBOUTI': 'DJ',
  'DOMINICA': 'DM',
  'DOMINICAN REPUBLIC': 'DO',
  'ECUADOR': 'EC',
  'EGYPT': 'EG',
  'EL SALVADOR': 'SV',
  'EQUATORIAL GUINEA': 'GQ',
  'ERITREA': 'ER',
  'ESTONIA': 'EE',
  'ETHIOPIA': 'ET',
  'FIJI': 'FJ',
  'FINLAND': 'FI',
  'FRANCE': 'FR',
  'FRENCH POLYNESIA': 'PF',
  'GABON': 'GA',
  'GAMBIA': 'GM',
  'GEORGIA': 'GE',
  'GERMANY': 'DE',
  'GHANA': 'GH',
  'GREECE': 'GR',
  'AKROTIRI': 'GR',
  'GRENADA': 'GD',
  'GUADELOUPE': 'GP',
  'GUATEMALA': 'GT',
  'GUINEA': 'GN',
  'GUYANA': 'GY',
  'HAITI': 'HT',
  'HONDURAS': 'HN',
  'HONG KONG': 'HK',
  'HUNGARY': 'HU',
  'ICELAND': 'IS',
  'INDIA': 'IN',
  'INDONESIA': 'ID',
  'IRAN': 'IR',
  'IRAN (ISLAMIC REPUBLIC OF)': 'IR',
  'IRAQ': 'IQ',
  'IRELAND': 'IE',
  'ISRAEL': 'IL',
  'ITALY': 'IT',
  'JAMAICA': 'JM',
  'JAPAN': 'JP',
  'JERSEY': 'JE',
  'JORDAN': 'JO',
  'KAZAKHSTAN': 'KZ',
  'KAZAKSTAN': 'KZ',
  'KENYA': 'KE',
  'KIRIBATI': 'KI',
  'KUWAIT': 'KW',
  'KYRGYZSTAN': 'KG',
  'LAOS': 'LA',
  "LAO PEOPLE'S DEMOCRATIC REPUBLIC": 'LA',
  'LATVIA': 'LV',
  'LEBANON': 'LB',
  'LIBERIA': 'LR',
  'LIBYA': 'LY',
  'LIBYAN ARAB JAMAHIRIYA': 'LY',
  'LIECHTENSTEIN': 'LI',
  'LITHUANIA': 'LT',
  'LUXEMBOURG': 'LU',
  'MACAU': 'MO',
  'MACAO': 'MO',
  'MACEDONIA': 'MK',
  'MONACO': 'MC',
  'NORTH MACEDONIA': 'MK',
  'REPUBLIC OF NORTH MACEDONIA': 'MK',
  'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF': 'MK',
  'MACEDONIA, FORMER YUGOSLAV REP OF': 'MK',
  'MADAGASCAR': 'MG',
  'MALAWI': 'MW',
  'MALAYSIA': 'MY',
  'MALDIVES': 'MV',
  'MALI': 'ML',
  'MALTA': 'MT',
  'MAURITANIA': 'MR',
  'MAURITIUS': 'MU',
  'MEXICO': 'MX',
  'MICRONESIA, FEDERATED STATES': 'FM',
  'MICRONESIA, FEDERATED STATES OF': 'FM',
  'MOLDOVA': 'MD',
  'MOLDOVA, REPUBLIC OF': 'MD',
  'MONGOLIA': 'MN',
  'MOROCCO': 'MA',
  'MOZAMBIQUE': 'MZ',
  'NAMIBIA': 'NA',
  'NEPAL': 'NP',
  'NETHERLANDS': 'NL',
  'NEW CALEDONIA': 'NC',
  'NEW ZEALAND': 'NZ',
  'NICARAGUA': 'NI',
  'NIGER': 'NE',
  'NIGERIA': 'NG',
  'NORTH KOREA': 'KP',
  "KOREA, DEMOCRATIC PEOPLE'S REP OF": 'KP',
  'NORWAY': 'NO',
  'OMAN': 'OM',
  'PAKISTAN': 'PK',
  'PALESTINIAN TERRITORY, OCCUPIE': 'PS',
  'PALESTINE': 'PS',
  'PALESTINIAN TERRITORY, OCCUPIED': 'PS',
  'PANAMA': 'PA',
  'PAPUA NEW GUINEA': 'PG',
  'PARAGUAY': 'PY',
  'PERU': 'PE',
  'PHILIPPINES': 'PH',
  'POLAND': 'PL',
  'PORTUGAL': 'PT',
  'PUERTO RICO': 'PR',
  'QATAR': 'QA',
  'REPUBLIC OF MONTENEGRO': 'ME',
  'MONTENEGRO': 'ME',
  'ROMANIA': 'RO',
  'RUSSIA': 'RU',
  'RUSSIAN FEDERATION': 'RU',
  'RWANDA': 'RW',
  'SAINT HELENA': 'SH',
  'SAINT KITTS AND NEVIS': 'KN',
  'SAINT LUCIA': 'LC',
  'SAINT VINCENT AND THE GRENADINES': 'VC',
  'SAO TOME AND PRINCIPE': 'ST',
  'SAUDI ARABIA': 'SA',
  'SENEGAL': 'SN',
  'SÉNÉGAL': 'SN',
  'SERBIA': 'RS',
  'SIERRA LEONE': 'SL',
  'SINGAPORE': 'SG',
  'SLOVAKIA': 'SK',
  'SLOVENIA': 'SI',
  'SOMALIA': 'SO',
  'SOUTH AFRICA': 'ZA',
  'SOUTH KOREA': 'KR',
  'KOREA, REPUBLIC OF': 'KR',
  'SOUTH KOREA.': 'KR',
  'SOUTH SUDAN': 'SS',
  'SPAIN': 'ES',
  'SRI LANKA': 'LK',
  'SUDAN': 'SD',
  'SURINAME': 'SR',
  'SWAZILAND': 'SZ',
  'SWEDEN': 'SE',
  'SWITZERLAND': 'CH',
  'SYRIA': 'SY',
  'SYRIAN ARAB REPUBLIC': 'SY',
  'TAJIKISTAN': 'TJ',
  'TANZANIA': 'TZ',
  'TANZANIA, UNITED REPUBLIC OF': 'TZ',
  'TAIWAN': 'TW',
  'THAILAND': 'TH',
  'TIMOR-LESTE': 'TL',
  'TIMOR LESTE': 'TL',
  'TOGO': 'TG',
  'TRINIDAD AND TOBAGO': 'TT',
  'TUNISIA': 'TN',
  'TURKEY': 'TR',
  'TURKMENISTAN': 'TM',
  'TURKS AND CAICOS ISLANDS': 'TC',
  'UGANDA': 'UG',
  'UKRAINE': 'UA',
  'UNITED ARAB EMIRATES': 'AE',
  'UNITED KINGDOM': 'GB',
  'UNITED STATES': 'US',
  'USA': 'US',
  'URUGUAY': 'UY',
  'UZBEKISTAN': 'UZ',
  'VANUATU': 'VU',
  'VENEZUELA': 'VE',
  'VIETNAM': 'VN',
  'VIET NAM': 'VN',
  'WEST BANK': 'PS',
  'VIRGIN ISLANDS': 'VI',
  'UNITED STATES VIRGIN ISLANDS': 'VI',
  'VIRGIN ISLANDS (U.S.)': 'VI',
  'YEMEN': 'YE',
  'ZAMBIA': 'ZM',
  'ZIMBABWE': 'ZW',
  'REPUBLIC OF THE CONGO': 'CG',
  'DEMOCRATIC REPUBLIC OF THE CONGO': 'CD',
  'CENTRAL AFRICAN REP.': 'CF',
  'ESWATINI': 'SZ',
  'LESOTHO': 'LS',
  'EQ. GUINEA': 'GQ',
  'FR. S. AND ANTARCTIC LANDS': 'TF',
  'SEYCHELLES': 'SC',
  'HEARD I. AND MCDONALD IS.': 'HM',
  'S. GEO. AND THE IS.': 'GS',
  'FALKLAND IS.': 'FK',
  'S. SUDAN': 'SS',
  'CZECHIA': 'CZ',
  'KOSOVO': 'XK',
  "CÔTE D'IVOIRE": 'CI',
  'BOSNIA AND HERZ.': 'BA',
  'GUERNSEY': 'GG',
  'ÅLAND': 'AX',
  'W. SAHARA': 'EH',
  'DOMINICAN REP.': 'DO',
  'GUINEA-BISSAU': 'GW',
  'GREENLAND': 'GL',
  'TURKS AND CAICOS IS.': 'TC',
  'ST. PIERRE AND MIQUELON': 'PM',
  'ST. VIN. AND GREN.': 'VC',
  'MONTSERRAT': 'MS',
  'ANTIGUA AND BARB.': 'AG',
  'U.S. VIRGIN IS.': 'VI',
  'ANGUILLA': 'AI',
  'CAYMAN IS.': 'KY',
  'CABO VERDE': 'CV',
  'ISLE OF MAN': 'IM',
  'FAEROE ISLANDS': 'FO',
};

// use the standardized 2 letter country code to get data about the country
const isoCodeToCountry = {
  AF: {
    name: 'Afghanistan',
    id: 130,
    continent: 'Asia',
    latLong: ['33.0000', '65.0000'],
  },
  AL: {
    name: 'Albania',
    id: 64,
    continent: 'Europe',
    latLong: ['41.0000', '20.0000'],
  },
  DZ: {
    name: 'Algeria',
    id: 122,
    continent: 'Africa',
    latLong: ['28.0000', '3.0000'],
  },
  AS: {
    name: 'American Samoa',
    id: 243,
    continent: 'Oceania',
    latLong: ['-14.3333', '-170.0000'],
  },
  AD: {
    name: 'Andorra',
    id: 129,
    continent: 'Europe',
    latLong: ['42.5000', '1.5000'],
  },
  AO: {
    name: 'Angola',
    id: 103,
    continent: 'Africa',
    latLong: ['-12.5000', '18.5000'],
  },
  AQ: {
    name: 'Antarctica',
    id: 173,
    continent: '',
    latLong: ['-63.1900', '-57.5300'],
  },
  AG: {
    name: 'Antigua and Barbuda',
    id: 204,
    continent: 'North America',
    latLong: ['17.0500', '-61.8000'],
  },
  AR: {
    name: 'Argentina',
    id: 6,
    continent: 'South America',
    latLong: ['-34.0000', '-64.0000'],
  },
  AM: {
    name: 'Armenia',
    id: 71,
    continent: 'Asia',
    latLong: ['40.0000', '45.0000'],
  },
  AW: {
    name: 'Aruba',
    id: 183,
    continent: 'North America',
    latLong: ['12.5000', '-69.9667'],
  },
  AU: {
    name: 'Australia',
    id: 174,
    continent: 'Oceania',
    latLong: ['-27.0000', '133.0000'],
  },
  AT: {
    name: 'Austria',
    id: 90,
    continent: 'Europe',
    latLong: ['47.3333', '13.3333'],
  },
  AZ: {
    name: 'Azerbaijan',
    id: 65,
    continent: 'Asia',
    latLong: ['40.5000', '47.5000'],
  },
  BS: {
    name: 'Bahamas, The',
    id: 184,
    continent: 'North America',
    latLong: ['24.2500', '-76.0000'],
  },
  BH: {
    name: 'Bahrain',
    id: 247,
    continent: 'Asia',
    latLong: ['26.0000', '50.5500'],
  },
  BD: {
    name: 'Bangladesh',
    id: 128,
    continent: 'Asia',
    latLong: ['24.0000', '90.0000'],
  },
  BB: {
    name: 'Barbados',
    id: 199,
    continent: 'North America',
    latLong: ['13.1667', '-59.5333'],
  },
  BY: {
    name: 'Belarus',
    id: 36,
    continent: 'Europe',
    latLong: ['53.0000', '28.0000'],
  },
  BE: {
    name: 'Belgium',
    id: 61,
    continent: 'Europe',
    latLong: ['50.8333', '4.0000'],
  },
  BZ: {
    name: 'Belize',
    id: 157,
    continent: 'North America',
    latLong: ['17.2500', '-88.7500'],
  },
  BJ: {
    name: 'Benin',
    id: 102,
    continent: 'Africa',
    latLong: ['9.5000', '2.2500'],
  },
  BM: {
    name: 'Bermuda',
    id: 213,
    continent: 'North America',
    latLong: ['32.3333', '-64.7500'],
  },
  BT: {
    name: 'Bhutan',
    id: 34,
    continent: 'Asia',
    latLong: ['27.5000', '90.5000'],
  },
  BO: {
    name: 'Bolivia',
    id: 4,
    continent: 'South America',
    latLong: ['-17.0000', '-65.0000'],
  },
  BQ: {
    name: 'Bonaire, Sint Eustatius & Saba',
    continent: 'North America',
    latLong: ['12.1784', '-68.2385'],
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    id: 132,
    continent: 'Europe',
    latLong: ['44.0000', '18.0000'],
  },
  BW: {
    name: 'Botswana',
    id: 108,
    continent: 'Africa',
    latLong: ['-22.0000', '24.0000'],
  },
  BR: {
    name: 'Brazil',
    id: 46,
    continent: 'South America',
    latLong: ['-10.0000', '-55.0000'],
  },
  VG: {
    name: 'British Virgin Islands',
    id: 210,
    continent: 'North America',
    latLong: ['18.5000', '-64.5000'],
  },
  BN: {
    name: 'Brunei',
    id: 120,
    continent: 'Asia',
    latLong: ['4.5000', '114.6667'],
  },
  BG: {
    name: 'Bulgaria',
    id: 110,
    continent: 'Europe',
    latLong: ['43.0000', '25.0000'],
  },
  BF: {
    name: 'Burkina Faso',
    id: 149,
    continent: 'Africa',
    latLong: ['13.0000', '-2.0000'],
  },
  MM: {
    name: 'Myanmar',
    id: 127,
    continent: 'Asia',
    latLong: ['22.0000', '98.0000'],
  },
  BI: {
    name: 'Burundi',
    id: 125,
    continent: 'Africa',
    latLong: ['-3.5000', '30.0000'],
  },
  KH: {
    name: 'Cambodia',
    id: 58,
    continent: 'Asia',
    latLong: ['13.0000', '105.0000'],
  },
  CM: {
    name: 'Cameroon',
    id: 146,
    continent: 'Africa',
    latLong: ['6.0000', '12.0000'],
  },
  CA: {
    name: 'Canada',
    id: 155,
    continent: 'North America',
    latLong: ['60.0000', '-95.0000'],
  },
  CV: {
    name: 'Cape Verde',
    id: 219,
    continent: 'Africa',
    latLong: ['16.0000', '-24.0000'],
  },
  KY: {
    name: 'Cayman Islands',
    id: 212,
    continent: 'North America',
    latLong: ['19.5000', '-80.5000'],
  },
  CF: {
    name: 'Central African Republic',
    id: 86,
    continent: 'Africa',
    latLong: ['7.0000', '21.0000'],
  },
  TD: {
    name: 'Chad',
    id: 115,
    continent: 'Africa',
    latLong: ['15.0000', '19.0000'],
  },
  CL: {
    name: 'Chile',
    id: 3,
    continent: 'South America',
    latLong: ['-30.0000', '-71.0000'],
  },
  CN: {
    name: 'China',
    id: 10,
    continent: 'Asia',
    latLong: ['35.0000', '105.0000'],
  },
  CX: {
    name: 'Christmas Island',
    continent: 'Oceania',
    latLong: ['-10.5000', '105.6667'],
  },
  CO: {
    name: 'Colombia',
    id: 138,
    continent: 'South America',
    latLong: ['4.0000', '-72.0000'],
  },
  KM: {
    name: 'Comoros',
    id: 217,
    continent: 'Africa',
    latLong: ['-12.1667', '44.2500'],
  },
  CG: {
    name: 'Congo',
    id: 32,
    continent: 'Africa',
    latLong: ['-1.0000', '15.0000'],
  },
  CD: {
    name: 'Congo,  Democratic Republic of',
    id: 33,
    continent: 'Africa',
    latLong: ['0.0000', '25.0000'],
  },
  CR: {
    name: 'Costa Rica',
    id: 30,
    continent: 'North America',
    latLong: ['10.0000', '-84.0000'],
  },
  CI: {
    name: "Cote D'Ivoire",
    id: 97,
    continent: 'Africa',
    latLong: ['8.0000', '-5.0000'],
  },
  HR: {
    name: 'Croatia',
    id: 104,
    continent: 'Europe',
    latLong: ['45.1667', '15.5000'],
  },
  CU: {
    name: 'Cuba',
    id: 135,
    continent: 'North America',
    latLong: ['21.5000', '-80.0000'],
  },
  CW: {
    name: 'Curacao',
    id: 182,
    continent: 'North America',
    latLong: ['12.1696', '-68.9900'],
  },
  CY: {
    name: 'Cyprus',
    id: 8,
    continent: 'Asia',
    latLong: ['35.0000', '33.0000'],
  },
  CZ: {
    name: 'Czech Republic',
    id: 50,
    continent: 'Europe',
    latLong: ['49.7500', '15.5000'],
  },
  DK: {
    name: 'Denmark',
    id: 72,
    continent: 'Europe',
    latLong: ['56.0000', '10.0000'],
  },
  DJ: {
    name: 'Djibouti',
    id: 88,
    continent: 'Africa',
    latLong: ['11.5000', '43.0000'],
  },
  DM: {
    name: 'Dominica',
    id: 201,
    continent: 'North America',
    latLong: ['15.4167', '-61.3333'],
  },
  DO: {
    name: 'Dominican Republic',
    id: 114,
    continent: 'North America',
    latLong: ['19.0000', '-70.6667'],
  },
  EC: {
    name: 'Ecuador',
    id: 137,
    continent: 'South America',
    latLong: ['-2.0000', '-77.5000'],
  },
  EG: {
    name: 'Egypt',
    id: 161,
    continent: 'Africa',
    latLong: ['27.0000', '30.0000'],
  },
  SV: {
    name: 'El Salvador',
    id: 117,
    continent: 'North America',
    latLong: ['13.8333', '-88.9167'],
  },
  GQ: {
    name: 'Equatorial Guinea',
    id: 164,
    continent: 'Africa',
    latLong: ['2.0000', '10.0000'],
  },
  ER: {
    name: 'Eritrea',
    id: 89,
    continent: 'Africa',
    latLong: ['15.0000', '39.0000'],
  },
  EE: {
    name: 'Estonia',
    id: 52,
    continent: 'Europe',
    latLong: ['59.0000', '26.0000'],
  },
  ET: {
    name: 'Ethiopia',
    id: 14,
    continent: 'Africa',
    latLong: ['8.0000', '38.0000'],
  },
  FJ: {
    name: 'Fiji',
    id: 176,
    continent: 'Oceania',
    latLong: ['-17.8600', '-182.029'],
  },
  FI: {
    name: 'Finland',
    id: 56,
    continent: 'Europe',
    latLong: ['64.0000', '26.0000'],
  },
  FR: {
    name: 'France',
    id: 23,
    continent: 'Europe',
    latLong: ['46.0000', '2.0000'],
  },
  PF: {
    name: 'French Polynesia',
    id: 191,
    continent: 'Oceania',
    latLong: ['-15.0000', '-140.0000'],
  },
  GA: {
    name: 'Gabon',
    id: 147,
    continent: 'Africa',
    latLong: ['-1.0000', '11.7500'],
  },
  GM: {
    name: 'Gambia, The',
    id: 165,
    continent: 'Africa',
    latLong: ['13.4667', '-16.5667'],
  },
  GE: {
    name: 'Georgia',
    id: 62,
    continent: 'Asia',
    latLong: ['42.0000', '43.5000'],
  },
  DE: {
    name: 'Germany',
    id: 51,
    continent: 'Europe',
    latLong: ['51.0000', '9.0000'],
  },
  GH: {
    name: 'Ghana',
    id: 151,
    continent: 'Africa',
    latLong: ['8.0000', '-2.0000'],
  },
  GR: {
    name: 'Greece',
    id: 81,
    continent: 'Europe',
    latLong: ['39.0000', '22.0000'],
  },
  GL: {
    name: 'Greenland',
    id: 175,
    continent: 'North America',
    latLong: ['67.7069', '-42.6043'],
  },
  GD: {
    name: 'Grenada',
    id: 197,
    continent: 'North America',
    latLong: ['12.1167', '-61.6667'],
  },
  GT: {
    name: 'Guatemala',
    id: 118,
    continent: 'North America',
    latLong: ['15.5000', '-90.2500'],
  },
  GP: {
    name: 'Guadeloupe',
    // id: ,
    continent: 'North America',
    latLong: ['16.2650', '-61.5510'],
  },
  GN: {
    name: 'Guinea',
    id: 84,
    continent: 'Africa',
    latLong: ['11.0000', '-10.0000'],
  },
  GY: {
    name: 'Guyana',
    id: 25,
    continent: 'South America',
    latLong: ['5.0000', '-59.0000'],
  },
  HT: {
    name: 'Haiti',
    id: 113,
    continent: 'North America',
    latLong: ['19.0000', '-72.4167'],
  },
  HN: {
    name: 'Honduras',
    id: 136,
    continent: 'North America',
    latLong: ['15.0000', '-86.5000'],
  },
  HK: {
    name: 'Hong Kong',
    id: 166,
    continent: 'Asia',
    latLong: ['22.2500', '114.1667'],
  },
  HU: {
    name: 'Hungary',
    id: 76,
    continent: 'Europe',
    latLong: ['47.0000', '20.0000'],
  },
  IS: {
    name: 'Iceland',
    id: 189,
    continent: 'Europe',
    latLong: ['65.0000', '-18.0000'],
  },
  IN: {
    name: 'India',
    id: 9,
    continent: 'Asia',
    latLong: ['20.0000', '77.0000'],
  },
  ID: {
    name: 'Indonesia',
    id: 1,
    continent: 'Asia',
    latLong: ['-5.0000', '120.0000'],
  },
  IR: {
    name: 'Iran',
    id: 94,
    continent: 'Asia',
    latLong: ['32.0000', '53.0000'],
  },
  IQ: {
    name: 'Iraq',
    id: 91,
    continent: 'Asia',
    latLong: ['33.0000', '44.0000'],
  },
  IE: {
    name: 'Ireland',
    id: 79,
    continent: 'Europe',
    latLong: ['53.0000', '-8.0000'],
  },
  IL: {
    name: 'Israel',
    id: 11,
    continent: 'Asia',
    latLong: ['31.5000', '34.7500'],
  },
  IT: {
    name: 'Italy',
    id: 92,
    continent: 'Europe',
    latLong: ['42.8333', '12.8333'],
  },
  JM: {
    name: 'Jamaica',
    id: 211,
    continent: 'North America',
    latLong: ['18.2500', '-77.5000'],
  },
  JP: {
    name: 'Japan',
    id: 187,
    continent: 'Asia',
    latLong: ['36.0000', '138.0000'],
  },
  JE: {
    name: 'Jersey',
    id: 221,
    continent: 'Europe',
    latLong: ['49.2144', '-2.1312'],
  },
  JO: {
    name: 'Jordan',
    id: 143,
    continent: 'Asia',
    latLong: ['31.0000', '36.0000'],
  },
  KZ: {
    name: 'Kazakhstan',
    id: 43,
    continent: 'Asia',
    latLong: ['48.0000', '68.0000'],
  },
  KE: {
    name: 'Kenya',
    id: 17,
    continent: 'Africa',
    latLong: ['1.0000', '38.0000'],
  },
  KI: {
    name: 'Kiribati',
    id: 194,
    continent: 'Oceania',
    latLong: ['1.82', '-157.3600'],
  },
  KW: {
    name: 'Kuwait',
    id: 116,
    continent: 'Asia',
    latLong: ['29.3375', '47.6581'],
  },
  KG: {
    name: 'Kyrgyzstan',
    id: 70,
    continent: 'Asia',
    latLong: ['41.0000', '75.0000'],
  },
  LA: {
    name: 'Laos',
    id: 69,
    continent: 'Asia',
    latLong: ['18.0000', '105.0000'],
  },
  LV: {
    name: 'Latvia',
    id: 53,
    continent: 'Europe',
    latLong: ['57.0000', '25.0000'],
  },
  LB: {
    name: 'Lebanon',
    id: 13,
    continent: 'Asia',
    latLong: ['33.8333', '35.8333'],
  },
  LR: {
    name: 'Liberia',
    id: 85,
    continent: 'Africa',
    latLong: ['6.5000', '-9.5000'],
  },
  LY: {
    name: 'Libya',
    id: 73,
    continent: 'Africa',
    latLong: ['25.0000', '17.0000'],
  },
  LI: {
    name: 'Liechtenstein',
    id: 96,
    continent: 'Europe',
    latLong: ['47.1667', '9.5333'],
  },
  LT: {
    name: 'Lithuania',
    id: 45,
    continent: 'Europe',
    latLong: ['56.0000', '24.0000'],
  },
  LU: {
    name: 'Luxembourg',
    id: 59,
    continent: 'Europe',
    latLong: ['49.7500', '6.1667'],
  },
  MC: {
    name: 'Monaco',
    id: 121,
    continent: 'Europe',
    latLong: ['43.7300', '7.4200'],
  },
  MO: {
    name: 'Macau',
    id: 251,
    continent: 'Asia',
    latLong: ['22.1667', '113.5500'],
  },
  MK: {
    name: 'Macedonia',
    id: 63,
    continent: 'Europe',
    latLong: ['41.8333', '22.0000'],
  },
  MG: {
    name: 'Madagascar',
    id: 179,
    continent: 'Africa',
    latLong: ['-20.0000', '47.0000'],
  },
  MW: {
    name: 'Malawi',
    id: 19,
    continent: 'Africa',
    latLong: ['-13.5000', '34.0000'],
  },
  MY: {
    name: 'Malaysia',
    id: 2,
    continent: 'Asia',
    latLong: ['2.5000', '112.5000'],
  },
  MV: {
    name: 'Maldives',
    continent: 'Asia',
    latLong: ['3.2500', '73.0000'],
  },
  ML: {
    name: 'Mali',
    id: 99,
    continent: 'Africa',
    latLong: ['17.0000', '-4.0000'],
  },
  MT: {
    name: 'Malta',
    id: 220,
    continent: 'Europe',
    latLong: ['35.8333', '14.5833'],
  },
  MR: {
    name: 'Mauritania',
    id: 163,
    continent: 'Africa',
    latLong: ['20.0000', '-12.0000'],
  },
  MU: {
    name: 'Mauritius',
    id: 216,
    continent: 'Africa',
    latLong: ['-20.2833', '57.5500'],
  },
  MX: {
    name: 'Mexico',
    id: 156,
    continent: 'North America',
    latLong: ['23.0000', '-102.0000'],
  },
  FM: {
    name: 'Micronesia, Federated States',
    id: 238,
    continent: 'Oceania',
    latLong: ['6.9167', '158.2500'],
  },
  MD: {
    name: 'Moldova',
    id: 141,
    continent: 'Europe',
    latLong: ['47.0000', '29.0000'],
  },
  MN: {
    name: 'Mongolia',
    id: 48,
    continent: 'Asia',
    latLong: ['46.0000', '105.0000'],
  },
  MA: {
    name: 'Morocco',
    id: 28,
    continent: 'Africa',
    latLong: ['32.0000', '-5.0000'],
  },
  MZ: {
    name: 'Mozambique',
    id: 123,
    continent: 'Africa',
    latLong: ['-18.2500', '35.0000'],
  },
  NA: {
    name: 'Namibia',
    id: 37,
    continent: 'Africa',
    latLong: ['-22.0000', '17.0000'],
  },
  NP: {
    name: 'Nepal',
    id: 144,
    continent: 'Asia',
    latLong: ['28.0000', '84.0000'],
  },
  NL: {
    name: 'Netherlands',
    id: 95,
    continent: 'Europe',
    latLong: ['52.5000', '5.7500'],
  },
  NC: {
    name: 'New Caledonia',
    id: 178,
    continent: 'Oceania',
    latLong: ['-21.5000', '165.5000'],
  },
  NZ: {
    name: 'New Zealand',
    id: 177,
    continent: 'Oceania',
    latLong: ['-41.0000', '174.0000'],
  },
  NI: {
    name: 'Nicaragua',
    id: 31,
    continent: 'North America',
    latLong: ['13.0000', '-85.0000'],
  },
  NE: {
    name: 'Niger',
    id: 148,
    continent: 'Africa',
    latLong: ['16.0000', '8.0000'],
  },
  NG: {
    name: 'Nigeria',
    id: 101,
    continent: 'Africa',
    latLong: ['10.0000', '8.0000'],
  },
  KP: {
    name: 'North Korea',
    id: 27,
    continent: 'Asia',
    latLong: ['40.0000', '127.0000'],
  },
  NO: {
    name: 'Norway',
    id: 54,
    continent: 'Europe',
    latLong: ['62.0000', '10.0000'],
  },
  OM: {
    name: 'Oman',
    id: 41,
    continent: 'Asia',
    latLong: ['21.0000', '57.0000'],
  },
  PK: {
    name: 'Pakistan',
    id: 18,
    continent: 'Asia',
    latLong: ['30.0000', '70.0000'],
  },
  PS: {
    name: 'Palestinian Territory, Occupied',
    id: 12,
    continent: 'Asia',
    latLong: ['32.0000', '35.2500'],
  },
  PA: {
    name: 'Panama',
    id: 158,
    continent: 'North America',
    latLong: ['9.0000', '-80.0000'],
  },
  PG: {
    name: 'Papua New Guinea',
    id: 160,
    continent: 'Oceania',
    latLong: ['-6.3150', '143.9555'],
  },
  PY: {
    name: 'Paraguay',
    id: 139,
    continent: 'South America',
    latLong: ['-23.0000', '-58.0000'],
  },
  PE: {
    name: 'Peru',
    id: 5,
    continent: 'South America',
    latLong: ['-10.0000', '-76.0000'],
  },
  PH: {
    name: 'Philippines',
    id: 180,
    continent: 'Asia',
    latLong: ['13.0000', '122.0000'],
  },
  PL: {
    name: 'Poland',
    id: 78,
    continent: 'Europe',
    latLong: ['52.0000', '20.0000'],
  },
  PT: {
    name: 'Portugal',
    id: 140,
    continent: 'Europe',
    latLong: ['39.5000', '-8.0000'],
  },
  PR: {
    name: 'Puerto Rico',
    id: 208,
    continent: 'North America',
    latLong: ['18.2500', '-66.5000'],
  },
  QA: {
    name: 'Qatar',
    id: 106,
    continent: 'Asia',
    latLong: ['25.5000', '51.2500'],
  },
  ME: {
    name: 'Republic of Montenegro',
    id: 131,
    continent: 'Europe',
    latLong: ['42.0000', '19.0000'],
  },
  RO: {
    name: 'Romania',
    id: 75,
    continent: 'Europe',
    latLong: ['46.0000', '25.0000'],
  },
  RU: {
    name: 'Russia',
    id: 49,
    continent: 'Europe',
    latLong: ['60.0000', '100.0000'],
  },
  RW: {
    name: 'Rwanda',
    id: 126,
    continent: 'Africa',
    latLong: ['-2.0000', '30.0000'],
  },
  SH: {
    name: 'Saint Helena',
    continent: 'Africa',
    latLong: ['-15.9333', '-5.7000'],
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    id: 205,
    continent: 'North America',
    latLong: ['17.3333', '-62.7500'],
  },
  LC: {
    name: 'Saint Lucia',
    id: 200,
    continent: 'North America',
    latLong: ['13.8833', '-61.1333'],
  },
  VC: {
    name: 'Saint Vincent & the Grenadines',
    id: 198,
    continent: 'North America',
    latLong: ['13.2500', '-61.2000'],
  },
  ST: {
    name: 'Sao Tome and Principe',
    id: 218,
    continent: 'Africa',
    latLong: ['1.0000', '7.0000'],
  },
  SA: {
    name: 'Saudi Arabia',
    id: 107,
    continent: 'Asia',
    latLong: ['25.0000', '45.0000'],
  },
  SN: {
    name: 'Senegal',
    id: 100,
    continent: 'Africa',
    latLong: ['14.0000', '-14.0000'],
  },
  RS: {
    name: 'Serbia',
    id: 98,
    continent: 'Europe',
    latLong: ['44.0000', '21.0000'],
  },
  SL: {
    name: 'Sierra Leone',
    id: 83,
    continent: 'Africa',
    latLong: ['8.5000', '-11.5000'],
  },
  SG: {
    name: 'Singapore',
    id: 228,
    continent: 'Asia',
    latLong: ['1.3667', '103.8000'],
  },
  SK: {
    name: 'Slovakia',
    id: 77,
    continent: 'Europe',
    latLong: ['48.6667', '19.5000'],
  },
  SI: {
    name: 'Slovenia',
    id: 105,
    continent: 'Europe',
    latLong: ['46.0000', '15.0000'],
  },
  SO: {
    name: 'Somalia',
    id: 16,
    continent: 'Africa',
    latLong: ['10.0000', '49.0000'],
  },
  ZA: {
    name: 'South Africa',
    id: 38,
    continent: 'Africa',
    latLong: ['-29.0000', '24.0000'],
  },
  KR: {
    name: 'South Korea',
    id: 26,
    continent: 'Asia',
    latLong: ['37.0000', '127.5000'],
  },
  SS: {
    name: 'South Sudan',
    id: 15,
    continent: 'Africa',
    latLong: ['6.8770', '31.3070'],
  },
  ES: {
    name: 'Spain',
    id: 68,
    continent: 'Europe',
    latLong: ['40.0000', '-4.0000'],
  },
  LK: {
    name: 'Sri Lanka',
    id: 181,
    continent: 'Asia',
    latLong: ['7.0000', '81.0000'],
  },
  SD: {
    name: 'Sudan',
    id: 87,
    continent: 'Africa',
    latLong: ['15.0000', '30.0000'],
  },
  SR: {
    name: 'Suriname',
    id: 24,
    continent: 'South America',
    latLong: ['4.0000', '-56.0000'],
  },
  SZ: {
    name: 'Swaziland',
    id: 124,
    continent: 'Africa',
    latLong: ['-26.5000', '31.5000'],
  },
  LS: {
    name: 'Lesotho',
    id: 202,
    continent: 'Africa',
    latLong: ['-29.0000', '28.0000'],
  },
  SE: {
    name: 'Sweden',
    id: 55,
    continent: 'Europe',
    latLong: ['62.0000', '15.0000'],
  },
  CH: {
    name: 'Switzerland',
    id: 93,
    continent: 'Europe',
    latLong: ['47.0000', '8.0000'],
  },
  SY: {
    name: 'Syria',
    id: 21,
    continent: 'Asia',
    latLong: ['35.0000', '38.0000'],
  },
  TJ: {
    name: 'Tajikistan',
    id: 44,
    continent: 'Asia',
    latLong: ['39.0000', '71.0000'],
  },
  TZ: {
    name: 'Tanzania',
    id: 20,
    continent: 'Africa',
    latLong: ['-6.0000', '35.0000'],
  },
  TL: {
    name: 'Timor-Leste',
    id: 188,
    continent: 'Asia',
    latLong: ['-9.0000', '126.0000'],
  },
  TH: {
    name: 'Thailand',
    id: 111,
    continent: 'Asia',
    latLong: ['15.0000', '100.0000'],
  },
  TG: {
    name: 'Togo',
    id: 150,
    continent: 'Africa',
    latLong: ['8.0000', '1.1667'],
  },
  TT: {
    name: 'Trinidad and Tobago',
    id: 196,
    continent: 'North America',
    latLong: ['11.0000', '-61.0000'],
  },
  TN: {
    name: 'Tunisia',
    id: 74,
    continent: 'Africa',
    latLong: ['34.0000', '9.0000'],
  },
  TR: {
    name: 'Turkey',
    id: 67,
    continent: 'Asia',
    latLong: ['39.0000', '35.0000'],
  },
  TM: {
    name: 'Turkmenistan',
    id: 142,
    continent: 'Asia',
    latLong: ['40.0000', '60.0000'],
  },
  TC: {
    name: 'Turks and Caicos Islands',
    id: 185,
    continent: 'North America',
    latLong: ['21.7500', '-71.5833'],
  },
  UG: {
    name: 'Uganda',
    id: 133,
    continent: 'Africa',
    latLong: ['1.0000', '32.0000'],
  },
  UA: {
    name: 'Ukraine',
    id: 35,
    continent: 'Europe',
    latLong: ['49.0000', '32.0000'],
  },
  AE: {
    name: 'United Arab Emirates',
    id: 60,
    continent: 'Asia',
    latLong: ['24.0000', '54.0000'],
  },
  GB: {
    name: 'United Kingdom',
    id: 80,
    continent: 'Europe',
    latLong: ['54.0000', '-2.0000'],
  },
  TW: {
    name: 'Taiwan',
    id: 186,
    continent: 'Asia',
    latLong: ['23.6978', '120.9605'],
  },
  US: {
    name: 'United States',
    id: 154,
    continent: 'North America',
    latLong: ['38.0000', '-97.0000'],
  },
  UY: {
    name: 'Uruguay',
    id: 47,
    continent: 'South America',
    latLong: ['-33.0000', '-56.0000'],
  },
  UZ: {
    name: 'Uzbekistan',
    id: 42,
    continent: 'Asia',
    latLong: ['41.0000', '64.0000'],
  },
  VE: {
    name: 'Venezuela',
    id: 159,
    continent: 'South America',
    latLong: ['8.0000', '-66.0000'],
  },
  VN: {
    name: 'Vietnam',
    id: 57,
    continent: 'Asia',
    latLong: ['16.0000', '106.0000'],
  },
  VI: {
    name: 'Virgin Islands',
    id: 206,
    continent: 'North America',
    latLong: ['18.3333', '-64.8333'],
  },
  VU: {
    name: 'Vanuatu',
    id: 241,
    continent: 'Oceania',
    latLong: ['-15.3767', '166.9592'],
  },
  XK: {
    name: 'Kosovo',
    id: 66,
    continent: 'Europe',
    latLong: ['42.6', '20.90'],
  },
  YE: {
    name: 'Yemen',
    id: 162,
    continent: 'Asia',
    latLong: ['15.0000', '48.0000'],
  },
  ZM: {
    name: 'Zambia',
    id: 82,
    continent: 'Africa',
    latLong: ['-15.0000', '30.0000'],
  },
  ZW: {
    name: 'Zimbabwe',
    id: 109,
    continent: 'Africa',
    latLong: ['-20.0000', '30.0000'],
  },
};

function normalizeCountryName(name) {
  return name
    .toUpperCase()
    .replace(/, THE$/, '').replace(/^THE /, '') // remove initial or final 'The'
    .replace('&', 'AND');
}

// To handle the non-standard names in our dataset, we have pre-populated the lookup tables above.
// But countries change often; to reduce the frequency with which we need to manually update our tables,
// fetch up-to-date country info from an API to try to fill in the gaps.
async function initializeCountries() {
  // try {
  //   const data = await fetch('https://restcountries.com/v2/all');
  //   const json = await data.json();
  //   if (!json) {
  //     return;
  //   }

  //   // was just used for building the countriesData.js file
  //   // const newJson = {}
  //   // json.forEach(country => {
  //   //   newJson[country.alpha2Code] =
  //   //   {
  //   //     "population": country.population,
  //   //     "timezones": country.timezones,
  //   //     "currencies": country.currencies,
  //   //     "languages": country.languages,
  //   //     "subregion": country.region,
  //   //     "capital": country.capital,
  //   //     "latlng": country.latlng,
  //   //     "demonym": country.demonym
  //   //   }
  //   // });

  //   json.forEach((country, index) => {
  //     if (country.altSpellings == null) {
  //       return;
  //     }
  //     // Gather up all country names that the API can provide: name, nativeName, altSpellings, translations
  //     const countryNames = country.altSpellings.concat([country.translations.de, country.translations.fr, country.translations.es].filter(name => name != null));
  //     countryNames.push(country.name);
  //     countryNames.push(country.nativeName);
  //     /* eslint-disable no-return-assign */
  //     countryNames.forEach(name => countryToIsoCode[normalizeCountryName(name)] = country.alpha2Code);
  //     /* eslint-enable no-return-assign */

  //     const ltlng = country.latlng;
  //     if (ltlng != null) {
  //       country.latlng.map(coord => `${coord}`);
  //     }
  //     if (isoCodeToCountry[country.alpha2Code] == null) {
  //       isoCodeToCountry[country.alpha2Code] = {
  //         name: country.name,
  //         id: 300 + index,
  //         continent: country.region,
  //         latLong: ltlng,
  //       };
  //     }
  //   });
  // } catch (e) {
  //   // we don't want to crash the whole site if for some reason the restcountries api is down
  //   console.log(`error: ${e}`);
  // }
}

function lookupIsoCode(name) {
  return countryToIsoCode[normalizeCountryName(name)];
}

export { initializeCountries, lookupIsoCode, isoCodeToCountry };
