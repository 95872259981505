<template>
<div class="bottom-nav-container">
  <div class="bottom-nav">
    <div class="activities" v-if="!isMobile">
      <div
        v-for="location in sortedSummaryData"
        :key="location[0]"
        @mouseover="$emit('locationhover', $event)"
        @mouseleave="$emit('locationhover', $event)"
        @click="onClickLocation"
        class="container--activity"
        tabindex="0"
        data-test="continent-summary-locations"
      >
        <span class="location">{{ location[0] }}:</span> {{ location[1] }}
      </div>
    </div>
    <div class="disclaimer">
      Disclaimer: Data shown is comprised of multiple data sets and date ranges.
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { convertSpacesToDashes } from '../helpers/helpers';
import { continentBounds } from '../data/continentData';

export default {
  computed: {
    ...mapState(['selectedContinent', 'searchTerm', 'selectedBuckets', 'isMobile', 'windowWidth']),
    ...mapGetters(['selectedSummaryData']),
    sortedSummaryData() {
      const sortedData = Object.entries(this.selectedSummaryData)
        .map((arr) => {
          if (arr[1].total !== undefined) {
            // currently continents have a 'total' key, while countries just have Country: Number
            return [arr[0], arr[1].total];
          }
          return arr;
        })
        .sort((a, b) => b[1] - a[1]);
      return sortedData.slice(0, this.numEntriesDisplayed); // only display first few entries
    },
    numEntriesDisplayed() {
      if (this.windowWidth > 1600) {
        return 7;
      }
      if (this.windowWidth > 1450) {
        return 6;
      }
      if (this.windowWidth > 1160) {
        return 5;
      }
      if (this.windowWidth > 820) {
        return 4;
      }
      return 2;
    },
    allEntriesDisplayed() {
      return (
        this.numEntriesDisplayed >= Object.keys(this.selectedSummaryData).length
      );
    },
  },
  methods: {
    onClickLocation(e) {
      const location = e.target.innerText.split(':')[0];
      const isContinent = !!continentBounds[location];
      if (isContinent) {
        this.$router.push({
          path: `/${convertSpacesToDashes(location)}`,
          query: {
            selectedBuckets: this.selectedBuckets.toString(),
          },
        });
      } else {
        this.$router.push({
          path: convertSpacesToDashes(location),
          append: true,
        });
      }
    },
  },

};
</script>

<style scoped>
.bottom-nav-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
.bottom-nav {
  position: relative;
  background-color: white;
  color: #000000;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.activities {
  display: flex;
  padding: 0 20px;
}
.container--activity {
  margin: 20px 12px;
}
.container--activity:hover {
  cursor: pointer;
  color: var(--asu-maroon-hover)
}
.container--activity:last-child {
  border-bottom: none;
}
.top-activity {
  display: inline-block;
  color: black;
  margin: 0 0 0 20px;
  width: 80px;
}
.location {
  font-weight: 700;
}
.disclaimer {
  background-color: #f5f5f5;
  padding: 10px 0;
  text-align: center;
}
@media only screen and (max-width: 1000px),
  only screen and (max-device-width: 1000px)
  and (orientation: landscape) {
    .bottom-nav {
      width: 100%;
      border: none;
    }
    .disclaimer {
      background-color: #ffffff;
      padding: 20px 0;
    }
}
</style>
