<template>
  <div v-if="selectedCountryInSearch">
    <div class="card">
      <button @click="onClickBucket('Overview')" class="card--button-container">
        <div class="card--country-img-container">
          <img
            v-bind:class="{
              'card--country-img': true,
              'card--country-img-not-available': noCountryImage,
            }"
            @load="onImageLoadSuccess()"
            @error="onImageLoadFailure($event)"
            :src="`${BASE_BUCKET_URL}images/${selectedCountryInSearch.isoCode}_country_image.jpg`"
          />
          <span
            v-if="noCountryImage"
            class="card--country-img-not-available-text"
          >
            Photo not available<br />for this location
          </span>
        </div>
        <div class="card--text">
          <div class="results">
            <div class="activity-summary">
              <h2 data-test="search-result-country-name">
                {{ selectedCountryInSearch.country }}
              </h2>
            </div>
            <p>View country details</p>
          </div>
        </div>
      </button>
    </div>
    <div
      v-for="bucket in searchResultsByBucket"
      v-bind:key="bucket.name"
      class="card"
    >
      <button
        @click="onClickBucket(bucket.name)"
        class="card--button-container"
      >
        <img class="card--img" :src="bucketsData[bucket.name].image" />
        <div class="card--text">
          <div class="results" data-test="search-result-bucket">
            <div class="activity-summary">
              <h2>{{ bucket.name }}</h2>
            </div>
            <div data-test="search-result-activity-count">
              {{ bucket.total }} {{ "Result" | pluralize(bucket.total) }}
            </div>
          </div>
          <div>
            <div class="country-name">
              {{ selectedCountryInSearch.country }}
            </div>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { lookupIsoCode } from "../data/countryCodeLookup";
import { convertSpacesToDashes } from "../helpers/helpers";

export default {
  data() {
    return {
      noCountryImage: false,
      imgError: false,
    };
  },
  computed: {
    ...mapState([
      "buckets",
      "bucketsData",
      "selectedCountryInSearch",
      "BASE_BUCKET_URL",
      "bucketSummaryData",
    ]),
    ...mapGetters(["filteredSearchData"]),
    searchResultsByBucket() {
      const parsed = [];
      this.buckets.forEach((bucket) => {
        let items = 0;
        try {
          // get the number of activities in this bucket and country
          items =
            this.bucketSummaryData[bucket].continents[
              this.selectedCountryInSearch.continent
            ].countries[lookupIsoCode(this.selectedCountryInSearch.country)];
        } catch {} // no activities found for this bucket and country
        if (items > 0) {
          parsed.push({ name: bucket, total: items });
        }
      });
      return parsed;
    },
  },
  filters: {
    pluralize(value, number) {
      return number === 1 ? value : `${value}s`;
    },
  },
  methods: {
    onClickBucket(bucket) {
      this.$router.push({
        path: `/${convertSpacesToDashes(
          this.selectedCountryInSearch.continent
        )}/${convertSpacesToDashes(this.selectedCountryInSearch.country)}`,
        query: {
          activity: bucket,
        },
      });
    },
    /* eslint-disable no-param-reassign */
    onImageLoadSuccess() {
      if (this.imgError) {
        this.imgError = false;
        this.noCountryImage = true;
      } else {
        this.noCountryImage = false;
      }
    },
    /* eslint-disable no-param-reassign */
    onImageLoadFailure(event) {
      // if the country image is not found, resort to a default image
      if (this.imgError) return;
      this.imgError = true;
      event.target.src = `${this.BASE_BUCKET_URL}images/Default_country_image.jpg`;
    },
  },
};
</script>

<style scoped>
.card {
  margin: 20px 30px;
  background-color: #ffffff;
  border: 1px solid #d2d2d2;
}
.card:hover {
  border-color: var(--asu-gold);
  box-shadow: 0 0 10px var(--asu-gold);
}
.card--button-container {
  width: 100%;
}
.card--country-img-container {
  position: relative;
  width: 100%;
}
.card--img,
.card--country-img {
  object-fit: cover;
  width: 100%;
  min-height: 100px;
  max-height: 180px;
  border-bottom: 1px solid #d2d2d2;
}
.card--country-img-not-available {
  filter: grayscale(100%);
}
.card--country-img-not-available-text {
  position: absolute;
  bottom: 5px;
  right: 10px;
  text-align: right;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
}
.card--text {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  color: #000000;
  width: 100%;
  text-align: start;
}
.card--text h2 {
  font-size: 16px;
}
.results {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.activity-summary {
  display: flex;
  align-items: center;
}
.country-name {
  padding-bottom: 4px;
  border-bottom: 4px solid var(--asu-gold);
  text-align: end;
}
</style>
