var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay"},[_c('div',{staticClass:"country",attrs:{"data-test":"country-details-main-div"}},[(!_vm.isMobile)?_c('div',{staticClass:"country-header"},[_c('a',{attrs:{"data-test":"country-details-back-arrow"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fas fa-arrow-left arrow-icon"}),_c('h1',{staticClass:"country-details-back-text"},[_vm._v("Back")])])]):_c('div',{staticClass:"country-header-mobile"},[_c('img',{staticClass:"asu-logo",attrs:{"src":require("../assets/asu_sunburst_rgb_maroongold_150ppi.png")}})]),_c('NavBreadcrumbs'),_c('div',{staticClass:"country-information",attrs:{"data-scrolls-header":""}},[_c('CountryOverview'),(_vm.noActivitiesFound)?_c('h2',{staticClass:"none-found"},[_vm._v(" As part of ASU's global engagement design pillar, the university is constantly expanding its global reach. Check back regularly to see updates on our global activities. ")]):_c('div',[_c('div',{ref:"bucketContainer",staticClass:"bucket-selector"},_vm._l((_vm.countryBucketSummary),function(bucket){return _c('button',{key:bucket[0],ref:"bucketButtons",refInFor:true,staticClass:"bucket",class:{ active: _vm.currentBucket === bucket[0] },attrs:{"data-test":"country-details-bucket-summary"},on:{"click":function($event){return _vm.onClick(bucket[0])}}},[_vm._v(" "+_vm._s(bucket[0])+" ")])}),0),_c('div',{staticClass:"bucket-data"},[_c('div',{staticClass:"summary-container"},[_c('hr',{staticClass:"summary-container--line-break"}),_c('h5',{staticClass:"bucket-description",attrs:{"data-test":"country-details-bucket-description"}},[_vm._v(" "+_vm._s(_vm.currentBucket && _vm.bucketsData[_vm.currentBucket].desc)+" ")]),_c('p',{staticClass:"bucket-disclaimer"},[_vm._v(" *Data shown is comprised of multiple data sets and date ranges. ")]),(
                _vm.currentBucket === 'Students' && _vm.selectedCountryCode === 'BM'
              )?_c('p',{staticClass:"bucket-disclaimer"},[_vm._v(" *Represents data for Bermuda and historical ASU data for the British Indian Ocean Territory ")]):_vm._e(),(
                _vm.currentBucket === 'Students' && _vm.selectedCountryCode === 'AW'
              )?_c('p',{staticClass:"bucket-disclaimer"},[_vm._v(" *Represents data for Aruba and historical ASU data for the Netherland Antilles ")]):_vm._e(),(
                _vm.currentBucket &&
                _vm.bucketsData[_vm.currentBucket].contact &&
                !_vm.isMobile
              )?_c('div',{staticClass:"summary-container--more-info"},[_c('h5',[_vm._v("Looking for more info?")]),_vm._l((_vm.bucketsData[_vm.currentBucket].contact),function(contact){return _c('a',{key:contact.label,staticClass:"button--primary",attrs:{"href":("mailto:" + (contact.email))}},[_vm._v(" "+_vm._s(contact.label)+" ")])})],2):_vm._e()]),_c('div',{staticClass:"card-container"},[(
                ['Travel', 'Students', 'Education and Service'].indexOf(
                  _vm.currentBucket
                ) !== -1
              )?_c('div',{staticClass:"card-container2"},[(_vm.currentBucket === 'Travel')?_c('TravelChart',{attrs:{"activities":_vm.activitiesInSelectedBucket}}):(_vm.currentBucket === 'Students')?_c('StudentsChart',{attrs:{"activities":_vm.activitiesInSelectedBucket}}):(_vm.currentBucket === 'Education and Service')?_c('EducationAndServiceChart',{attrs:{"activities":_vm.activitiesInSelectedBucket}}):_vm._e()],1):_c('div',{staticClass:"stacked-card-containers"},[(
                  _vm.currentBucket === 'Partnerships' &&
                  _vm.cintanaPartnersInCountry.length > 0
                )?_c('CountryDetailsBucketOverview',{staticClass:"card-container2",attrs:{"title":"ASU-Cintana Institutions","activities":_vm.cintanaPartnersInCountry,"bucketData":_vm.bucketsData[_vm.currentBucket],"bucketName":_vm.currentBucket,"featured":"true"},scopedSlots:_vm._u([{key:"bottom",fn:function(){return [_c('a',{staticClass:"cintana-link",attrs:{"href":"https://global.asu.edu/what-we-do-/asu-cintana-alliance"}},[_vm._v("Learn More")])]},proxy:true}],null,false,2127980588)}):_vm._e(),((_vm.currentBucket === 'Partnerships'
                    ? _vm.activitiesWithoutCintanaPartners
                    : _vm.activitiesInSelectedBucket).length > 0)?_c('CountryDetailsBucketOverview',{staticClass:"card-container2",attrs:{"activities":_vm.currentBucket === 'Partnerships'
                    ? _vm.activitiesWithoutCintanaPartners
                    : _vm.activitiesInSelectedBucket,"bucketData":_vm.bucketsData[_vm.currentBucket],"bucketName":_vm.currentBucket}}):_vm._e()],1)]),(
              _vm.currentBucket &&
              _vm.bucketsData[_vm.currentBucket].contactEmail &&
              _vm.isMobile
            )?_c('div',{staticClass:"summary-container--more-info"},[_c('h5',[_vm._v("Looking for more info?")]),_c('a',{staticClass:"button--primary",attrs:{"href":("mailto:" + (_vm.bucketsData[_vm.currentBucket].contactEmail))}},[_vm._v("Contact Us")])]):_vm._e()])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }