<script>
import { HorizontalBar } from 'vue-chartjs';
import { mapState } from 'vuex';

export default {
  extends: HorizontalBar,
  props: ['title', 'labels', 'datasets'],
  computed: {
    ...mapState(['isMobile']),
  },
  mounted() {
    this.addPlugin({
      id: 'draw-title',
      beforeDraw: this.drawTitle,
    });
    const chartData = {
      labels: this.labels.map(label => label.split('\n')),
      datasets: this.datasets,
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          display: false,
          stacked: true,
          ticks: {
            suggestedMax: 4,
          },
        }],
        yAxes: [{
          stacked: true,
          gridLines: {
            borderColor: '#ff0000',
            color: '#f6eef1',
          },
          ticks: {
            fontColor: '#8c1d40',
            fontStyle: 'bold',
          },
        }],
      },
      legend: {
        align: 'end',
        labels: {
          boxWidth: 14,
          fontColor: '#000000',
        },
      },
    };

    this.renderChart(chartData, options);
  },
  methods: {
    // chartjs' built-in title is always center-aligned, so we render our own left-aligned title here
    drawTitle(chart) {
      if (this.isMobile) {
        return;
      }
      const ctx = chart.chart.ctx;
      ctx.restore();
      ctx.font = '18px Arial, Helvetica, sans-serif';
      ctx.textBaseline = 'top';

      const textX = 0;
      const textY = 5;

      ctx.fillText(`${this.title}`, textX, textY);
      ctx.save();
    },
  },
};
</script>
