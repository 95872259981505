<template>
  <div>
    <div v-if="isMobile">
      <div
        @click="expanded = !expanded"
        class="filter-edit-bar"
        data-test="bucket-select-mobile-header"
        :aria-label="
          expanded
            ? 'Close the Edit Filters menu'
            : 'Open the Edit Filters menu'
        "
      >
        <span data-test="bucket-select-title">{{ title }}</span>
        <i
          class="fas"
          :class="[expanded ? 'fa-times' : 'fa-sliders-h']"
          aria-hidden="true"
        ></i>
      </div>
      <div
        v-show="expanded"
        class="filter-edit-container"
        data-test="bucket-select-mobile-list"
      >
        <button
          @click="onClickCintanaPartners"
          class="bucket cintana-partnership"
          :class="{ unselected: !cintanaPartnersFilterSelected }"
          tabindex="0"
          role="checkbox"
          :aria-checked="cintanaPartnersFilterSelected"
        >
          <span data-test="bucket-select-bucket-names"
            >Cintana Academic Partnerships</span
          >
          <i
            class="far"
            :class="[
              cintanaPartnersFilterSelected ? 'fa-check-square' : 'fa-square',
            ]"
            aria-hidden="true"
          ></i>
        </button>
        <button
          :key="bucket"
          v-for="(bucket, i) in buckets"
          @click.stop="onClickBucket(i)"
          :class="[selectedBuckets[i] ? '' : 'unselected']"
          class="bucket"
          tabindex="0"
          role="checkbox"
          :aria-checked="!!selectedBuckets[i]"
        >
          <span data-test="bucket-select-bucket-names">{{
            bucketsData[bucket].title
          }}</span>
          <i
            class="far"
            :class="[selectedBuckets[i] ? 'fa-check-square' : 'fa-square']"
            aria-hidden="true"
          ></i>
        </button>

        <div @click="showAllActivities" class="show-all-activities">
          <button class="button--primary">Show All Activities</button>
        </div>
      </div>
    </div>
    <div v-else class="desktop-container">
      <div class="total-results" data-test="bucket-select-title">
        {{ title }}
      </div>
      <button
        @click="onClickCintanaPartners"
        :class="{ unselected: !cintanaPartnersFilterSelected }"
        class="bucket cintana-partnership"
        tabindex="0"
        role="checkbox"
        :aria-checked="cintanaPartnersFilterSelected"
      >
        <span>ASU-Cintana Institutions</span>
        <i
          class="far"
          :class="[
            cintanaPartnersFilterSelected ? 'fa-check-square' : 'fa-square',
          ]"
          aria-hidden="true"
        ></i>
      </button>
      <div class="bucket-container">
        <button
          v-for="(bucket, i) in buckets"
          data-test="bucket-select-bucket-names"
          :key="bucket"
          @click="onClickBucket(i)"
          :class="[selectedBuckets[i] ? '' : 'unselected']"
          class="bucket"
          tabindex="0"
          role="checkbox"
          :aria-checked="!!selectedBuckets[i]"
        >
          <span data-test="bucket-select-bucket-names">{{
            bucketsData[bucket].title
          }}</span>
          <i
            class="far"
            :class="[selectedBuckets[i] ? 'fa-check-square' : 'fa-square']"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { formatNumber } from '../helpers/helpers';

export default {
  beforeUpdate() {
    if (this.selectedBuckets.some(bucket => bucket)) {
      this.updateCintanaPartnersFilterSelected(false);
    }
    // if (this.cintanaPartnersFilterSelected) {
    //   this.updateSelectedBuckets(this.buckets.map(() => 0));
    // }
  },
  data: () => ({
    expanded: false,
  }),
  components: {},
  computed: {
    ...mapState([
      'buckets',
      'selectedBuckets',
      'bucketsData',
      'isMobile',
      'selectedContinent',
      'cintanaPartnersFilterSelected',
    ]),
    ...mapGetters(['totalResults']),
    title() {
      if (this.expanded) {
        return 'Edit Filters';
      }
      return `${
        this.selectedContinent
          ? `Activity in ${this.selectedContinent}`
          : 'Global Activity'
      }: ${formatNumber(this.totalResults)}`;
    },
  },
  methods: {
    ...mapMutations([
      'updateSelectedBucket',
      'updateSelectedBuckets',
      'updateCintanaPartnersFilterSelected',
    ]),
    onClickBucket(bucket) {
      if (!this.selectedBuckets[bucket]) {
        this.updateCintanaPartnersFilterSelected(false);
      }
      this.updateSelectedBucket(bucket);
    },
    onClickCintanaPartners() {
      if (this.cintanaPartnersFilterSelected) {
        this.showAllActivities();
      } else {
        this.showCintanaPartners();
      }
    },
    showCintanaPartners() {
      this.updateSelectedBuckets(this.buckets.map(() => 0));
      this.updateCintanaPartnersFilterSelected(true);
    },
    showAllActivities() {
      this.updateSelectedBuckets(this.buckets.map(() => 1));
      this.updateCintanaPartnersFilterSelected(false);
      this.expanded = false;
    },
  },
};
</script>

<style scoped>
.desktop-container {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto auto;
  top: 15px;
  left: 15px;
  right: 15px;
  pointer-events: none;
}
.desktop-container * {
  pointer-events: all;
}
.total-results {
  grid-column: 2;
  grid-row: 1;
  display: inline-block;
  font-weight: 700;
  text-shadow: 0px 6px 12px #00000029;
  font-size: 1.5em;
  color: #000000;
  background-color: var(--asu-gold);
  padding: 4px 10px;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 20px;
}
.bucket-container {
  display: flex;
  grid-column: 2;
  grid-row: 2;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 75%;
  margin-left: auto;
  margin-right: 0;
  /* position: absolute;
  right: 15px;
  top: 70px; */
}
.desktop-container .bucket {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin: 5px;
  padding: 0 12px;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  outline: 0;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #000000;
  font-weight: 700;
  font-size: 12px;
}
.desktop-container .unselected {
  background-color: #e8e8e8;
  color: #000000;
  border: 1px solid #ceb7b7;
}
.bucket span {
  margin-right: 8px;
}
.desktop-container .bucket.cintana-partnership {
  grid-row: 2;
  grid-column: 1;
  display: inline;
  align-self: flex-start;
  background-color: var(--asu-gold);
  color: #000000;
  border: 1px solid var(--asu-gold);
  max-width: 260px;
}

@media screen and (min-width: 1000px) {
  .bucket.cintana-partnership.unselected {
    filter: brightness(90%);
  }
}

@media (hover: hover) {
  .bucket:hover,
  .bucket:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.2s;
  }
}

@media only screen and (max-width: 1000px),
  only screen and (max-device-width: 1000px) and (orientation: landscape) {
  .filter-edit-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    background-color: var(--asu-gold);
    height: 40px;
    padding: 0 15px;
  }
  .filter-edit-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 40px;
    background-color: #ffffff;
    z-index: 60; /* to sit on top of SideNavWrapper's z-index of 50 */
    padding-top: 10px;
    overflow-y: scroll;
  }
  .filter-edit-container .bucket {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    padding: 20px 24px;
  }
  .filter-edit-container .bucket.bucket.cintana-partnership span {
    background-color: var(--asu-gold);
    padding: 2px;
  }
  .filter-edit-container .bucket:not(:last-child) {
    border-bottom: 1px solid #f1e3e7;
  }
  .filter-edit-container .bucket span {
    font-size: 14px;
    font-weight: 700;
    color: #000000;
  }
  .filter-edit-container .bucket i {
    font-size: 18px;
  }
  .filter-edit-container .bucket.unselected i {
    color: #f1e3e7;
  }
  .show-all-activities {
    display: flex;
    justify-content: center;
  }
}
</style>
