<template>
  <div v-if="mapBucketStyle[bucket] === 'Study Abroad'" class="result">
    <h2 data-test="study-abroad-search-result-program">
      {{ activity.program_name }}
    </h2>
    <div class="activity-information">
      <div class="activity-information--column">
        <div
          class="activity-prop"
          data-test="study-abroad-search-result-terms-available"
          v-if="activity.semesters"
        >
          <span>Terms Available: </span
          >{{ Object.keys(activity.semesters).join(", ") }}
        </div>
        <div
          class="activity-prop"
          data-test="study-abroad-search-result-areas-of-study"
        >
          <span>Areas of Study: </span>{{ activity.areas_of_study }}
        </div>
        <div
          v-if="activity.internships_available"
          class="activity-prop"
          data-test="study-abroad-search-result-internships-available"
        >
          <span>Internships Available</span>
        </div>
        <div
          v-if="activity.graduate_courses_available"
          class="activity-prop"
          data-test="study-abroad-search-result-graduate-courses-available"
        >
          <span>Graduate Courses Available</span>
        </div>
      </div>
      <div class="activity-information--column">
        <div
          v-if="activity.program_city"
          class="activity-prop"
          data-test="study-abroad-search-result-city"
        >
          <span>City: </span>{{ activity.program_city }}
        </div>
        <div v-if="activity.college_sponsor" class="activity-prop break">
          <span>College Sponsor: </span>{{ activity.college_sponsor }}
        </div>
      </div>
      <a
        :href="activity.brochure_url"
        target="”_blank”"
        rel="noreferrer noopener"
        class="button--primary learn-more"
        data-test="study-abroad-search-result-learn-more"
      >
        Learn More
      </a>
    </div>
  </div>

  <div v-else-if="mapBucketStyle[bucket] === 'Research'" class="result">
    <div class="activity-information--column">
      <div class="activity-prop">
        <span>Number of Agreements: </span>{{ activity.number_of_agreements }}
      </div>
    </div>
  </div>

  <div v-else-if="mapBucketStyle[bucket] === 'Locations'" class="result">
    <h2>{{ activity.city }}</h2>
    <div class="activity-information--column">
      <div class="activity-prop">{{ activity.description }}</div>
    </div>
  </div>

  <div v-else-if="mapBucketStyle[bucket] === 'Partnerships'" class="result">
    <h2 :class="{ featured: featured }">{{ activity.academic_partner }}</h2>
    <p v-if="featured" class="featured-tag">Featured</p>
    <div
      v-if="!activity.in_cintana_alliance"
      class="activity-information--column"
    >
      <div class="activity-prop">
        <span>Frameworks: </span>{{ activity.frameworks }}
      </div>
      <div v-if="!activity.in_cintana_alliance" class="activity-prop">
        <span>ASU Units: </span>{{ activity.asu_units }}
      </div>
    </div>
  </div>

  <div v-else-if="mapBucketStyle[bucket] === 'Alumni'" class="result">
    <div class="activity-information">
      <h2>{{ activity.chapter_name }}</h2>
      <a
        :href="activity.url"
        target="_blank"
        rel="noreferrer noopener"
        class="button--primary learn-more"
      >
        Learn More
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { convertSpacesToDashes } from "../helpers/helpers";

export default {
  data() {
    return {};
  },
  props: ["bucket", "activity", "featured"],
  components: {},
  computed: {
    ...mapState(["isMobile", "bucketsData"]),
    continent() {
      return convertSpacesToDashes(this.activity.continent);
    },
    country() {
      return convertSpacesToDashes(this.activity.country);
    },
    mapBucketStyle() {
      const styles = { Countries: "Countries" };
      Object.entries(this.bucketsData).forEach((entry) => {
        styles[entry[0]] = entry[1].style;
      });
      return styles;
    },
  },
  methods: {},
};
</script>

<style scoped>
.button--primary.learn-more {
  width: 100px;
  padding: 0;
  font-size: 0.875rem;
  height: 30px;
  border-radius: 15px;
  margin: 0;
}
.result {
  padding: 10px 0;
}
h2 {
  font-size: 1.125rem;
  margin: 2vh 0;
  color: black;
}
h2 .header-addition {
  font-weight: normal;
}
.activity-information {
  display: flex;
  justify-content: space-between;
}
.activity-information--column {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 10px 30px 0 0;
}
.activity-prop {
  display: inline-block;
  margin: 1vh 0;
  color: black;
  font-weight: 300;
}
.activity-prop span {
  font-weight: 700;
}
.featured {
  display: inline-block;
  /* text-decoration: underline; */
  box-shadow: -0.15em 0 0 #ffc627, 0.15em 0 0 #ffc627;
  background: #ffc627;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  line-height: 1.5em;
  padding: 0 4px;
  transform: translateX(-4px);
}
.featured-tag {
  display: inline;
  padding-left: 8px;
  font-style: italic;
}
.desc--container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.desc {
  display: inline-block;
  margin: 2% 4% 2% 2%;
  color: black;
  font-weight: 300;
}
.areas-of-study {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.break {
  display: block;
}
.semester--list {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}
.semester {
  width: 145px;
}

@media only screen and (max-width: 1000px),
  only screen and (max-device-width: 1000px) and (orientation: landscape) {
  .button--primary.learn-more {
    font-size: 1.125rem;
  }
  .semester--list {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .semester {
    width: 135px;
  }
}
</style>
