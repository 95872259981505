<template>
  <div class="overview">
    <Spinner
      v-if="loading"
      class="loading"
      data-test="country-overview-loading"
    />
    <h1 v-else-if="error" class="none-found" data-test="country-overview-error">
      Error: Could not retrieve country data
    </h1>
    <div v-else>
      <div class="country-hero-image-container">
        <img
          v-bind:class="{
            'country-hero-image': true,
            'country-hero-image-not-available': noCountryImage,
          }"
          @error="onImageLoadFailure($event)"
          :src="`${BASE_BUCKET_URL}images/${selectedCountryCode}_country_image.jpg`"
        />
        <span
          v-if="noCountryImage"
          class="country-hero-image-not-available-text"
        >
          Photo not available for this location
        </span>
        <div class="country-data-primary">
          <h2 data-test="country-overview-country-name">
            {{ selectedCountry }}, {{ selectedContinent }}
          </h2>
          <ul
            class="country-data-primary--list"
            data-test="country-overview-primary-list"
          >
            <li
              v-for="item in this.countryDataPrimary"
              :key="item.key"
              data-test="country-overview-primary-data"
            >
              <span class="prop-name">{{ item.key }}:</span>
              <span v-if="typeof item.value === 'object'">
                <span v-for="(record, i) in item.value" :key="record">
                  {{ record }}<span v-if="i < item.value.length - 1">, </span>
                </span>
              </span>
              <span v-else>
                {{ item.value }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="country-data-secondary">
        <ul
          class="country-data-secondary--list"
          data-test="country-overview-secondary-list"
        >
          <li
            v-for="item in this.countryDataSecondary"
            :key="item.key"
            data-test="country-overview-secondary-data"
          >
            <span class="prop-name">{{ item.key }}</span>
            <span v-if="typeof item.value === 'object'" class="data-value">
              <span v-for="(record, i) in item.value" :key="record">
                {{ record }}<span v-if="i < item.value.length - 1">, </span>
              </span>
            </span>
            <span v-else class="data-value">
              {{ item.value }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import countriesData from "../data/countriesData";
import Spinner from "./Spinner.vue";

export default {
  data() {
    return {
      loading: true,
      error: false,
      noCountryImage: false,
      countryDataPrimary: [],
      countryDataSecondary: [],
    };
  },
  components: {
    Spinner,
  },
  computed: {
    ...mapState([
      "selectedCountry",
      "selectedContinent",
      "selectedCountryCode",
      "isMobile",
      "BASE_BUCKET_URL",
    ]),
  },
  methods: {
    /* eslint-disable no-param-reassign */
    onImageLoadFailure(event) {
      // if the country image is not found, resort to a default image
      if (this.noCountryImage) return;
      this.noCountryImage = true;
      event.target.src = `${this.BASE_BUCKET_URL}images/Default_country_image.jpg`;
    },
  },
  async mounted() {
    const dataParsed = countriesData[this.selectedCountryCode];
    this.loading = false;
    if (!dataParsed) {
      this.error = true;
    }
    let popParsed; // parse population number to include commas or 'million'
    if (dataParsed.population >= 1000000000) {
      popParsed = Math.round(dataParsed.population / 10000000) / 100;
      popParsed = `${popParsed.toString()} billion`;
    } else if (dataParsed.population >= 1000000) {
      popParsed = Math.round(dataParsed.population / 10000) / 100;
      popParsed = `${popParsed.toString()} million`;
    } else if (dataParsed.population > 1000) {
      const beforeComma = Math.floor(dataParsed.population / 1000);
      const afterComma = Math.abs(beforeComma * 1000 - dataParsed.population);
      popParsed = `${beforeComma},${afterComma}`;
    }

    this.countryDataPrimary = [
      { key: "Time Zone(s)", value: dataParsed.timezones },
      {
        key: "Spoken Language(s)",
        value: dataParsed.languages.map((item) => item.name),
      },
      {
        key: "Currencies",
        value: dataParsed.currencies.map(
          (item) => `${item.name} (${item.symbol})`
        ),
      },
      { key: "Subregion", value: dataParsed.subregion },
    ];

    this.countryDataSecondary = [
      {
        key: this.isMobile ? "Total Pop." : "Total Population",
        value: popParsed,
      },
      { key: "Capital City", value: dataParsed.capital },
      { key: "Lat + Long", value: dataParsed.latlng },
      { key: "Demonym", value: dataParsed.demonym },
    ];
  },
};
</script>

<style scoped>
.overview {
  /* background-color: gray; */
  /* padding: 1px; */
}
.loading {
  position: relative;
  margin: 5rem;
}
.country-hero-image-container {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.country-hero-image {
  object-fit: cover;
  object-position: center center;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.country-hero-image-not-available {
  filter: grayscale(100%);
}
.country-hero-image-not-available-text {
  position: absolute;
  bottom: 10px;
  right: 40px;
  color: #ffffff;
  font-weight: 700;
}
.country-data-primary {
  background-color: var(--asu-gold);
  margin: calc(30px + 10vh);
  padding: 30px;
  max-width: 500px;
  color: #000000;
}
.country-data-primary--list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 20px;
}
.country-data-primary--list li {
  flex-basis: 50%;
  margin: 10px 0;
  font-weight: 300;
}
.country-data-primary--list li .prop-name {
  font-weight: 700;
}
.country-data-secondary--list {
  display: flex;
  background-color: #000000;
  list-style: none;
  flex-wrap: wrap;
}
.country-data-secondary--list li {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  font-weight: 300;
}
.country-data-secondary--list li:not(:last-child):after {
  content: "|";
  color: var(--asu-gold);
  font-weight: 700;
  font-size: 18px;
  text-align: right;
  margin-top: -30px;
}
.country-data-secondary--list li .prop-name {
  font-weight: 700;
  color: #ffffff;
  margin: 0px 40px 10px 40px;
  font-size: 16px;
}
.country-data-secondary--list li .data-value {
  color: #000000;
  background-color: var(--asu-gold);
  margin: 0px 40px;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
}

@media only screen and (max-width: 1000px),
  only screen and (max-device-width: 1000px) and (orientation: landscape) {
  .country-data-primary {
    margin: 60px 20px;
  }
  .country-data-primary--list li {
    flex-basis: 100%;
  }
  .country-data-secondary--list li {
    flex-basis: 50%;
    margin: 40px 0px;
  }
  .country-data-secondary--list li:not(:last-child):after {
    content: "";
  }
  .country-data-secondary--list li .prop-name {
    margin: 0px 20px 10px 20px;
  }
  .country-data-secondary--list li .data-value {
    margin: 0px 20px;
    padding: 10px;
  }
}
</style>
