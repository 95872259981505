// @input Object map reference, Object continentMarkers
// add continent and cluster layers to the map
// @return void
function addLayers(mapRef, continentMarkers) {
  mapRef.addSource('countriesSource', {
    type: 'vector',
    url: 'mapbox://acpierc3.61jp46ub',
  });

  mapRef.addLayer(
    {
      'id': 'countries',
      'source': 'countriesSource',
      'source-layer': 'ne_10m_admin_0_countries_30MA-di6mr6',
      'type': 'fill',
      'paint': {
        'fill-color': '#8C1D40',
        'fill-outline-color': '#FAFDFF',
        'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
      },
    },
    'country-label', // place this layer below the country label layer
  );

  // CONTINENT LAYERS

  mapRef.addSource('NorthAmericaSource', {
    type: 'vector',
    url: 'mapbox://acpierc3.5sy2a6ko',
  });

  mapRef.addLayer({
    'id': 'North America',
    'source': 'NorthAmericaSource',
    'source-layer': 'NA_highres-9p5duv',
    'type': 'fill',
    'paint': {
      'fill-color': '#8C1D40',
      'fill-outline-color': '#FAFDFF',
      'fill-opacity': 0,
    },
  });

  mapRef.addSource('AfricaSource', {
    type: 'vector',
    url: 'mapbox://acpierc3.68lob6zi',
  });

  mapRef.addLayer({
    'id': 'Africa',
    'source': 'AfricaSource',
    'source-layer': 'AF_highres2-ahp6gf',
    'type': 'fill',
    'paint': {
      'fill-color': '#8C1D40',
      'fill-outline-color': '#FAFDFF',
      'fill-opacity': 0,
    },
  });

  mapRef.addSource('SouthAmericaSource', {
    type: 'vector',
    url: 'mapbox://acpierc3.7ncb51j9',
  });

  mapRef.addLayer({
    'id': 'South America',
    'source': 'SouthAmericaSource',
    'source-layer': 'SA_highres-aun0ew',
    'type': 'fill',
    'paint': {
      'fill-color': '#8C1D40',
      'fill-outline-color': '#FAFDFF',
      'fill-opacity': 0,
    },
  });

  mapRef.addSource('OceaniaSource', {
    type: 'vector',
    url: 'mapbox://acpierc3.8pm8kn2q',
  });

  mapRef.addLayer({
    'id': 'Oceania',
    'source': 'OceaniaSource',
    'source-layer': 'OC_medres-0zvjit',
    'type': 'fill',
    'paint': {
      'fill-color': '#8C1D40',
      'fill-outline-color': '#FAFDFF',
      'fill-opacity': 0,
    },
  });

  mapRef.addSource('AntarcticaSource', {
    type: 'vector',
    url: 'mapbox://acpierc3.4656kkgf',
  });

  mapRef.addLayer({
    'id': 'Antarctica',
    'source': 'AntarcticaSource',
    'source-layer': 'AN_highres-7tyffg',
    'type': 'fill',
    'paint': {
      'fill-color': '#8C1D40',
      'fill-outline-color': '#FAFDFF',
      'fill-opacity': 0,
    },
  });

  mapRef.addSource('EuropeSource', {
    type: 'vector',
    url: 'mapbox://acpierc3.ayizdc46',
  });

  mapRef.addLayer({
    'id': 'Europe',
    'source': 'EuropeSource',
    'source-layer': 'EU_medres-7t8lme',
    'type': 'fill',
    'paint': {
      'fill-color': '#8C1D40',
      'fill-outline-color': '#FAFDFF',
      'fill-opacity': 0,
    },
  });

  mapRef.addSource('AsiaSource', {
    type: 'vector',
    url: 'mapbox://acpierc3.456ukd44',
  });

  mapRef.addLayer({
    'id': 'Asia',
    'source': 'AsiaSource',
    'source-layer': 'AS_medres-2bteo8',
    'type': 'fill',
    'paint': {
      'fill-color': '#8C1D40',
      'fill-outline-color': '#FAFDFF',
      'fill-opacity': 0,
    },
  });

  // CLUSTER LAYERS

  mapRef.addSource('markers', {
    type: 'geojson',
    data: continentMarkers,
    cluster: false,
  });

  mapRef.addLayer(
    {
      'id': 'cluster-count',
      'type': 'symbol',
      'source': 'markers',
      'layout': {
        'text-field': '+{total}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 10,
      },
      'paint': {
        'text-color': '#000',
      },
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'text-ignore-placement': true,
    },
    'North America',
  );

  mapRef.addLayer(
    {
      'id': 'unclustered-point',
      'type': 'circle',
      'source': 'markers',
      'paint': {
        'circle-color': '#FFC627',
        'circle-radius': [
          'step',
          ['get', 'total'],
          7,
          3,
          8,
          4,
          9,
          5,
          11,
          15,
          13,
          100,
          15,
          200,
          17,
          300,
          18,
          400,
          19,
          500,
          20,
          600,
          21,
          700,
          22,
          1000,
          24,
          1500,
          25,
          2000,
          26,
          3000,
          28,
        ],
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff',
        'circle-opacity': 1,
      },
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'text-ignore-placement': true,
      'circle-allow-overlap': true,
      'circle-ignore-placement': true,
    },
    'cluster-count',
  );
}

export { addLayers };
