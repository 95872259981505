function convertSpacesToDashes(str) {
  // also conver to lower case, for routing purposes
  const newStr = str.replace(/\s/g, '-').toLowerCase();
  return newStr;
}

function convertDashesToSpaces(str) {
  // also convert to Title Case, for dynamically pulling info from route
  return str.split('-')
    // eslint-disable-next-line consistent-return, array-callback-return
    .map((word) => {
      if (word) {
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
      }
    })
    .join(' ');
}

function formatNumber(num) {
  return Number(num).toLocaleString();
}

export { convertSpacesToDashes, convertDashesToSpaces, formatNumber };
