<template>
  <div class="intro-panel-wrapper">
    <div class="intro-panel">
      <button class="close-button" @click="close"><i class="fas fa-times"></i></button>
      <h2>Welcome to ASU Global</h2>
      <div class="content-description">
        Here you can explore ASU's global footprint by exploring categories and locations that interest you!
      </div>
      <div class="activities">
        <div class="activity-description">
          <i class="fas fa-check-circle"></i>
          <h4>Select Categories</h4>
          <div>Click on categories at the top of the interactive map to filter result count by activity type.</div>
        </div>
        <div class="activity-description">
          <i class="fas fa-map-marked-alt"></i>
          <h4>Explore Activity</h4>
          <div>Click on an area of the map to zoom in and see activity counts by continent and country. Click on a country to view more information.</div>
        </div>
        <div class="activity-description">
          <i class="fas fa-search"></i>
          <h4>Search ASU's Global Impact</h4>
          <div>Use the search box to view detailed information by country.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.intro-panel-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.intro-panel {
  background-color: var(--asu-blue);
  padding: 40px 60px;
  color: #000000;
}
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 15px;
}
.content-description {
  max-width: 500px;
  margin: 10px 0 30px;
}
.activities {
  display: flex;
}
.activity-description {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.activity-description:not(:last-child) {
  margin-right: 20px;
}
.activity-description i {
  font-size: 24px;
  margin-bottom: 10px;
}
.activity-description h4 {
  margin: 6px 0;
}
</style>
