<template>
<div class="chart-container">
  <Bar v-if="datasets.length > 0" :title="title" :labels="purposes" :datasets="datasets" :styles="styles" />
</div>
</template>

<script>
import Bar from './Bar.vue';

export default {
  components: { Bar },
  props: ['activities'],
  data() {
    return {
      title: '',
      purposes: [],
      datasets: [],
      styles: {
        width: '100%',
        height: '300px',
      },
    };
  },
  mounted() {
    this.title = `Total Visitors: ${this.activities.length}`;

    // count total number of visitors by purpose
    /* eslint-disable no-param-reassign */
    const visitorCountByPurpose = this.activities.reduce((result, activity) => {
      const purpose = activity.trip_purpose;
      if (result[purpose] == null) {
        result[purpose] = 0;
      }
      result[purpose] += 1;
      return result;
    }, {});
    /* eslint-enable no-param-reassign */

    // sort purposes by number of visitors, descending
    const sorted = Object.entries(visitorCountByPurpose)
      .map(entry => ({ purpose: entry[0], count: entry[1] }))
      .sort((a, b) => (a.count > b.count ? -1 : 1));

    this.purposes = sorted.map(entry => entry.purpose);
    this.datasets = [{
      label: 'Visitors',
      backgroundColor: '#8c1d40',
      maxBarThickness: 40,
      data: sorted.map(entry => entry.count),
    }];
  },
};
</script>

<style scoped>
.chart-container {
  display: flex;
  justify-content: center;
  padding: 30px 40px;
}
</style>
