<template>
  <div class="global-engagement-newsfeed" data-test="global-engagement-container" v-if="stories.length > 0 && !selectedCountryInSearch">
    <h1>Global Engagement Stories</h1>
    <div v-for="story in stories" :key="story.nid" class="newsfeed-item">
      <div class="newsfeed-item--image-wrapper">
        <img v-lazy="story.imageUrl" :alt="story.imageAlt">
      </div>
      <div class="newsfeed-item--text">
        <h2>{{ story.title }}</h2>
        <a :href="story.path" class="button--learn-more" target="_blank">Learn More</a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import VueLazyload from 'vue-lazyload';
import fetch from 'node-fetch';
import parser from 'fast-xml-parser';
import globalEngagementDataTemp from '../data/globalEngagementTemp';

Vue.use(VueLazyload);

export default {
  data() {
    return {
      stories: [],
    };
  },
  computed: {
    ...mapState(['selectedCountryInSearch']),
  },
  methods: {
    async getGlobalEngagementStories() {
      // Temporary solution until we have a new feed
      return globalEngagementDataTemp;
      // const data = await fetch(`${process.env.VUE_APP_GLOBAL_ENGAGEMENT_FEED_URL}`, {
      //   headers: new Headers({ 'Accept': 'application/xml', 'Content-Type': 'application/xml' }),
      // });
      // return data.text();
    },
  },
  async mounted() {
    const xmlString = await this.getGlobalEngagementStories();
    const json = parser.parse(xmlString);
    this.stories = json.xml.node.map(story => ({
      nid: story.nid,
      imageUrl: story.image_url,
      imageAlt: story.image_alt,
      title: story.title.replace(/&#039;/g, "'").replace(/&#034;/g, '"'), // un-escape single and double quotes
      path: story.path,
    }));
  },
};
</script>

<style scoped>
.global-engagement-newsfeed h1 {
  color: #000000;
  margin: 20px 30px;
  font-size: 20px;
}
.newsfeed-item {
  background-color: #ffffff;
  margin: 20px 30px;
  border: 1px solid #dcdcdc;
}
/* Trick to maintain an aspect ratio on an image with dynamic size: https://pqina.nl/blog/presenting-images-in-an-aspect-ratio-with-css/ */
.newsfeed-item--image-wrapper {
  position: relative;
}
.newsfeed-item--image-wrapper::after {
  display: block;
  content: '';
  padding-bottom: 50%; /* results in a 2:1 aspect ratio */
}
.newsfeed-item--image-wrapper img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0%;
}
.newsfeed-item--image-wrapper img[lazy='loaded'] {
  opacity: 0;
  animation-name: fadein;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-timing-function: ease-out;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.newsfeed-item--text {
  padding: 20px;
}
.newsfeed-item--text h2 {
  color: #000000;
  font-size: 16px;
  margin-bottom: 20px;
}
.newsfeed-item--text a {
  background-color: #000000;
  color: #ffffff;
  text-decoration: none;
  width: 110px;
  margin: 0;
  height: 30px;
  border-radius: 15px;
}
</style>
