const continentNames = {
  AF: 'Africa',
  AN: 'Antarctica',
  AS: 'Asia',
  EU: 'Europe',
  NA: 'North America',
  OC: 'Oceania',
  SA: 'South America',
};

// when a user selects a continent, these are the coordinates of the bounding box the map zooms to
const continentBounds = {
  'Africa': [
    [-25.6, -36.9], // bottom left of bounding box around continent [lng, lat]
    [51.07, 38.07], // top right [lng, lat]
  ],
  'Antarctica': [
    [-132.53, -84.35],
    [103.97, -29.77],
  ],
  'Asia': [
    [20.91, -12.27],
    [151.54, 56.91],
  ],
  'Europe': [
    [-24.02, 30.14],
    [81.67, 72.04],
  ],
  'North America': [
    [-144.79, -5.01],
    [-32.68, 66.52],
  ],
  'Oceania': [
    [99.37, -49.09],
    [185.49, 0.91],
  ],
  'South America': [
    [-118.1, -63.18],
    [-19.42, 24.16],
  ],
};

const continentLatLong = {
  'North America': ['-97.00', '38.00'],
  'South America': ['-60.82', '-13.89'],
  'Asia': ['90.61', '39.43'],
  'Europe': ['17.87', '48.64'],
  'Antarctica': ['33.73', '-71.94'],
  'Oceania': ['135.65', '-24.55'],
  'Africa': ['21.80', '8.44'],
};

export { continentNames, continentBounds, continentLatLong };
