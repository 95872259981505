<script>
import { Doughnut } from 'vue-chartjs';

export default {
  extends: Doughnut,
  props: ['amount', 'total', 'color'],
  mounted() {
    this.addPlugin({
      id: 'draw-text',
      beforeDraw: this.drawText,
    });
    const chartData = {
      datasets: [
        {
          backgroundColor: [this.color, '#e8e8e8'],
          data: [this.amount, this.total - this.amount],
          borderWidth: 0,
        },
      ],
    };
    const options = {
      cutoutPercentage: 65,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    };
    this.renderChart(chartData, options);
  },
  methods: {
    drawText(chart) {
      const height = chart.chart.height;
      const ctx = chart.chart.ctx;

      ctx.restore();
      ctx.textBaseline = 'middle';
      ctx.font = 'bold 36px Arial, Helvetica, sans-serif';
      this.printCenteredText(chart.chart, this.amount, (height / 2));
      ctx.save();
    },
    // Prints one line of text, centered horizontally on the chart.
    printCenteredText(chart, text, y) {
      const ctx = chart.ctx;
      const x = Math.round((chart.width - ctx.measureText(text).width) / 2);
      ctx.fillText(text, x, y);
    },
  },
};
</script>
