<template>
  <div class="nav-breadcrumbs">
    <router-link class="home-link" data-test="nav-breadcrumbs-all-continents" v-if="selectedContinent" :to="'/'">7 Continents</router-link>
    <span v-if="selectedContinent" class="divider">/</span>
    <router-link
      v-if="selectedContinent"
      :to="'/' + selectedContinentParsed + '?selectedBuckets=' + selectedBuckets.toString()"
      :class="{ active: !isCountrySelected }"
      class="continent-link"
      data-test="nav-breadcrumbs-selected-continent"
      >{{ selectedContinent }}</router-link
    >
    <span v-if="selectedCountry" class="divider">/</span>
    <router-link
      v-if="selectedCountry"
      :to="'/' + selectedContinentParsed + '/' + selectedCountryParsed"
      :class="{ active: isCountrySelected }"
      data-test="nav-breadcrumbs-selected-country"
      >{{ selectedCountry }}</router-link
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { convertSpacesToDashes } from '../helpers/helpers';

export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapState(['selectedBuckets', 'selectedContinent', 'selectedCountry']),
    isCountrySelected() {
      return this.selectedCountry !== '';
    },
    selectedContinentParsed() {
      return convertSpacesToDashes(this.selectedContinent);
    },
    selectedCountryParsed() {
      return convertSpacesToDashes(this.selectedCountry);
    },
  },
  methods: {},
};
</script>

<style scoped>
.nav-breadcrumbs {
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 30px;
  margin-right: 50px;
  z-index: 200;

}
.divider {
  margin: 3px;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: var(--asu-maroon-hover)
}
a.active {
  font-weight: 700;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
a.active:hover {
  cursor: default;
}

@media only screen and (max-width: 1000px),
  only screen and (max-device-width: 1000px)
  and (orientation: landscape) {
  .nav-breadcrumbs {
    height: 60px;
    margin-top: 0;
    margin-right: 15px;
    font-size: 1.33rem;
    display: flex;
    align-items: center;
    max-width: 75%;
  }
  .home-link, .continent-link {
    white-space: nowrap;
  }
}
</style>
