import { initGlobalHeader } from '@asu/component-header/dist/asuHeader.es';

const BASE_URL = process.env.VUE_APP_BASE_URL;

// Adapted from https://github.com/ASU/asu-unity-stack/blob/dev/packages/component-header/examples/global-header.html
// Props adapted from props on https://global.asu.edu/
const props = {
  'baseUrl': BASE_URL,
  'title': 'Global@ASU',
  'parentOrg': '',
  'parentOrgUrl': '',
  'expandOnHover': 0,
  'loginLink': '/caslogin',
  'logoutLink': '/caslogout',
  'loggedIn': false,
  'userName': '',
  'animateTitle': true,
  'navTree': [
    {
      'href': '',
      'text': 'Home',
      'items': '',
      'buttons': '',
      'type': 'icon-home',
      'class': 'home',
    },
    {
      'href': '',
      'text': 'Who We Are',
      'items': [
        [
          {
            'href': `${BASE_URL}/who-we-are`,
            'text': 'Who We Are',
            'type': null,
          },
          {
            'href': `${BASE_URL}/who-we-are/our-team`,
            'text': 'Meet our Team',
            'type': null,
          },
          {
            'href': `${BASE_URL}/who-we-are/what-we-do`,
            'text': 'What We Do',
            'type': null,
          },
          {
            'href': `${BASE_URL}/who-we-are/why-we-do-it`,
            'text': 'Why We Do It',
            'type': null,
          },
        ],
      ],
      'buttons': '',
    },
    {
      'href': '',
      'text': 'What We Do',
      'items': [
        [
          {
            'href': `${BASE_URL}/what-we-do`,
            'text': 'What We Do',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do-/asu-cintana-alliance`,
            'text': 'ASU-Cintana Alliance',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do-/global-launch`,
            'text': 'Global Launch',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do/hainan`,
            'text': 'Hainan',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do/plus-alliance-tedi-london`,
            'text': 'Plus Alliance/TEDI London',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do/sustainability`,
            'text': 'Sustainability',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do/university-design-institute`,
            'text': 'University Design Institute',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do/asu-refugees`,
            'text': 'ASU Refugees',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do/asu-gsv`,
            'text': 'ASU GSV',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do/remote`,
            'text': 'Remote',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do/thunderbird`,
            'text': 'Thunderbird',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do/asuid`,
            'text': 'ASUID',
            'type': null,
          },
          {
            'href': `${BASE_URL}/what-we-do/mastercard-foundation`,
            'text': 'Mastercard Foundation',
            'type': null,
          },
        ],
      ],
      'buttons': '',
    },
    {
      'href': '',
      'text': 'Where We Are',
      'items': [
        [
          {
            'href': `${BASE_URL}/where-we-are`,
            'text': 'Where We Are',
            'type': null,
          },
          {
            'href': `${BASE_URL}/where-we-are/our-networks`,
            'text': 'Our Networks',
            'type': null,
          },
          {
            'href': `${BASE_URL}/where-we-are/international-brochures`,
            'text': 'International Brochures',
            'type': null,
          },
        ],
      ],
      'buttons': '',
    },
    {
      'href': '',
      'text': 'For Students',
      'items': [
        [
          {
            'href': '',
            'text': 'Study at ASU',
            'type': 'heading',
          },
          {
            'href': `${BASE_URL}/for-students/study-at-asu`,
            'text': 'Study at ASU',
            'type': null,
          },
          {
            'href': `${BASE_URL}/study-at-asu/international-admission`,
            'text': 'International Student Admission',
            'type': null,
          },
          {
            'href': `${BASE_URL}/for-students/study-at-asu/learn-english`,
            'text': 'Learn English',
            'type': null,
          },
          {
            'href': `${BASE_URL}/for-students/study-at-asu/asu-student-life`,
            'text': 'Student life at ASU',
            'type': null,
          },
          {
            'href': `${BASE_URL}/for-students/study-at-asu/global-curriculum`,
            'text': 'Global Curriculum',
            'type': null,
          },
          {
            'href': `${BASE_URL}/study-at-asu/asu-egypt`,
            'text': 'ASU in Egypt',
            'type': null,
          },
          {
            'href': `${BASE_URL}/study-at-asu/asu-china`,
            'text': 'ASU in China',
            'type': null,
          },
          {
            'href': `${BASE_URL}/study-at-asu/asu-mexico`,
            'text': 'ASU in Mexico',
            'type': null,
          },
          {
            'href': `${BASE_URL}/study-at-asu/asu-japan`,
            'text': 'ASU in Japan',
            'type': null,
          },
        ],
        [
          {
            'href': '',
            'text': 'Global Experiences',
            'type': 'heading',
          },
          {
            'href': `${BASE_URL}/for-students/global-experiences`,
            'text': 'Global Experiences',
            'type': null,
          },
          {
            'href': `${BASE_URL}/for-students/global-experiences/study-abroad`,
            'text': 'Study Abroad',
            'type': null,
          },
          {
            'href': `${BASE_URL}/for-students/global-experiences/fulbright`,
            'text': 'Fulbright',
            'type': null,
          },
          {
            'href': `${BASE_URL}/for-students/global-experiences/peace-corps`,
            'text': 'Peace Corps',
            'type': null,
          },
          {
            'href': `${BASE_URL}/for-students/global-experiences/global-resolve`,
            'text': 'GlobalResolve',
            'type': null,
          },
        ],
      ],
      'buttons': '',
    },
    {
      'href': '',
      'text': 'For ASU Units',
      'items': [
        [
          {
            'href': `${BASE_URL}/asu-units`,
            'text': 'ASU Units',
            'type': null,
          },
          {
            'href': `${BASE_URL}/asu-units/mailing-list`,
            'text': 'Join Our Mailing List',
            'type': null,
          },
          {
            'href': `${BASE_URL}/asu-units/new-initiatives-checklist`,
            'text': 'New Initiatives Checklist',
            'type': null,
          },
          {
            'href': `${BASE_URL}/where-we-are/international-brochures`,
            'text': 'International Brochures',
            'type': null,
          },
        ],
      ],
      'buttons': '',
    },
    {
      'href': `${BASE_URL}/earthquake-relief`,
      'text': 'Earthquake Relief',
      'items': '',
      'buttons': '',
    },
    {
      'href': `${BASE_URL}/contact-asu-global`,
      'text': 'Contact',
      'items': '',
      'buttons': '',
    },
  ],
  'logo': {
    'alt': 'Arizona State University',
    'src': 'https://www.asu.edu/asuthemes/5.0/assets/arizona-state-university-logo-vertical.png',
    'mobileSrc': 'https://global.asu.edu/modules/composer/webspark-module-asu_brand/node_modules/@asu-design-system/component-header/dist/assets/img/arizona-state-university-logo.png',
    'brandLink': 'https://www.asu.edu',
  },
  'searchUrl': 'https://search.asu.edu/search',
  'site': 'https://globalscope.asu.edu',
};

/**
 *
 * @param {string|undefined|null} attr - result of e.target.attributes?.['data-scrolls-header']?.value
 * @returns boolean
 */
const getShouldScroll = (attr) => {
  switch (attr) {
    case true:
    case 'true':
    case '':
      return true;
    default:
      return false;
  }
};

export const renderAsuHeader = () => {
  initGlobalHeader({
    targetSelector: '#header-container',
    props,
  });
  setTimeout(() => {
    document.body.addEventListener('scroll', (e) => {
      const shouldScroll = getShouldScroll(e.target.attributes?.['data-scrolls-header']?.value);
      if (!shouldScroll) return;
      const header = document.querySelector('header');
      const curPos = e.target.scrollTop;
      if (curPos > 0) {
        header.classList.add('scrolled');
      } else {
        header.classList.remove('scrolled');
      }
    }, { capture: true });
  }, 1000);
};
