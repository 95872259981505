/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import Fuse from 'fuse.js';

import router from '../router';
import { convertDashesToSpaces } from '../helpers/helpers';
import { buildCintanaPartnersByRegion, buildSummaryData } from '../data/buildData';
import { lookupIsoCode, isoCodeToCountry } from '../data/countryCodeLookup';
import { continentLatLong } from '../data/continentData';

import studyAbroadImg from '../assets/imgs/buckets/study-abroad.png';
import partnershipsImg from '../assets/imgs/buckets/academic-partnerships.png';
import educationServiceImg from '../assets/imgs/buckets/education-service.png';
import alumniImg from '../assets/imgs/buckets/global-alumni.png';
import locationsImg from '../assets/imgs/buckets/international-locations.png';
import studentsImg from '../assets/imgs/buckets/international-students.png';
import researchImg from '../assets/imgs/buckets/research-sponsored-projects.png';
import travelImg from '../assets/imgs/buckets/travel.png';
import { getBucketData, BASE_BUCKET_URL } from '../data/getBucketData';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    BASE_BUCKET_URL,
    selectedContinent: '',
    selectedCountry: '',
    selectedCountryCode: '',
    searchTerm: '',
    selectedCountryInSearch: null,
    currentBucket: '', // selected activity in country details view
    selectedBuckets: [1, 1, 1, 1, 1, 1, 1, 1], // indices correspond to index of bucket in the "buckets array". 1 = selected, 0 = not selected
    allData: null,
    bucketSummaryData: null, // all summary data
    language: 'en',
    buckets: [
      'Study Abroad',
      'Travel',
      'Research',
      'Students',
      'Locations',
      'Partnerships',
      'Education and Service',
      'Alumni',
    ],
    bucketsData: {
      'Study Abroad': {
        title: 'Study Abroad Programs',
        desc: 'ASU’s portfolio of global education abroad destinations and credit-bearing programs',
        style: 'Study Abroad',
        image: studyAbroadImg,
        filename: 'study_abroad',
      },
      'Travel': {
        title: 'Travel',
        desc: 'University approved international travel across ASU’s faculty, staff and student populations',
        style: 'Travel',
        image: travelImg,
        filename: 'travel',
      },
      'Research': {
        title: 'Research and Sponsored Projects',
        desc: 'Research and sponsored projects',
        style: 'Research',
        image: researchImg,
        contact: [{ label: 'Contact Us', email: 'global.ops@asu.edu' }],
        filename: 'research',
      },
      'Students': {
        title: 'International Students',
        desc: 'Home country data for ASU’s international student population',
        style: 'Students',
        image: studentsImg,
        contact: [{ label: 'Contact Us', email: 'asuglobal@asu.edu' }],
        filename: 'students',
      },
      'Locations': {
        title: 'International Locations',
        desc: 'ASU’s offices, hubs, and campuses around the globe',
        style: 'Locations',
        image: locationsImg,
        contact: [{ label: 'Contact Us', email: 'asuglobal@asu.edu' }],
        filename: 'locations',
      },
      'Partnerships': {
        title: 'Academic Partnerships',
        desc: 'Global academic partnerships',
        style: 'Partnerships',
        image: partnershipsImg,
        contact: [{ label: 'Contact Us', email: 'global.ops@asu.edu' }],
        filename: 'partnerships',
      },
      'Education and Service': {
        title: 'Education and Service',
        desc: 'Professional growth and global service learning',
        style: 'Education and Service',
        image: educationServiceImg,
        contact: [{ label: 'Contact Us', email: 'asuglobal@asu.edu' }],
        filename: 'education_and_service',
      },
      'Alumni': {
        title: 'Alumni Chapters',
        desc: 'ASU and Thunderbird graduates around the world',
        style: 'Alumni',
        image: alumniImg,
        contact: [
          { label: 'Contact ASU Alumni', email: 'alumni@asu.edu' },
          { label: 'Contact Thunderbird Alumni', email: 'tbirdalumni@thunderbird.asu.edu' },
        ],
        filename: 'alumni',
      },
    },
    cintanaPartnersFilterSelected: false,
    cintanaPartners: [
      'Leuphana Universität Lüneburg',
      'Universität Regensburg',
      'Macquarie University',
      'Universidad de los Andes',
    ],
    cintanaPartnersByRegion: null,
    lastUpdated: new Date(),
    isMobile: window.innerWidth <= 1000,
    windowWidth: window.innerWidth,
    introClosed: false,
  },
  mutations: {
    updateContinent(state, continent) {
      state.selectedContinent = continent;
    },
    updateCountry(state, isoCode) {
      if (isoCode !== '') {
        state.selectedCountryCode = isoCode;
        state.selectedCountry = isoCodeToCountry[isoCode].name; // convert to asu's country_truth name using the country 2 letter iso code as the shared key
      } else {
        state.selectedCountryCode = '';
        state.selectedCountry = '';
      }
    },
    updateSearchTerm(state, data) {
      state.searchTerm = data;
    },
    updateSelectedCountryInSearch(state, data) {
      state.selectedCountryInSearch = data;
    },
    updateSelectedBuckets(state, buckets) {
      state.selectedBuckets = buckets;
    },
    updateSelectedBucket(state, bucket) {
      const newSelectedBuckets = [...state.selectedBuckets];
      newSelectedBuckets[bucket] = newSelectedBuckets[bucket] === 1 ? 0 : 1;
      state.selectedBuckets = newSelectedBuckets;
    },
    updateCintanaPartnersFilterSelected(state, selected) {
      state.cintanaPartnersFilterSelected = selected;
    },
    updateCurrentBucket(state, bucket) {
      state.currentBucket = bucket;
    },
    updateAllData(state, data) {
      state.allData = data;
    },
    updateBucketSummaryData(state, data) {
      state.bucketSummaryData = { ...data };
    },
    updateCintanaPartnersByRegion(state, data) {
      state.cintanaPartnersByRegion = data;
    },
    updateIsMobile(state, data) {
      state.isMobile = data;
    },
    updateWindowWidth(state, data) {
      state.windowWidth = data;
    },
    updateIntroClosed(state, data) {
      state.introClosed = data;
    },
  },
  actions: {
    // fetch all activity data on initial page load
    getAllData({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        const allData = [];
        const buckets = Object.keys(state.bucketsData);
        const dataMap = {};
        try {
          // fetch all data files from s3 (one file per bucket)
          await Promise.all(
            buckets.map(async (bucket) => {
              dataMap[bucket] = await getBucketData(state.bucketsData[bucket]);
            }),
          );
          // combine repeated study abroad programs, keeping an array of different semesters and number of students per semester
          const studyAbroadObj = {};
          dataMap['Study Abroad'].forEach((record) => {
            let key;
            if (record.country_code) {
              // the same program can exist in multiple countries, so store a record of each per country
              key = `${record.program_name} ${record.country_code}`;
            } else {
              key = record.program_name;
            }
            if (studyAbroadObj[key]) {
              const yearAndTerm = `${record.program_year} ${record.program_term}`;
              if (studyAbroadObj[key].semesters[yearAndTerm]) {
                studyAbroadObj[key].semesters[yearAndTerm] += record.count_distinct_user_id;
              } else {
                studyAbroadObj[key].semesters[yearAndTerm] = record.count_distinct_user_id;
              }
              studyAbroadObj[key].students += record.count_distinct_user_id;
            } else {
              let regionName = record.region_name;
              if (regionName === 'Americas') {
                if (record.intermediate_region_name === 'South America') {
                  regionName = 'South America';
                } else {
                  regionName = 'North America';
                }
              }
              const yearAndTerm = `${record.program_year} ${record.program_term}`;
              studyAbroadObj[key] = {
                ...record,
                bucket: 'Study Abroad',
                region_name: regionName,
                semesters: {},
                students: record.count_distinct_user_id,
              };
              studyAbroadObj[key].semesters[yearAndTerm] = record.count_distinct_user_id;
            }
          });
          allData.push(...Object.values(studyAbroadObj));
          // combine all json data files into a single array
          buckets.forEach((bucket) => {
            if (bucket !== 'Study Abroad') {
              dataMap[bucket].forEach((record) => {
                record.bucket = bucket;
                if (record.region_name === 'Americas') {
                  if (!record.country_code) {
                    console.log('ISO does not exist for', record.country_truth);
                    return;
                  }
                  const country = isoCodeToCountry[record.country_code];
                  if (!country || !country.continent) {
                    console.log('Continent does not exist for', record.country_truth);
                    return;
                  }
                  if (country.continent === 'South America') {
                    record.region_name = 'South America';
                  } else {
                    record.region_name = 'North America';
                  }
                }
                if (record.country_truth === 'Antarctica') {
                  record.region_name = 'Antarctica';
                }
                allData.push(record);
              });
            }
          });
          commit('updateAllData', allData);
          commit('updateBucketSummaryData', buildSummaryData(state.allData, state.buckets));
          commit(
            'updateCintanaPartnersByRegion',
            buildCintanaPartnersByRegion(state.allData, state.cintanaPartners),
          );
          resolve();
        } catch (err) {
          console.log(`Failed because: ${err}`);
          reject(err);
        }
      });
    },
    resolveRouteParams({ commit }) {
      const { params, query } = router.currentRoute;
      if (query.selectedBuckets) {
        const newFilter = query.selectedBuckets.split(',').map(x => +x); // split to array and conver to numbers
        if (newFilter) {
          commit('updateSelectedBuckets', newFilter)
        }
      }
      if (query.activity) {
        commit('updateCurrentBucket', query.activity);
      }
      if (params.continent) {
        const continent = convertDashesToSpaces(params.continent);
        if (continentLatLong[continent]) {
          commit('updateContinent', continent);
        } else {
          router.replace('/');
        }
      } else {
        commit('updateContinent', '');
      }
      if (params.country) {
        const country = convertDashesToSpaces(params.country);
        const isoCode = lookupIsoCode(country);
        if (isoCode) {
          commit('updateCountry', isoCode);
        } else {
          router.replace('/');
        }
      } else {
        commit('updateCountry', '');
      }
      if (query.term) {
        commit('updateSearchTerm', query.term);
      }

    },
  },
  getters: {
    selectedSummaryData(state, getters) {
      // summary data filtered by buckets, and possibly by continent, used to display stats and build markers
      // {
      //   Africa: {
      //     total: num,
      //     countries: {

      //     }
      //   }
      // }
      if (state.allData && Object.keys(getters.filteredSummaryData).length > 0) {
        let newData;
        if (state.selectedContinent !== '') {
          newData = getters.filteredSummaryData.continents[state.selectedContinent].countries;
        } else {
          newData = getters.filteredSummaryData.continents;
        }
        return newData;
      }
      return {};
    },
    filteredSummaryData(state, getters) {
      if (!state.allData || !state.bucketSummaryData) {
        return null;
      }
      // get data from selected buckets
      let selectedbucketDataList = state.selectedBuckets.reduce((acc, enabled, i) => {
        if (enabled) {
          acc.push(state.bucketSummaryData[state.buckets[i]]);
        }
        return acc;
      }, []);

      // if the cintana partners filter is selected then only show cintana partners.
      if (state.cintanaPartnersFilterSelected) {
        selectedbucketDataList = [state.cintanaPartnersByRegion];
      }
      // summary data filtered by selected buckets
      let newData = {};
      selectedbucketDataList.forEach((bucketData, i) => {
        if (i === 0) {
          newData = cloneDeep(bucketData);
        } else {
          // join data from different buckets
          newData.total += bucketData.total;
          Object.keys(bucketData.continents).forEach((continent) => {
            if (!newData.continents[continent]) {
              // data for this continent hasnt been added yet
              newData.continents[continent] = cloneDeep(bucketData.continents[continent]);
            } else {
              newData.continents[continent].total += bucketData.continents[continent].total;
              const countriesExisting = newData.continents[continent].countries;
              const countriesToAdd = bucketData.continents[continent].countries;
              Object.keys(countriesToAdd).forEach((country) => {
                if (countriesExisting[country] !== undefined) {
                  countriesExisting[country] += countriesToAdd[country];
                } else {
                  countriesExisting[country] = countriesToAdd[country];
                }
              });
            }
          });
        }
      });
      return newData;
    },
    filteredSearchData(state) {
      if (state.searchTerm) {
        const options = {
          shouldSort: true,
          threshold: 0.2,
          location: 0,
          distance: 100,
          minMatchCharLength: 2,
          keys: [
            'chapter_name',
            'country_truth',
            'region_name',
            'subregion_name',
            'intermediate_region_name',
            'description',
            'city',
            'partner_institutions',
            'college_short_descr',
            'program_name',
            'program_city',
          ],
        };
        const fuse = new Fuse(state.allData, options);
        const result = fuse.search(state.searchTerm);
        return result;
      }
      return [];
    },
    parsedCountriesList() {
      const countries = [];
      Object.entries(isoCodeToCountry).forEach(([isoCode, country]) => {
        countries.push({
          isoCode,
          country: country.name,
          continent: country.continent,
        });
      });
      return countries;
    },
    filteredSearchCountries(state, getters) {
      if (!state.searchTerm) {
        return [];
      }

      const options = {
        shouldSort: true,
        includeScore: true,
        threshold: 0.2,
        location: 0,
        distance: 100,
        minMatchCharLength: 2,
        keys: ['country'],
      };
      const fuse = new Fuse(getters.parsedCountriesList, options);
      const result = fuse.search(state.searchTerm);

      // Filter out countries that have no available activities
      const isoCodes = getters.filteredSearchData.map(record => lookupIsoCode(record.item.country_truth));
      return result.filter(record => isoCodes.indexOf(record.item.isoCode) !== -1);
    },
    totalResults(state, getters) {
      let total = 0;
      const data = getters.selectedSummaryData;
      if (Object.keys(data).length > 0) {
        Object.keys(data).forEach((location) => {
          if (data[location].total !== undefined) {
            total += data[location].total;
          } else {
            total += data[location];
          }
        });
      }
      return total;
    },
    isBucketEnabled: state => bucket => !!state.selectedBuckets[state.buckets.indexOf(bucket)],
    isCintanaPartner: state => activity => activity.in_cintana_alliance === true,
  },
});
