<template>
  <div class="wrapper">
    <div id="map" data-test="world-map-wrapper"></div>
    <ContinentSummary v-if="!selectedContinent && totalResults > 0" @locationhover="mouseOverLocationSummary" />
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { mapState, mapGetters } from 'vuex';

import ContinentSummary from './ContinentSummary.vue';
import { addLayers } from '../helpers/mapHelpers';
import { buildContinentMarkers, buildCountryMarkers } from '../data/buildData';
import { convertSpacesToDashes } from '../helpers/helpers';
import { continentNames, continentBounds, continentLatLong } from '../data/continentData';
import { isoCodeToCountry } from '../data/countryCodeLookup';

import mapStyle from './mapStyle.json';

export default {
  components: {
    ContinentSummary,
  },
  data() {
    return {
      // accessToken: process.env.VUE_APP_MAPBOX_KEY,
      countryLabelFilter: ['==', 'type', 'country'],
    };
  },
  computed: {
    ...mapState([
      'bucketSummaryData',
      'selectedContinent',
      'selectedBuckets',
      'language',
      'isMobile',
      'windowWidth',
    ]),
    ...mapGetters(['selectedSummaryData', 'totalResults']),
    minZoom() {
      let zoom = 0;
      if (this.windowWidth > 1500) {
        zoom = 1.3;
      } else if (this.windowWidth > 1000) {
        zoom = 1.1;
      } else if (this.windowWidth > 800) {
        zoom = 0.8;
      } else if (this.windowWidth > 600) {
        zoom = 0;
      }
      return zoom;
    },
    zoomOutLevel() {
      return this.minZoom + 0.5;
    },
  },
  created() {
    this.mapbox = Mapbox;
    this.map = null;
    this.popup = null;
    this.listeners = {};
    this.debounce = {};
    this.markers = {};
  },
  mounted() {
    // read only access to map style
    const PUBLIC_KEY = 'pk.eyJ1IjoiYWNwaWVyYzMiLCJhIjoiY2szZ2UzcXdrMDB3dDNibXlsMm5hdXZyayJ9.CDw07NqV_NZXqT228bP-9g';
    Mapbox.accessToken = PUBLIC_KEY;

    this.map = new Mapbox.Map({
      container: 'map',
      style: mapStyle,
      zoom: this.minZoom,
      fadeDuration: 0, // fade duration for country labels
      antialias: false,
      crossSourceCollisions: false, // allow clusters to collide with country labels without making the labels disappear
      // attributionControl: false
    });
    // this.map.addControl(new Mapbox.AttributionControl(), 'top-right');

    this.popup = new Mapbox.Popup({
      // create popup to display country name, but dont add it to the map yet
      closeButton: false,
    });

    this.map.on('load', this.onLoad);
  },
  watch: {
    selectedContinent(newContinent) {
      // monitor for a change in the selected continent, and trigger changes on selection
      this.toggleContinents(newContinent);
      this.markers = this.buildMarkers();
      this.map.getSource('markers').setData(this.markers);
    },
    selectedBuckets() {
      // when bucket filters are changed, update the activity markers on the map
      this.markers = this.buildMarkers();
      this.map.getSource('markers').setData(this.markers);
      if (this.selectedContinent) {
        const countryCodes = Object.keys(this.selectedSummaryData); // filter countries down to only the selected continent
        this.map.setFilter('countries', ['in', 'ISO_A2'].concat([...countryCodes]));
      }
    },
  },
  methods: {
    mouseOverLocationSummary(e) {
      const location = e.target.innerText.split(':')[0];
      this.listeners[location](e, true);
    },
    buildMarkers() {
      const store = this.$store;
      if (store.state.bucketSummaryData) {
        let markers;
        if (store.state.selectedContinent) {
          markers = buildCountryMarkers(store);
        } else {
          markers = buildContinentMarkers(store);
        }
        return markers;
      }
      return {};
    },
    setContinentOpacity(value) {
      Object.keys(continentNames).forEach((prop) => {
        this.map.setPaintProperty(continentNames[prop], 'fill-opacity', value);
      });
    },
    toggleContinentLayers(value) {
      // value === 'off' filters out all continent polygon layers
      // value === 'on' re-enables all continent polygon layers
      let filter;
      if (value === 'off') {
        filter = ['in', 'BRK_NAME'];
      }
      Object.keys(continentNames).forEach((prop) => {
        this.map.setFilter(continentNames[prop], filter);
      });
    },
    toggleContinentHoverListeners(value) {
      const vm = this;
      if (value === 'on') {
        // store the event listeners on this so they can properly be removed later

        Object.keys(continentNames).forEach((prop) => {
          vm.listeners[continentNames[prop]] = function (e, isHoverOverName) {
            // only have hover styles/functionality if continent has activities
            if (vm.selectedSummaryData && vm.selectedSummaryData[continentNames[prop]] && vm.selectedSummaryData[continentNames[prop]].total) {
              if (e.type === 'mouseleave') {
                if (!isHoverOverName) {
                  // hover event is coming from the map, not the list of continent names in ContinentSummary
                  vm.map.getCanvas().style.cursor = '';
                }
                vm.map.setFilter('unclustered-point'); // remove cluster filter
                vm.map.setFilter('cluster-count'); // remove cluster count filter
                vm.popup.remove();
                vm.debounce[continentNames[prop]] = setTimeout(() => {
                  vm.map.setPaintProperty(continentNames[prop], 'fill-opacity', 0);
                }, 300);
              } else {
                let newContLatLong;

                if (!isHoverOverName) {
                  newContLatLong = e.lngLat;
                  vm.map.getCanvas().style.cursor = 'pointer';
                } else {
                  newContLatLong = continentLatLong[continentNames[prop]];
                }
                vm.popup.setLngLat(newContLatLong).setHTML(vm.popupHtml(continentNames[prop], vm.selectedSummaryData[continentNames[prop]].total)).addTo(vm.map);
                clearTimeout(vm.debounce[continentNames[prop]]);
                vm.map.setPaintProperty(continentNames[prop], 'fill-opacity', 1);
                vm.map.setFilter('unclustered-point', ['!=', 'name', continentNames[prop]]);
                vm.map.setFilter('cluster-count', ['!=', 'name', continentNames[prop]]);
              }
            }
          };
        });
      }

      Object.keys(continentNames).forEach((prop) => {
        vm.map[value]('mousemove', continentNames[prop], vm.listeners[continentNames[prop]]);
        vm.map[value]('mouseleave', continentNames[prop], vm.listeners[continentNames[prop]]);
      });
    },
    toggleCountryHoverListeners(value) {
      const vm = this;
      if (value === 'on') {
        // ALL COUNTRIES
        vm.listeners.countries = function (e, isHoverOverName) {
          if (e.type === 'mouseleave') {
            if (!isHoverOverName) {
              // hover event is coming from the map, not the list of country names
              vm.map.getCanvas().style.cursor = '';
              vm.popup.remove();
            }

            vm.debounce.countries = setTimeout(() => {
              if (vm.hoveredCountryId) {
                vm.map.setFeatureState(
                  {
                    source: 'countriesSource',
                    sourceLayer: 'ne_10m_admin_0_countries_30MA-di6mr6',
                    id: vm.hoveredCountryId,
                  },
                  { hover: false },
                );
                if (isHoverOverName) {
                  vm.popup.remove();
                }
                vm.map.setFilter('unclustered-point'); // remove cluster filter
                vm.map.setFilter('cluster-count'); // remove cluster count filter
                vm.map.setFilter('country-label', vm.countryLabelFilter); // show all country labels
              }
              vm.hoveredCountryId = null;
            }, 300);
          } else if (e.features.length > 0) {
            const newCountryId = e.features[0].id;
            const newCountryName = isoCodeToCountry[e.features[0].properties.ISO_A2].name;
            const newCountryCode = e.features[0].properties.ISO_A2;
            const newCountryLngLat = e.lngLat;
            vm.map.getCanvas().style.cursor = 'pointer';
            vm.popup.setLngLat(newCountryLngLat).setHTML(vm.popupHtml(newCountryName, vm.selectedSummaryData[newCountryCode])).addTo(vm.map);

            clearTimeout(vm.debounce.countries);

            if (newCountryId && newCountryId !== vm.hoveredCountryId) {
              if (vm.hoveredCountryId) {
                vm.map.setFeatureState(
                  {
                    source: 'countriesSource',
                    sourceLayer: 'ne_10m_admin_0_countries_30MA-di6mr6',
                    id: vm.hoveredCountryId,
                  },
                  { hover: false },
                );
              }
              vm.hoveredCountryId = newCountryId;
              vm.map.setFilter('unclustered-point', ['!=', 'name', newCountryName]);
              vm.map.setFilter('cluster-count', ['!=', 'name', newCountryName]);
              vm.map.setFilter('country-label', [
                'all',
                ['!=', `name_${vm.language}`, newCountryName],
                vm.countryLabelFilter,
              ]); // hide highlighted country's label

              vm.map.setFeatureState(
                {
                  source: 'countriesSource',
                  sourceLayer: 'ne_10m_admin_0_countries_30MA-di6mr6',
                  id: vm.hoveredCountryId,
                },
                { hover: true },
              );
            }
          }
        };
      }

      vm.map[value]('mousemove', 'countries', vm.listeners.countries);
      vm.map[value]('mouseleave', 'countries', vm.listeners.countries);
    },
    toggleContinents(newContinent) {
      // user de-selects continent
      if (newContinent === '') {
        this.popup.remove();
        this.map.setMinZoom(this.minZoom);

        this.toggleContinentHoverListeners('on');
        this.toggleContinentLayers('on');
        this.toggleCountryHoverListeners('off');
        this.map.setLayoutProperty('country-label', 'visibility', 'none');
        this.map.setFilter('countries', ['in', 'ISO_A2'].concat([]));
        this.map.easeTo({ center: [0, 10], zoom: this.minZoom });
        this.map.setFilter('unclustered-point'); // remove cluster filter
        this.map.setFilter('cluster-count'); // remove cluster count filter
      } else {
        // user selects new continent
        this.popup.remove();
        this.map.fitBounds(continentBounds[newContinent]);
        this.toggleContinentHoverListeners('off');
        this.toggleCountryHoverListeners('on');
        this.setContinentOpacity(0);
        this.toggleContinentLayers('off');
        this.map.setLayoutProperty('country-label', 'visibility', 'visible');

        if (this.selectedSummaryData) {
          const countryCodes = Object.keys(this.selectedSummaryData); // filter countries down to only countries within the selected continent that have activities
          this.map.setFilter('countries', ['in', 'ISO_A2'].concat([...countryCodes]));
        }
      }
    },
    // onLoad(event) {
    onLoad() {
      // on initial map load
      const vm = this;

      // disable map rotation using right click + drag
      vm.map.dragRotate.disable();

      // disable map rotation using touch rotation gesture
      vm.map.touchZoomRotate.disableRotation();

      // helpful for debugging
      // vm.map.showTileBoundaries = true;
      // vm.map.showCollisionBoxes = true;

      // add data and vector layers to the map
      addLayers(vm.map, this.buildMarkers());

      Object.keys(continentNames).forEach((prop) => {
        this.map.on('click', continentNames[prop], () => {
          if (vm.selectedSummaryData && vm.selectedSummaryData[continentNames[prop]] && vm.selectedSummaryData[continentNames[prop]].total) {
            vm.$router.push({
              path: `/${convertSpacesToDashes(continentNames[prop])}`,
              query: {
                selectedBuckets: vm.selectedBuckets.toString(),
              },
            });
          }
        });
      });

      this.map.on('click', 'countries', (mapElement) => {
        vm.$router.push({
          path: convertSpacesToDashes(
            isoCodeToCountry[mapElement.features[0].properties.ISO_A2].name,
          ), // parsed country name, e.g. south-africa
          append: true,
        });
      });

      // functionality for when a continent is selected and a user wants to zoom all the way out
      // using the scroll wheel to deselct the continent.
      // const minZoom = this.isMobile ? 0.5 : 1.7;
      vm.zoomLevel = vm.map.getZoom();
      this.map.on('zoom', () => {
        const currentZoom = vm.map.getZoom();
        if (currentZoom < vm.zoomLevel && currentZoom < this.zoomOutLevel && vm.selectedContinent) {
          vm.$router.push({ path: '/' });
        }
        vm.zoomLevel = currentZoom;
      });

      this.map.on('click', () => {
        // console.log("CLICK", e.lngLat.lng, e.lngLat.lat)
        // console.log("CLICK", e.features[0])
      });

      vm.toggleContinents(vm.selectedContinent); // toggle continent hover detection
    },
    popupHtml(region, count) {
      return `
        <div class="text">
          <div class="region-name">${region}</div>
          <div><span class="bold">${count}</span> Activity Result${count === 1 ? '' : 's'}</div>
        </div>
        <div class="arrow-icon">\uf061</div>
      `;
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>

<style>
#map {
  width: 100%;
  height: 100%;
}
.mapboxgl-popup-content {
  display: flex;
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 4px;
  overflow: hidden;
  background: none;
  padding: 0;
}
.mapboxgl-popup-content .text {
  background-color: rgba(255, 198, 39, 0.9);
  color: #000000;
  font-size: 12px;
  padding: 5px;
}
.mapboxgl-popup-content .text .region-name {
  font-size: 13px;
  font-weight: 700;
}
.mapboxgl-popup-content .text .bold {
  font-weight: 700;
}
.mapboxgl-popup-content .arrow-icon {
  display: flex;
  align-self: stretch;
  align-items: center;
  font-family: 'FontAwesome';
  font-size: 14px;
  background-color: var(--asu-maroon);
  color: #ffffff;
  padding: 0 10px;
  margin: 0;
}

@media only screen and (max-width: 1000px),
  only screen and (max-device-width: 1000px)
  and (orientation: landscape) {
  .mapboxgl-ctrl {
    margin-bottom: 0 !important;
  }
  .mapboxgl-ctrl-logo {
    width: 60px !important;
  }
  .mapboxgl-ctrl-attrib {
    line-height: 10px !important;
    font-size: 8px !important;
  }
  .mapboxgl-ctrl-attrib-inner {
    line-height: 10px !important;
    font-size: 8px !important;
  }
  .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
    width: 18px !important;
    height: 18px !important;
    margin-right: -8px;
    margin-bottom: 5px;
  }
}
</style>
