<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: ['title', 'labels', 'datasets'],
  mounted() {
    this.addPlugin({
      id: 'draw-title',
      beforeDraw: this.drawTitle,
    });
    const chartData = {
      labels: this.labels,
      datasets: this.datasets,
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            fontStyle: 'italic',
          },
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            suggestedMax: 2,
            beginAtZero: true,
            precision: 0,
          },
        }],
      },
      legend: {
        align: 'end',
        labels: {
          boxWidth: 14,
        },
      },
    };

    this.renderChart(chartData, options);
  },
  methods: {
    // chartjs' built-in title is always center-aligned, so we render our own left-aligned title here
    drawTitle(chart) {
      const ctx = chart.chart.ctx;
      ctx.restore();
      ctx.font = '18px Arial, Helvetica, sans-serif';
      ctx.textBaseline = 'top';

      const textX = 0;
      const textY = 0;

      ctx.fillText(`${this.title}`, textX, textY);
      ctx.save();
    },
  },
};
</script>
