<template>
  <div class="side-nav-wrapper">
    <div class="header">
      <div class="search-container">
        <img v-if="!isMobile" src="../assets/asu_university_horiz_rgb_maroongold_150_4.png" alt="Arizona State University" class="asu-logo">
        <SearchBox />
      </div>
    </div>
    <div class="content-container">
      <SearchResults />
      <GlobalEngagementStories />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchResults from './SearchResults.vue';
import GlobalEngagementStories from './GlobalEngagementStories.vue';
import SearchBox from './SearchBox.vue';

export default {
  components: {
    SearchResults,
    GlobalEngagementStories,
    SearchBox,
  },
  computed: {
    ...mapState(['isMobile']),
  },
  methods: {},
};
</script>

<style scoped>
.side-nav-wrapper {
  width: 25%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 4px 0 8px 1px rgba(0, 0, 0, 0.1);
  z-index: 50;
  display: flex;
  flex-direction: column;
}
.header {
  position: relative;
  margin-top: 2vh;
}
.search-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}
.search-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  z-index: -1;
  background-color: #f4f4f4;
}
.asu-logo {
  width: 100%;
  padding: 0 20px;
}
.content-container {
  background-color: #f4f4f4;
  height: 100%;
  padding-top: 10px;
  overflow-y: scroll;
}
@media (max-width: 769px) {
  .side-nav-wrapper {
    width: 40%;
  }
}
@media only screen and (max-width: 1000px),
  only screen and (max-device-width: 1000px)
  and (orientation: landscape) {
  .side-nav-wrapper {
    width: 100%;
    padding-top: 30px;
    margin-bottom: 2vh;
    box-shadow: none;
    background-color: #f4f4f4;
  }
  .header {
    margin: 0;
  }
  .content-container {
    overflow-y: unset;
    height: unset;
  }
}
</style>
