<template>
  <div class="chart-container">
    <h2>Education and Service</h2>
    <div class="doughnut-row">
      <div v-for="chart in charts" v-bind:key="chart.label" class="doughnut-container">
        <Doughnut :amount="chart.amount" :total="total" :color="chart.color" :styles="styles" />
        <div class="chart-label">{{ chart.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Doughnut from './Doughnut.vue';

export default {
  components: { Doughnut },
  props: ['activities'],
  data() {
    return {
      charts: [],
      total: 0,
      styles: {
        width: '200px',
        margin: '10px 0',
      },
      dataSourceToLabel: {
        'Global Launch': 'Global Launch Users',
        'Peace Corps': 'Peace Corps Volunteer Posts',
        'NGL': 'McCain Institute Next Generation Leaders',
        'Fulbright': 'Fulbright Scholar Destinations',
        'HHH Fellows': 'Humphrey Fellows',
      },
      chartColors: ['#00a3e0', '#8c1d40', '#ffc627'],
    };
  },
  mounted() {
    this.total = this.activities.reduce((accumulator, activity) => accumulator + activity.learners_count, 0);
    // sort activities by number of learners
    const sortedActivities = this.activities.sort((a, b) => (a.learners_count > b.learners_count ? -1 : 1));
    sortedActivities.forEach((activity, index) => {
      this.charts.push({
        amount: activity.learners_count,
        label: this.dataSourceToLabel[activity.data_source] || activity.category_name,
        color: this.chartColors[index % 3],
      });
    });
  },
};
</script>

<style scoped>
.chart-container h2 {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  margin: 30px 40px;
}
.doughnut-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.doughnut-container {
  width: 200px;
  margin: 0 20px;
}
.chart-label {
  color: #000000;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin: 20px 0;
}
</style>
