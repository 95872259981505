export default {
  'AF': {
    'population': 27657145,
    'timezones': [
      'UTC+04:30',
    ],
    'currencies': [
      {
        'code': 'AFN',
        'name': 'Afghan afghani',
        'symbol': '؋',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ps',
        'iso639_2': 'pus',
        'name': 'Pashto',
        'nativeName': 'پښتو',
      },
      {
        'iso639_1': 'uz',
        'iso639_2': 'uzb',
        'name': 'Uzbek',
        'nativeName': 'Oʻzbek',
      },
      {
        'iso639_1': 'tk',
        'iso639_2': 'tuk',
        'name': 'Turkmen',
        'nativeName': 'Türkmen',
      },
    ],
    'subregion': 'Southern Asia',
    'capital': 'Kabul',
    'latlng': [
      33,
      65,
    ],
    'demonym': 'Afghan',
  },
  'AL': {
    'population': 2886026,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'ALL',
        'name': 'Albanian lek',
        'symbol': 'L',
      },
    ],
    'languages': [
      {
        'iso639_1': 'sq',
        'iso639_2': 'sqi',
        'name': 'Albanian',
        'nativeName': 'Shqip',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Tirana',
    'latlng': [
      41,
      20,
    ],
    'demonym': 'Albanian',
  },
  'DZ': {
    'population': 40400000,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'DZD',
        'name': 'Algerian dinar',
        'symbol': 'د.ج',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Northern Africa',
    'capital': 'Algiers',
    'latlng': [
      28,
      3,
    ],
    'demonym': 'Algerian',
  },
  'AS': {
    'population': 57100,
    'timezones': [
      'UTC-11:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States Dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'sm',
        'iso639_2': 'smo',
        'name': 'Samoan',
        'nativeName': "gagana fa'a Samoa",
      },
    ],
    'subregion': 'Polynesia',
    'capital': 'Pago Pago',
    'latlng': [
      -14.33333333,
      -170,
    ],
    'demonym': 'American Samoan',
  },
  'AD': {
    'population': 78014,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ca',
        'iso639_2': 'cat',
        'name': 'Catalan',
        'nativeName': 'català',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Andorra la Vella',
    'latlng': [
      42.5,
      1.5,
    ],
    'demonym': 'Andorran',
  },
  'AO': {
    'population': 25868000,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'AOA',
        'name': 'Angolan kwanza',
        'symbol': 'Kz',
      },
    ],
    'languages': [
      {
        'iso639_1': 'pt',
        'iso639_2': 'por',
        'name': 'Portuguese',
        'nativeName': 'Português',
      },
    ],
    'subregion': 'Middle Africa',
    'capital': 'Luanda',
    'latlng': [
      -12.5,
      18.5,
    ],
    'demonym': 'Angolan',
  },
  'AI': {
    'population': 13452,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'XCD',
        'name': 'East Caribbean dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'The Valley',
    'latlng': [
      18.25,
      -63.16666666,
    ],
    'demonym': 'Anguillian',
  },
  'AQ': {
    'population': 1000,
    'timezones': [
      'UTC-03:00',
      'UTC+03:00',
      'UTC+05:00',
      'UTC+06:00',
      'UTC+07:00',
      'UTC+08:00',
      'UTC+10:00',
      'UTC+12:00',
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'ru',
        'iso639_2': 'rus',
        'name': 'Russian',
        'nativeName': 'Русский',
      },
    ],
    'subregion': 'Antarctica',
    'latlng': [
      -74.65,
      4.48,
    ],
    'demonym': 'Antarctic',
  },
  'AG': {
    'population': 86295,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'XCD',
        'name': 'East Caribbean dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': "Saint John's",
    'latlng': [
      17.05,
      -61.8,
    ],
    'demonym': 'Antiguan, Barbudan',
  },
  'AR': {
    'population': 43590400,
    'timezones': [
      'UTC-03:00',
    ],
    'currencies': [
      {
        'code': 'ARS',
        'name': 'Argentine peso',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
      {
        'iso639_1': 'gn',
        'iso639_2': 'grn',
        'name': 'Guaraní',
        'nativeName': "Avañe'ẽ",
      },
    ],
    'subregion': 'South America',
    'capital': 'Buenos Aires',
    'latlng': [
      -34,
      -64,
    ],
    'demonym': 'Argentinean',
  },
  'AM': {
    'population': 2994400,
    'timezones': [
      'UTC+04:00',
    ],
    'currencies': [
      {
        'code': 'AMD',
        'name': 'Armenian dram',
        'symbol': '֏',
      },
    ],
    'languages': [
      {
        'iso639_1': 'hy',
        'iso639_2': 'hye',
        'name': 'Armenian',
        'nativeName': 'Հայերեն',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Yerevan',
    'latlng': [
      40,
      45,
    ],
    'demonym': 'Armenian',
  },
  'AW': {
    'population': 107394,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'AWG',
        'name': 'Aruban florin',
        'symbol': 'ƒ',
      },
    ],
    'languages': [
      {
        'iso639_1': 'nl',
        'iso639_2': 'nld',
        'name': 'Dutch',
        'nativeName': 'Nederlands',
      },
      {
        'iso639_1': 'pa',
        'iso639_2': 'pan',
        'name': '(Eastern) Punjabi',
        'nativeName': 'ਪੰਜਾਬੀ',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Oranjestad',
    'latlng': [
      12.5,
      -69.96666666,
    ],
    'demonym': 'Aruban',
  },
  'AU': {
    'population': 24117360,
    'timezones': [
      'UTC+05:00',
      'UTC+06:30',
      'UTC+07:00',
      'UTC+08:00',
      'UTC+09:30',
      'UTC+10:00',
      'UTC+10:30',
      'UTC+11:30',
    ],
    'currencies': [
      {
        'code': 'AUD',
        'name': 'Australian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Australia and New Zealand',
    'capital': 'Canberra',
    'latlng': [
      -27,
      133,
    ],
    'demonym': 'Australian',
  },
  'AT': {
    'population': 8725931,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'de',
        'iso639_2': 'deu',
        'name': 'German',
        'nativeName': 'Deutsch',
      },
    ],
    'subregion': 'Central Europe',
    'capital': 'Vienna',
    'latlng': [
      47.33333333,
      13.33333333,
    ],
    'demonym': 'Austrian',
  },
  'AZ': {
    'population': 9730500,
    'timezones': [
      'UTC+04:00',
    ],
    'currencies': [
      {
        'code': 'AZN',
        'name': 'Azerbaijani manat',
        'symbol': '₼',
      },
    ],
    'languages': [
      {
        'iso639_1': 'az',
        'iso639_2': 'aze',
        'name': 'Azerbaijani',
        'nativeName': 'azərbaycan dili',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Baku',
    'latlng': [
      40.5,
      47.5,
    ],
    'demonym': 'Azerbaijani',
  },
  'BS': {
    'population': 378040,
    'timezones': [
      'UTC-05:00',
    ],
    'currencies': [
      {
        'code': 'BSD',
        'name': 'Bahamian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Nassau',
    'latlng': [
      24.25,
      -76,
    ],
    'demonym': 'Bahamian',
  },
  'BH': {
    'population': 1404900,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'BHD',
        'name': 'Bahraini dinar',
        'symbol': '.د.ب',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Manama',
    'latlng': [
      26,
      50.55,
    ],
    'demonym': 'Bahraini',
  },
  'BD': {
    'population': 161006790,
    'timezones': [
      'UTC+06:00',
    ],
    'currencies': [
      {
        'code': 'BDT',
        'name': 'Bangladeshi taka',
        'symbol': '৳',
      },
    ],
    'languages': [
      {
        'iso639_1': 'bn',
        'iso639_2': 'ben',
        'name': 'Bengali',
        'nativeName': 'বাংলা',
      },
    ],
    'subregion': 'Southern Asia',
    'capital': 'Dhaka',
    'latlng': [
      24,
      90,
    ],
    'demonym': 'Bangladeshi',
  },
  'BB': {
    'population': 285000,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'BBD',
        'name': 'Barbadian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Bridgetown',
    'latlng': [
      13.16666666,
      -59.53333333,
    ],
    'demonym': 'Barbadian',
  },
  'BY': {
    'population': 9498700,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'BYN',
        'name': 'New Belarusian ruble',
        'symbol': 'Br',
      },
      {
        'code': 'BYR',
        'name': 'Old Belarusian ruble',
        'symbol': 'Br',
      },
    ],
    'languages': [
      {
        'iso639_1': 'be',
        'iso639_2': 'bel',
        'name': 'Belarusian',
        'nativeName': 'беларуская мова',
      },
      {
        'iso639_1': 'ru',
        'iso639_2': 'rus',
        'name': 'Russian',
        'nativeName': 'Русский',
      },
    ],
    'subregion': 'Eastern Europe',
    'capital': 'Minsk',
    'latlng': [
      53,
      28,
    ],
    'demonym': 'Belarusian',
  },
  'BE': {
    'population': 11319511,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'nl',
        'iso639_2': 'nld',
        'name': 'Dutch',
        'nativeName': 'Nederlands',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'de',
        'iso639_2': 'deu',
        'name': 'German',
        'nativeName': 'Deutsch',
      },
    ],
    'subregion': 'Western Europe',
    'capital': 'Brussels',
    'latlng': [
      50.83333333,
      4,
    ],
    'demonym': 'Belgian',
  },
  'BZ': {
    'population': 370300,
    'timezones': [
      'UTC-06:00',
    ],
    'currencies': [
      {
        'code': 'BZD',
        'name': 'Belize dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Central America',
    'capital': 'Belmopan',
    'latlng': [
      17.25,
      -88.75,
    ],
    'demonym': 'Belizean',
  },
  'BJ': {
    'population': 10653654,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'XOF',
        'name': 'West African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Porto-Novo',
    'latlng': [
      9.5,
      2.25,
    ],
    'demonym': 'Beninese',
  },
  'BM': {
    'population': 61954,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'BMD',
        'name': 'Bermudian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Northern America',
    'capital': 'Hamilton',
    'latlng': [
      32.33333333,
      -64.75,
    ],
    'demonym': 'Bermudian',
  },
  'BT': {
    'population': 775620,
    'timezones': [
      'UTC+06:00',
    ],
    'currencies': [
      {
        'code': 'BTN',
        'name': 'Bhutanese ngultrum',
        'symbol': 'Nu.',
      },
      {
        'code': 'INR',
        'name': 'Indian rupee',
        'symbol': '₹',
      },
    ],
    'languages': [
      {
        'iso639_1': 'dz',
        'iso639_2': 'dzo',
        'name': 'Dzongkha',
        'nativeName': 'རྫོང་ཁ',
      },
    ],
    'subregion': 'Southern Asia',
    'capital': 'Thimphu',
    'latlng': [
      27.5,
      90.5,
    ],
    'demonym': 'Bhutanese',
  },
  'BO': {
    'population': 10985059,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'BOB',
        'name': 'Bolivian boliviano',
        'symbol': 'Bs.',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
      {
        'iso639_1': 'ay',
        'iso639_2': 'aym',
        'name': 'Aymara',
        'nativeName': 'aymar aru',
      },
      {
        'iso639_1': 'qu',
        'iso639_2': 'que',
        'name': 'Quechua',
        'nativeName': 'Runa Simi',
      },
    ],
    'subregion': 'South America',
    'capital': 'Sucre',
    'latlng': [
      -17,
      -65,
    ],
    'demonym': 'Bolivian',
  },
  'BQ': {
    'population': 17408,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'nl',
        'iso639_2': 'nld',
        'name': 'Dutch',
        'nativeName': 'Nederlands',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Kralendijk',
    'latlng': [
      12.15,
      -68.266667,
    ],
    'demonym': 'Dutch',
  },
  'BA': {
    'population': 3531159,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'BAM',
        'name': 'Bosnia and Herzegovina convertible mark',
        'symbol': 'KM',
      },
    ],
    'languages': [
      {
        'iso639_1': 'bs',
        'iso639_2': 'bos',
        'name': 'Bosnian',
        'nativeName': 'bosanski jezik',
      },
      {
        'iso639_1': 'hr',
        'iso639_2': 'hrv',
        'name': 'Croatian',
        'nativeName': 'hrvatski jezik',
      },
      {
        'iso639_1': 'sr',
        'iso639_2': 'srp',
        'name': 'Serbian',
        'nativeName': 'српски језик',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Sarajevo',
    'latlng': [
      44,
      18,
    ],
    'demonym': 'Bosnian, Herzegovinian',
  },
  'BW': {
    'population': 2141206,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'BWP',
        'name': 'Botswana pula',
        'symbol': 'P',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'tn',
        'iso639_2': 'tsn',
        'name': 'Tswana',
        'nativeName': 'Setswana',
      },
    ],
    'subregion': 'Southern Africa',
    'capital': 'Gaborone',
    'latlng': [
      -22,
      24,
    ],
    'demonym': 'Motswana',
  },
  'BV': {
    'population': 0,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'NOK',
        'name': 'Norwegian krone',
        'symbol': 'kr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'no',
        'iso639_2': 'nor',
        'name': 'Norwegian',
        'nativeName': 'Norsk',
      },
      {
        'iso639_1': 'nb',
        'iso639_2': 'nob',
        'name': 'Norwegian Bokmål',
        'nativeName': 'Norsk bokmål',
      },
      {
        'iso639_1': 'nn',
        'iso639_2': 'nno',
        'name': 'Norwegian Nynorsk',
        'nativeName': 'Norsk nynorsk',
      },
    ],
    'subregion': 'South Antarctic Ocean',
    'latlng': [
      -54.43333333,
      3.4,
    ],
  },
  'BR': {
    'population': 206135893,
    'timezones': [
      'UTC-05:00',
      'UTC-04:00',
      'UTC-03:00',
      'UTC-02:00',
    ],
    'currencies': [
      {
        'code': 'BRL',
        'name': 'Brazilian real',
        'symbol': 'R$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'pt',
        'iso639_2': 'por',
        'name': 'Portuguese',
        'nativeName': 'Português',
      },
    ],
    'subregion': 'South America',
    'capital': 'Brasília',
    'latlng': [
      -10,
      -55,
    ],
    'demonym': 'Brazilian',
  },
  'IO': {
    'population': 3000,
    'timezones': [
      'UTC+06:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Diego Garcia',
    'latlng': [
      -6,
      71.5,
    ],
    'demonym': 'Indian',
  },
  'UM': {
    'population': 300,
    'timezones': [
      'UTC-11:00',
      'UTC-10:00',
      'UTC+12:00',
    ],
    'currencies': [
      {
        'code': 'GBP',
        'name': 'British pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Northern America',
    'demonym': 'American',
  },
  'VG': {
    'population': 28514,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Road Town',
    'latlng': [
      18.431383,
      -64.62305,
    ],
    'demonym': 'Virgin Islander',
  },
  'VI': {
    'population': 114743,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Charlotte Amalie',
    'latlng': [
      18.34,
      -64.93,
    ],
    'demonym': 'Virgin Islander',
  },
  'BN': {
    'population': 411900,
    'timezones': [
      'UTC+08:00',
    ],
    'currencies': [
      {
        'code': 'BND',
        'name': 'Brunei dollar',
        'symbol': '$',
      },
      {
        'code': 'SGD',
        'name': 'Singapore dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ms',
        'iso639_2': 'msa',
        'name': 'Malay',
        'nativeName': 'bahasa Melayu',
      },
    ],
    'subregion': 'South-Eastern Asia',
    'capital': 'Bandar Seri Begawan',
    'latlng': [
      4.5,
      114.66666666,
    ],
    'demonym': 'Bruneian',
  },
  'BG': {
    'population': 7153784,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'BGN',
        'name': 'Bulgarian lev',
        'symbol': 'лв',
      },
    ],
    'languages': [
      {
        'iso639_1': 'bg',
        'iso639_2': 'bul',
        'name': 'Bulgarian',
        'nativeName': 'български език',
      },
    ],
    'subregion': 'Eastern Europe',
    'capital': 'Sofia',
    'latlng': [
      43,
      25,
    ],
    'demonym': 'Bulgarian',
  },
  'BF': {
    'population': 19034397,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'XOF',
        'name': 'West African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'ff',
        'iso639_2': 'ful',
        'name': 'Fula',
        'nativeName': 'Fulfulde',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Ouagadougou',
    'latlng': [
      13,
      -2,
    ],
    'demonym': 'Burkinabe',
  },
  'BI': {
    'population': 10114505,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'BIF',
        'name': 'Burundian franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'rn',
        'iso639_2': 'run',
        'name': 'Kirundi',
        'nativeName': 'Ikirundi',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Bujumbura',
    'latlng': [
      -3.5,
      30,
    ],
    'demonym': 'Burundian',
  },
  'KH': {
    'population': 15626444,
    'timezones': [
      'UTC+07:00',
    ],
    'currencies': [
      {
        'code': 'KHR',
        'name': 'Cambodian riel',
        'symbol': '៛',
      },
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'km',
        'iso639_2': 'khm',
        'name': 'Khmer',
        'nativeName': 'ខ្មែរ',
      },
    ],
    'subregion': 'South-Eastern Asia',
    'capital': 'Phnom Penh',
    'latlng': [
      13,
      105,
    ],
    'demonym': 'Cambodian',
  },
  'CM': {
    'population': 22709892,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'XAF',
        'name': 'Central African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Middle Africa',
    'capital': 'Yaoundé',
    'latlng': [
      6,
      12,
    ],
    'demonym': 'Cameroonian',
  },
  'CA': {
    'population': 36155487,
    'timezones': [
      'UTC-08:00',
      'UTC-07:00',
      'UTC-06:00',
      'UTC-05:00',
      'UTC-04:00',
      'UTC-03:30',
    ],
    'currencies': [
      {
        'code': 'CAD',
        'name': 'Canadian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Northern America',
    'capital': 'Ottawa',
    'latlng': [
      60,
      -95,
    ],
    'demonym': 'Canadian',
  },
  'CV': {
    'population': 531239,
    'timezones': [
      'UTC-01:00',
    ],
    'currencies': [
      {
        'code': 'CVE',
        'name': 'Cape Verdean escudo',
        'symbol': 'Esc',
      },
    ],
    'languages': [
      {
        'iso639_1': 'pt',
        'iso639_2': 'por',
        'name': 'Portuguese',
        'nativeName': 'Português',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Praia',
    'latlng': [
      16,
      -24,
    ],
    'demonym': 'Cape Verdian',
  },
  'KY': {
    'population': 58238,
    'timezones': [
      'UTC-05:00',
    ],
    'currencies': [
      {
        'code': 'KYD',
        'name': 'Cayman Islands dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'George Town',
    'latlng': [
      19.5,
      -80.5,
    ],
    'demonym': 'Caymanian',
  },
  'CF': {
    'population': 4998000,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'XAF',
        'name': 'Central African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'sg',
        'iso639_2': 'sag',
        'name': 'Sango',
        'nativeName': 'yângâ tî sängö',
      },
    ],
    'subregion': 'Middle Africa',
    'capital': 'Bangui',
    'latlng': [
      7,
      21,
    ],
    'demonym': 'Central African',
  },
  'TD': {
    'population': 14497000,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'XAF',
        'name': 'Central African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Middle Africa',
    'capital': "N'Djamena",
    'latlng': [
      15,
      19,
    ],
    'demonym': 'Chadian',
  },
  'CL': {
    'population': 18191900,
    'timezones': [
      'UTC-06:00',
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'CLP',
        'name': 'Chilean peso',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'South America',
    'capital': 'Santiago',
    'latlng': [
      -30,
      -71,
    ],
    'demonym': 'Chilean',
  },
  'CN': {
    'population': 1377422166,
    'timezones': [
      'UTC+08:00',
    ],
    'currencies': [
      {
        'code': 'CNY',
        'name': 'Chinese yuan',
        'symbol': '¥',
      },
    ],
    'languages': [
      {
        'iso639_1': 'zh',
        'iso639_2': 'zho',
        'name': 'Chinese',
        'nativeName': '中文 (Zhōngwén)',
      },
    ],
    'subregion': 'Eastern Asia',
    'capital': 'Beijing',
    'latlng': [
      35,
      105,
    ],
    'demonym': 'Chinese',
  },
  'CX': {
    'population': 2072,
    'timezones': [
      'UTC+07:00',
    ],
    'currencies': [
      {
        'code': 'AUD',
        'name': 'Australian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Australia and New Zealand',
    'capital': 'Flying Fish Cove',
    'latlng': [
      -10.5,
      105.66666666,
    ],
    'demonym': 'Christmas Island',
  },
  'CC': {
    'population': 550,
    'timezones': [
      'UTC+06:30',
    ],
    'currencies': [
      {
        'code': 'AUD',
        'name': 'Australian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Australia and New Zealand',
    'capital': 'West Island',
    'latlng': [
      -12.5,
      96.83333333,
    ],
    'demonym': 'Cocos Islander',
  },
  'CO': {
    'population': 48759958,
    'timezones': [
      'UTC-05:00',
    ],
    'currencies': [
      {
        'code': 'COP',
        'name': 'Colombian peso',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'South America',
    'capital': 'Bogotá',
    'latlng': [
      4,
      -72,
    ],
    'demonym': 'Colombian',
  },
  'KM': {
    'population': 806153,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'KMF',
        'name': 'Comorian franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Moroni',
    'latlng': [
      -12.16666666,
      44.25,
    ],
    'demonym': 'Comoran',
  },
  'CG': {
    'population': 4741000,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'XAF',
        'name': 'Central African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'ln',
        'iso639_2': 'lin',
        'name': 'Lingala',
        'nativeName': 'Lingála',
      },
    ],
    'subregion': 'Middle Africa',
    'capital': 'Brazzaville',
    'latlng': [
      -1,
      15,
    ],
    'demonym': 'Congolese',
  },
  'CD': {
    'population': 85026000,
    'timezones': [
      'UTC+01:00',
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'CDF',
        'name': 'Congolese franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'ln',
        'iso639_2': 'lin',
        'name': 'Lingala',
        'nativeName': 'Lingála',
      },
      {
        'iso639_1': 'kg',
        'iso639_2': 'kon',
        'name': 'Kongo',
        'nativeName': 'Kikongo',
      },
      {
        'iso639_1': 'sw',
        'iso639_2': 'swa',
        'name': 'Swahili',
        'nativeName': 'Kiswahili',
      },
      {
        'iso639_1': 'lu',
        'iso639_2': 'lub',
        'name': 'Luba-Katanga',
        'nativeName': 'Tshiluba',
      },
    ],
    'subregion': 'Middle Africa',
    'capital': 'Kinshasa',
    'latlng': [
      0,
      25,
    ],
    'demonym': 'Congolese',
  },
  'CK': {
    'population': 18100,
    'timezones': [
      'UTC-10:00',
    ],
    'currencies': [
      {
        'code': 'NZD',
        'name': 'New Zealand dollar',
        'symbol': '$',
      },
      {
        'code': 'CKD',
        'name': 'Cook Islands dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_2': 'rar',
        'name': 'Cook Islands Māori',
        'nativeName': 'Māori',
      },
    ],
    'subregion': 'Polynesia',
    'capital': 'Avarua',
    'latlng': [
      -21.23333333,
      -159.76666666,
    ],
    'demonym': 'Cook Islander',
  },
  'CR': {
    'population': 4890379,
    'timezones': [
      'UTC-06:00',
    ],
    'currencies': [
      {
        'code': 'CRC',
        'name': 'Costa Rican colón',
        'symbol': '₡',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Central America',
    'capital': 'San José',
    'latlng': [
      10,
      -84,
    ],
    'demonym': 'Costa Rican',
  },
  'HR': {
    'population': 4190669,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'HRK',
        'name': 'Croatian kuna',
        'symbol': 'kn',
      },
    ],
    'languages': [
      {
        'iso639_1': 'hr',
        'iso639_2': 'hrv',
        'name': 'Croatian',
        'nativeName': 'hrvatski jezik',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Zagreb',
    'latlng': [
      45.16666666,
      15.5,
    ],
    'demonym': 'Croatian',
  },
  'CU': {
    'population': 11239004,
    'timezones': [
      'UTC-05:00',
    ],
    'currencies': [
      {
        'code': 'CUC',
        'name': 'Cuban convertible peso',
        'symbol': '$',
      },
      {
        'code': 'CUP',
        'name': 'Cuban peso',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Havana',
    'latlng': [
      21.5,
      -80,
    ],
    'demonym': 'Cuban',
  },
  'CW': {
    'population': 154843,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'ANG',
        'name': 'Netherlands Antillean guilder',
        'symbol': 'ƒ',
      },
    ],
    'languages': [
      {
        'iso639_1': 'nl',
        'iso639_2': 'nld',
        'name': 'Dutch',
        'nativeName': 'Nederlands',
      },
      {
        'iso639_1': 'pa',
        'iso639_2': 'pan',
        'name': '(Eastern) Punjabi',
        'nativeName': 'ਪੰਜਾਬੀ',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Willemstad',
    'latlng': [
      12.116667,
      -68.933333,
    ],
    'demonym': 'Dutch',
  },
  'CY': {
    'population': 847000,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'el',
        'iso639_2': 'ell',
        'name': 'Greek (modern)',
        'nativeName': 'ελληνικά',
      },
      {
        'iso639_1': 'tr',
        'iso639_2': 'tur',
        'name': 'Turkish',
        'nativeName': 'Türkçe',
      },
      {
        'iso639_1': 'hy',
        'iso639_2': 'hye',
        'name': 'Armenian',
        'nativeName': 'Հայերեն',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Nicosia',
    'latlng': [
      35,
      33,
    ],
    'demonym': 'Cypriot',
  },
  'CZ': {
    'population': 10558524,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'CZK',
        'name': 'Czech koruna',
        'symbol': 'Kč',
      },
    ],
    'languages': [
      {
        'iso639_1': 'cs',
        'iso639_2': 'ces',
        'name': 'Czech',
        'nativeName': 'čeština',
      },
      {
        'iso639_1': 'sk',
        'iso639_2': 'slk',
        'name': 'Slovak',
        'nativeName': 'slovenčina',
      },
    ],
    'subregion': 'Central Europe',
    'capital': 'Prague',
    'latlng': [
      49.75,
      15.5,
    ],
    'demonym': 'Czech',
  },
  'DK': {
    'population': 5717014,
    'timezones': [
      'UTC-04:00',
      'UTC-03:00',
      'UTC-01:00',
      'UTC',
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'DKK',
        'name': 'Danish krone',
        'symbol': 'kr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'da',
        'iso639_2': 'dan',
        'name': 'Danish',
        'nativeName': 'dansk',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Copenhagen',
    'latlng': [
      56,
      10,
    ],
    'demonym': 'Danish',
  },
  'DJ': {
    'population': 900000,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'DJF',
        'name': 'Djiboutian franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Djibouti',
    'latlng': [
      11.5,
      43,
    ],
    'demonym': 'Djibouti',
  },
  'DM': {
    'population': 71293,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'XCD',
        'name': 'East Caribbean dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Roseau',
    'latlng': [
      15.41666666,
      -61.33333333,
    ],
    'demonym': 'Dominican',
  },
  'DO': {
    'population': 10075045,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'DOP',
        'name': 'Dominican peso',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Santo Domingo',
    'latlng': [
      19,
      -70.66666666,
    ],
    'demonym': 'Dominican',
  },
  'EC': {
    'population': 16545799,
    'timezones': [
      'UTC-06:00',
      'UTC-05:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'South America',
    'capital': 'Quito',
    'latlng': [
      -2,
      -77.5,
    ],
    'demonym': 'Ecuadorean',
  },
  'EG': {
    'population': 91290000,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'EGP',
        'name': 'Egyptian pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Northern Africa',
    'capital': 'Cairo',
    'latlng': [
      27,
      30,
    ],
    'demonym': 'Egyptian',
  },
  'SV': {
    'population': 6520675,
    'timezones': [
      'UTC-06:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Central America',
    'capital': 'San Salvador',
    'latlng': [
      13.83333333,
      -88.91666666,
    ],
    'demonym': 'Salvadoran',
  },
  'GQ': {
    'population': 1222442,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'XAF',
        'name': 'Central African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'pt',
        'iso639_2': 'por',
        'name': 'Portuguese',
        'nativeName': 'Português',
      },
      {
        'iso639_2': 'fan',
        'name': 'Fang',
        'nativeName': 'Fang',
      },
    ],
    'subregion': 'Middle Africa',
    'capital': 'Malabo',
    'latlng': [
      2,
      10,
    ],
    'demonym': 'Equatorial Guinean',
  },
  'ER': {
    'population': 5352000,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'ERN',
        'name': 'Eritrean nakfa',
        'symbol': 'Nfk',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ti',
        'iso639_2': 'tir',
        'name': 'Tigrinya',
        'nativeName': 'ትግርኛ',
      },
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_2': 'tig',
        'name': 'Tigre',
        'nativeName': 'ትግረ',
      },
      {
        'iso639_2': 'kun',
        'name': 'Kunama',
        'nativeName': 'Kunama',
      },
      {
        'iso639_2': 'ssy',
        'name': 'Saho',
        'nativeName': 'Saho',
      },
      {
        'iso639_2': 'byn',
        'name': 'Bilen',
        'nativeName': 'ብሊና',
      },
      {
        'iso639_2': 'nrb',
        'name': 'Nara',
        'nativeName': 'Nara',
      },
      {
        'iso639_1': 'aa',
        'iso639_2': 'aar',
        'name': 'Afar',
        'nativeName': 'Afar',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Asmara',
    'latlng': [
      15,
      39,
    ],
    'demonym': 'Eritrean',
  },
  'EE': {
    'population': 1315944,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'et',
        'iso639_2': 'est',
        'name': 'Estonian',
        'nativeName': 'eesti',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Tallinn',
    'latlng': [
      59,
      26,
    ],
    'demonym': 'Estonian',
  },
  'ET': {
    'population': 92206005,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'ETB',
        'name': 'Ethiopian birr',
        'symbol': 'Br',
      },
    ],
    'languages': [
      {
        'iso639_1': 'am',
        'iso639_2': 'amh',
        'name': 'Amharic',
        'nativeName': 'አማርኛ',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Addis Ababa',
    'latlng': [
      8,
      38,
    ],
    'demonym': 'Ethiopian',
  },
  'FK': {
    'population': 2563,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'FKP',
        'name': 'Falkland Islands pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'South America',
    'capital': 'Stanley',
    'latlng': [
      -51.75,
      -59,
    ],
    'demonym': 'Falkland Islander',
  },
  'FO': {
    'population': 49376,
    'timezones': [
      'UTC+00:00',
    ],
    'currencies': [
      {
        'code': 'DKK',
        'name': 'Danish krone',
        'symbol': 'kr',
      },
      {
        'code': '(none)',
        'name': 'Faroese króna',
        'symbol': 'kr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fo',
        'iso639_2': 'fao',
        'name': 'Faroese',
        'nativeName': 'føroyskt',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Tórshavn',
    'latlng': [
      62,
      -7,
    ],
    'demonym': 'Faroese',
  },
  'FJ': {
    'population': 867000,
    'timezones': [
      'UTC+12:00',
    ],
    'currencies': [
      {
        'code': 'FJD',
        'name': 'Fijian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'fj',
        'iso639_2': 'fij',
        'name': 'Fijian',
        'nativeName': 'vosa Vakaviti',
      },
      {
        'iso639_2': 'hif',
        'name': 'Fiji Hindi',
        'nativeName': 'फ़िजी बात',
      },
      {
        'iso639_2': 'rtm',
        'name': 'Rotuman',
        'nativeName': 'Fäeag Rotuma',
      },
    ],
    'subregion': 'Melanesia',
    'capital': 'Suva',
    'latlng': [
      -18,
      175,
    ],
    'demonym': 'Fijian',
  },
  'FI': {
    'population': 5491817,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fi',
        'iso639_2': 'fin',
        'name': 'Finnish',
        'nativeName': 'suomi',
      },
      {
        'iso639_1': 'sv',
        'iso639_2': 'swe',
        'name': 'Swedish',
        'nativeName': 'svenska',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Helsinki',
    'latlng': [
      64,
      26,
    ],
    'demonym': 'Finnish',
  },
  'FR': {
    'population': 66710000,
    'timezones': [
      'UTC-10:00',
      'UTC-09:30',
      'UTC-09:00',
      'UTC-08:00',
      'UTC-04:00',
      'UTC-03:00',
      'UTC+01:00',
      'UTC+02:00',
      'UTC+03:00',
      'UTC+04:00',
      'UTC+05:00',
      'UTC+10:00',
      'UTC+11:00',
      'UTC+12:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Western Europe',
    'capital': 'Paris',
    'latlng': [
      46,
      2,
    ],
    'demonym': 'French',
  },
  'GF': {
    'population': 254541,
    'timezones': [
      'UTC-03:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'South America',
    'capital': 'Cayenne',
    'latlng': [
      4,
      -53,
    ],
  },
  'PF': {
    'population': 271800,
    'timezones': [
      'UTC-10:00',
      'UTC-09:30',
      'UTC-09:00',
    ],
    'currencies': [
      {
        'code': 'XPF',
        'name': 'CFP franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Polynesia',
    'capital': 'Papeetē',
    'latlng': [
      -15,
      -140,
    ],
    'demonym': 'French Polynesian',
  },
  'TF': {
    'population': 140,
    'timezones': [
      'UTC+05:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Southern Africa',
    'capital': 'Port-aux-Français',
    'latlng': [
      -49.25,
      69.167,
    ],
    'demonym': 'French',
  },
  'GA': {
    'population': 1802278,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'XAF',
        'name': 'Central African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Middle Africa',
    'capital': 'Libreville',
    'latlng': [
      -1,
      11.75,
    ],
    'demonym': 'Gabonese',
  },
  'GM': {
    'population': 1882450,
    'timezones': [
      'UTC+00:00',
    ],
    'currencies': [
      {
        'code': 'GMD',
        'name': 'Gambian dalasi',
        'symbol': 'D',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Banjul',
    'latlng': [
      13.46666666,
      -16.56666666,
    ],
    'demonym': 'Gambian',
  },
  'GE': {
    'population': 3720400,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'GEL',
        'name': 'Georgian Lari',
        'symbol': 'ლ',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ka',
        'iso639_2': 'kat',
        'name': 'Georgian',
        'nativeName': 'ქართული',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Tbilisi',
    'latlng': [
      42,
      43.5,
    ],
    'demonym': 'Georgian',
  },
  'DE': {
    'population': 81770900,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'de',
        'iso639_2': 'deu',
        'name': 'German',
        'nativeName': 'Deutsch',
      },
    ],
    'subregion': 'Central Europe',
    'capital': 'Berlin',
    'latlng': [
      51,
      9,
    ],
    'demonym': 'German',
  },
  'GH': {
    'population': 27670174,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'GHS',
        'name': 'Ghanaian cedi',
        'symbol': '₵',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Accra',
    'latlng': [
      8,
      -2,
    ],
    'demonym': 'Ghanaian',
  },
  'GI': {
    'population': 33140,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'GIP',
        'name': 'Gibraltar pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Gibraltar',
    'latlng': [
      36.13333333,
      -5.35,
    ],
    'demonym': 'Gibraltar',
  },
  'GR': {
    'population': 10858018,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'el',
        'iso639_2': 'ell',
        'name': 'Greek (modern)',
        'nativeName': 'ελληνικά',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Athens',
    'latlng': [
      39,
      22,
    ],
    'demonym': 'Greek',
  },
  'GL': {
    'population': 55847,
    'timezones': [
      'UTC-04:00',
      'UTC-03:00',
      'UTC-01:00',
      'UTC+00:00',
    ],
    'currencies': [
      {
        'code': 'DKK',
        'name': 'Danish krone',
        'symbol': 'kr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'kl',
        'iso639_2': 'kal',
        'name': 'Kalaallisut',
        'nativeName': 'kalaallisut',
      },
    ],
    'subregion': 'Northern America',
    'capital': 'Nuuk',
    'latlng': [
      72,
      -40,
    ],
    'demonym': 'Greenlandic',
  },
  'GD': {
    'population': 103328,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'XCD',
        'name': 'East Caribbean dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': "St. George's",
    'latlng': [
      12.11666666,
      -61.66666666,
    ],
    'demonym': 'Grenadian',
  },
  'GP': {
    'population': 400132,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Basse-Terre',
    'latlng': [
      16.25,
      -61.583333,
    ],
    'demonym': 'Guadeloupian',
  },
  'GU': {
    'population': 184200,
    'timezones': [
      'UTC+10:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'ch',
        'iso639_2': 'cha',
        'name': 'Chamorro',
        'nativeName': 'Chamoru',
      },
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Micronesia',
    'capital': 'Hagåtña',
    'latlng': [
      13.46666666,
      144.78333333,
    ],
    'demonym': 'Guamanian',
  },
  'GT': {
    'population': 16176133,
    'timezones': [
      'UTC-06:00',
    ],
    'currencies': [
      {
        'code': 'GTQ',
        'name': 'Guatemalan quetzal',
        'symbol': 'Q',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Central America',
    'capital': 'Guatemala City',
    'latlng': [
      15.5,
      -90.25,
    ],
    'demonym': 'Guatemalan',
  },
  'GG': {
    'population': 62999,
    'timezones': [
      'UTC+00:00',
    ],
    'currencies': [
      {
        'code': 'GBP',
        'name': 'British pound',
        'symbol': '£',
      },
      {
        'code': '(none)',
        'name': 'Guernsey pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'St. Peter Port',
    'latlng': [
      49.46666666,
      -2.58333333,
    ],
    'demonym': 'Channel Islander',
  },
  'GN': {
    'population': 12947000,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'GNF',
        'name': 'Guinean franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'ff',
        'iso639_2': 'ful',
        'name': 'Fula',
        'nativeName': 'Fulfulde',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Conakry',
    'latlng': [
      11,
      -10,
    ],
    'demonym': 'Guinean',
  },
  'GW': {
    'population': 1547777,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'XOF',
        'name': 'West African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'pt',
        'iso639_2': 'por',
        'name': 'Portuguese',
        'nativeName': 'Português',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Bissau',
    'latlng': [
      12,
      -15,
    ],
    'demonym': 'Guinea-Bissauan',
  },
  'GY': {
    'population': 746900,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'GYD',
        'name': 'Guyanese dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'South America',
    'capital': 'Georgetown',
    'latlng': [
      5,
      -59,
    ],
    'demonym': 'Guyanese',
  },
  'HT': {
    'population': 11078033,
    'timezones': [
      'UTC-05:00',
    ],
    'currencies': [
      {
        'code': 'HTG',
        'name': 'Haitian gourde',
        'symbol': 'G',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'ht',
        'iso639_2': 'hat',
        'name': 'Haitian',
        'nativeName': 'Kreyòl ayisyen',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Port-au-Prince',
    'latlng': [
      19,
      -72.41666666,
    ],
    'demonym': 'Haitian',
  },
  'HM': {
    'population': 0,
    'timezones': [
      'UTC+05:00',
    ],
    'currencies': [
      {
        'code': 'AUD',
        'name': 'Australian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Antarctic',
    'latlng': [
      -53.1,
      72.51666666,
    ],
    'demonym': 'Heard and McDonald Islander',
  },
  'VA': {
    'population': 451,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'la',
        'iso639_2': 'lat',
        'name': 'Latin',
        'nativeName': 'latine',
      },
      {
        'iso639_1': 'it',
        'iso639_2': 'ita',
        'name': 'Italian',
        'nativeName': 'Italiano',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'Français',
      },
      {
        'iso639_1': 'de',
        'iso639_2': 'deu',
        'name': 'German',
        'nativeName': 'Deutsch',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Rome',
    'latlng': [
      41.9,
      12.45,
    ],
    'demonym': 'Vatican',
  },
  'HN': {
    'population': 8576532,
    'timezones': [
      'UTC-06:00',
    ],
    'currencies': [
      {
        'code': 'HNL',
        'name': 'Honduran lempira',
        'symbol': 'L',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Central America',
    'capital': 'Tegucigalpa',
    'latlng': [
      15,
      -86.5,
    ],
    'demonym': 'Honduran',
  },
  'HU': {
    'population': 9823000,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'HUF',
        'name': 'Hungarian forint',
        'symbol': 'Ft',
      },
    ],
    'languages': [
      {
        'iso639_1': 'hu',
        'iso639_2': 'hun',
        'name': 'Hungarian',
        'nativeName': 'magyar',
      },
    ],
    'subregion': 'Central Europe',
    'capital': 'Budapest',
    'latlng': [
      47,
      20,
    ],
    'demonym': 'Hungarian',
  },
  'IS': {
    'population': 334300,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'ISK',
        'name': 'Icelandic króna',
        'symbol': 'kr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'is',
        'iso639_2': 'isl',
        'name': 'Icelandic',
        'nativeName': 'Íslenska',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Reykjavík',
    'latlng': [
      65,
      -18,
    ],
    'demonym': 'Icelander',
  },
  'IN': {
    'population': 1378712489,
    'timezones': [
      'UTC+05:30',
    ],
    'currencies': [
      {
        'code': 'INR',
        'name': 'Indian rupee',
        'symbol': '₹',
      },
    ],
    'languages': [
      {
        'iso639_1': 'hi',
        'iso639_2': 'hin',
        'name': 'Hindi',
        'nativeName': 'हिन्दी',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Southern Asia',
    'capital': 'New Delhi',
    'latlng': [
      20,
      77,
    ],
    'demonym': 'Indian',
  },
  'ID': {
    'population': 258705000,
    'timezones': [
      'UTC+07:00',
      'UTC+08:00',
      'UTC+09:00',
    ],
    'currencies': [
      {
        'code': 'IDR',
        'name': 'Indonesian rupiah',
        'symbol': 'Rp',
      },
    ],
    'languages': [
      {
        'iso639_1': 'id',
        'iso639_2': 'ind',
        'name': 'Indonesian',
        'nativeName': 'Bahasa Indonesia',
      },
    ],
    'subregion': 'South-Eastern Asia',
    'capital': 'Jakarta',
    'latlng': [
      -5,
      120,
    ],
    'demonym': 'Indonesian',
  },
  'CI': {
    'population': 22671331,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'XOF',
        'name': 'West African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Yamoussoukro',
    'latlng': [
      8,
      -5,
    ],
    'demonym': 'Ivorian',
  },
  'IR': {
    'population': 79369900,
    'timezones': [
      'UTC+03:30',
    ],
    'currencies': [
      {
        'code': 'IRR',
        'name': 'Iranian rial',
        'symbol': '﷼',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fa',
        'iso639_2': 'fas',
        'name': 'Persian (Farsi)',
        'nativeName': 'فارسی',
      },
    ],
    'subregion': 'Southern Asia',
    'capital': 'Tehran',
    'latlng': [
      32,
      53,
    ],
    'demonym': 'Iranian',
  },
  'IQ': {
    'population': 37883543,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'IQD',
        'name': 'Iraqi dinar',
        'symbol': 'ع.د',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
      {
        'iso639_1': 'ku',
        'iso639_2': 'kur',
        'name': 'Kurdish',
        'nativeName': 'Kurdî',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Baghdad',
    'latlng': [
      33,
      44,
    ],
    'demonym': 'Iraqi',
  },
  'IE': {
    'population': 6378000,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ga',
        'iso639_2': 'gle',
        'name': 'Irish',
        'nativeName': 'Gaeilge',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Dublin',
    'latlng': [
      53,
      -8,
    ],
    'demonym': 'Irish',
  },
  'IM': {
    'population': 84497,
    'timezones': [
      'UTC+00:00',
    ],
    'currencies': [
      {
        'code': 'GBP',
        'name': 'British pound',
        'symbol': '£',
      },
      {
        'code': 'IMP[G]',
        'name': 'Manx pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'gv',
        'iso639_2': 'glv',
        'name': 'Manx',
        'nativeName': 'Gaelg',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Douglas',
    'latlng': [
      54.25,
      -4.5,
    ],
    'demonym': 'Manx',
  },
  'IL': {
    'population': 8527400,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'ILS',
        'name': 'Israeli new shekel',
        'symbol': '₪',
      },
    ],
    'languages': [
      {
        'iso639_1': 'he',
        'iso639_2': 'heb',
        'name': 'Hebrew (modern)',
        'nativeName': 'עברית',
      },
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Jerusalem',
    'latlng': [
      31.5,
      34.75,
    ],
    'demonym': 'Israeli',
  },
  'IT': {
    'population': 60665551,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'it',
        'iso639_2': 'ita',
        'name': 'Italian',
        'nativeName': 'Italiano',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Rome',
    'latlng': [
      42.83333333,
      12.83333333,
    ],
    'demonym': 'Italian',
  },
  'JM': {
    'population': 2723246,
    'timezones': [
      'UTC-05:00',
    ],
    'currencies': [
      {
        'code': 'JMD',
        'name': 'Jamaican dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Kingston',
    'latlng': [
      18.25,
      -77.5,
    ],
    'demonym': 'Jamaican',
  },
  'JP': {
    'population': 126960000,
    'timezones': [
      'UTC+09:00',
    ],
    'currencies': [
      {
        'code': 'JPY',
        'name': 'Japanese yen',
        'symbol': '¥',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ja',
        'iso639_2': 'jpn',
        'name': 'Japanese',
        'nativeName': '日本語 (にほんご)',
      },
    ],
    'subregion': 'Eastern Asia',
    'capital': 'Tokyo',
    'latlng': [
      36,
      138,
    ],
    'demonym': 'Japanese',
  },
  'JE': {
    'population': 100800,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'GBP',
        'name': 'British pound',
        'symbol': '£',
      },
      {
        'code': 'JEP[G]',
        'name': 'Jersey pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Saint Helier',
    'latlng': [
      49.25,
      -2.16666666,
    ],
    'demonym': 'Channel Islander',
  },
  'JO': {
    'population': 9531712,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'JOD',
        'name': 'Jordanian dinar',
        'symbol': 'د.ا',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Amman',
    'latlng': [
      31,
      36,
    ],
    'demonym': 'Jordanian',
  },
  'KZ': {
    'population': 17753200,
    'timezones': [
      'UTC+05:00',
      'UTC+06:00',
    ],
    'currencies': [
      {
        'code': 'KZT',
        'name': 'Kazakhstani tenge',
        'symbol': '₸',
      },
    ],
    'languages': [
      {
        'iso639_1': 'kk',
        'iso639_2': 'kaz',
        'name': 'Kazakh',
        'nativeName': 'қазақ тілі',
      },
      {
        'iso639_1': 'ru',
        'iso639_2': 'rus',
        'name': 'Russian',
        'nativeName': 'Русский',
      },
    ],
    'subregion': 'Central Asia',
    'capital': 'Nursultan',
    'latlng': [
      48,
      68,
    ],
  },
  'KE': {
    'population': 47251000,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'KES',
        'name': 'Kenyan shilling',
        'symbol': 'Sh',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'sw',
        'iso639_2': 'swa',
        'name': 'Swahili',
        'nativeName': 'Kiswahili',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Nairobi',
    'latlng': [
      1,
      38,
    ],
    'demonym': 'Kenyan',
  },
  'KI': {
    'population': 113400,
    'timezones': [
      'UTC+12:00',
      'UTC+13:00',
      'UTC+14:00',
    ],
    'currencies': [
      {
        'code': 'AUD',
        'name': 'Australian dollar',
        'symbol': '$',
      },
      {
        'code': '(none)',
        'name': 'Kiribati dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Micronesia',
    'capital': 'South Tarawa',
    'latlng': [
      1.41666666,
      173,
    ],
    'demonym': 'I-Kiribati',
  },
  'KW': {
    'population': 4183658,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'KWD',
        'name': 'Kuwaiti dinar',
        'symbol': 'د.ك',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Kuwait City',
    'latlng': [
      29.5,
      45.75,
    ],
    'demonym': 'Kuwaiti',
  },
  'KG': {
    'population': 6047800,
    'timezones': [
      'UTC+06:00',
    ],
    'currencies': [
      {
        'code': 'KGS',
        'name': 'Kyrgyzstani som',
        'symbol': 'с',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ky',
        'iso639_2': 'kir',
        'name': 'Kyrgyz',
        'nativeName': 'Кыргызча',
      },
      {
        'iso639_1': 'ru',
        'iso639_2': 'rus',
        'name': 'Russian',
        'nativeName': 'Русский',
      },
    ],
    'subregion': 'Central Asia',
    'capital': 'Bishkek',
    'latlng': [
      41,
      75,
    ],
    'demonym': 'Kirghiz',
  },
  'LA': {
    'population': 6492400,
    'timezones': [
      'UTC+07:00',
    ],
    'currencies': [
      {
        'code': 'LAK',
        'name': 'Lao kip',
        'symbol': '₭',
      },
    ],
    'languages': [
      {
        'iso639_1': 'lo',
        'iso639_2': 'lao',
        'name': 'Lao',
        'nativeName': 'ພາສາລາວ',
      },
    ],
    'subregion': 'South-Eastern Asia',
    'capital': 'Vientiane',
    'latlng': [
      18,
      105,
    ],
    'demonym': 'Laotian',
  },
  'LV': {
    'population': 1961600,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'lv',
        'iso639_2': 'lav',
        'name': 'Latvian',
        'nativeName': 'latviešu valoda',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Riga',
    'latlng': [
      57,
      25,
    ],
    'demonym': 'Latvian',
  },
  'LB': {
    'population': 5988000,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'LBP',
        'name': 'Lebanese pound',
        'symbol': 'ل.ل',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Beirut',
    'latlng': [
      33.83333333,
      35.83333333,
    ],
    'demonym': 'Lebanese',
  },
  'LS': {
    'population': 1894194,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'LSL',
        'name': 'Lesotho loti',
        'symbol': 'L',
      },
      {
        'code': 'ZAR',
        'name': 'South African rand',
        'symbol': 'R',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'st',
        'iso639_2': 'sot',
        'name': 'Southern Sotho',
        'nativeName': 'Sesotho',
      },
    ],
    'subregion': 'Southern Africa',
    'capital': 'Maseru',
    'latlng': [
      -29.5,
      28.5,
    ],
    'demonym': 'Mosotho',
  },
  'LR': {
    'population': 4615000,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'LRD',
        'name': 'Liberian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Monrovia',
    'latlng': [
      6.5,
      -9.5,
    ],
    'demonym': 'Liberian',
  },
  'LY': {
    'population': 6385000,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'LYD',
        'name': 'Libyan dinar',
        'symbol': 'ل.د',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Northern Africa',
    'capital': 'Tripoli',
    'latlng': [
      25,
      17,
    ],
    'demonym': 'Libyan',
  },
  'LI': {
    'population': 37623,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'CHF',
        'name': 'Swiss franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'de',
        'iso639_2': 'deu',
        'name': 'German',
        'nativeName': 'Deutsch',
      },
    ],
    'subregion': 'Central Europe',
    'capital': 'Vaduz',
    'latlng': [
      47.26666666,
      9.53333333,
    ],
    'demonym': 'Liechtensteiner',
  },
  'LT': {
    'population': 2872294,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'lt',
        'iso639_2': 'lit',
        'name': 'Lithuanian',
        'nativeName': 'lietuvių kalba',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Vilnius',
    'latlng': [
      56,
      24,
    ],
    'demonym': 'Lithuanian',
  },
  'LU': {
    'population': 576200,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'de',
        'iso639_2': 'deu',
        'name': 'German',
        'nativeName': 'Deutsch',
      },
      {
        'iso639_1': 'lb',
        'iso639_2': 'ltz',
        'name': 'Luxembourgish',
        'nativeName': 'Lëtzebuergesch',
      },
    ],
    'subregion': 'Western Europe',
    'capital': 'Luxembourg',
    'latlng': [
      49.75,
      6.16666666,
    ],
    'demonym': 'Luxembourger',
  },
  'MO': {
    'population': 649100,
    'timezones': [
      'UTC+08:00',
    ],
    'currencies': [
      {
        'code': 'MOP',
        'name': 'Macanese pataca',
        'symbol': 'P',
      },
    ],
    'languages': [
      {
        'iso639_1': 'zh',
        'iso639_2': 'zho',
        'name': 'Chinese',
        'nativeName': '中文 (Zhōngwén)',
      },
      {
        'iso639_1': 'pt',
        'iso639_2': 'por',
        'name': 'Portuguese',
        'nativeName': 'Português',
      },
    ],
    'subregion': 'Eastern Asia',
    'latlng': [
      22.16666666,
      113.55,
    ],
    'demonym': 'Chinese',
  },
  'MK': {
    'population': 2058539,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'MKD',
        'name': 'Macedonian denar',
        'symbol': 'ден',
      },
    ],
    'languages': [
      {
        'iso639_1': 'mk',
        'iso639_2': 'mkd',
        'name': 'Macedonian',
        'nativeName': 'македонски јазик',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Skopje',
    'latlng': [
      41.83333333,
      22,
    ],
    'demonym': 'Macedonian',
  },
  'MG': {
    'population': 22434363,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'MGA',
        'name': 'Malagasy ariary',
        'symbol': 'Ar',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'mg',
        'iso639_2': 'mlg',
        'name': 'Malagasy',
        'nativeName': 'fiteny malagasy',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Antananarivo',
    'latlng': [
      -20,
      47,
    ],
    'demonym': 'Malagasy',
  },
  'MW': {
    'population': 16832910,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'MWK',
        'name': 'Malawian kwacha',
        'symbol': 'MK',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'ny',
        'iso639_2': 'nya',
        'name': 'Chichewa',
        'nativeName': 'chiCheŵa',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Lilongwe',
    'latlng': [
      -13.5,
      34,
    ],
    'demonym': 'Malawian',
  },
  'MY': {
    'population': 31405416,
    'timezones': [
      'UTC+08:00',
    ],
    'currencies': [
      {
        'code': 'MYR',
        'name': 'Malaysian ringgit',
        'symbol': 'RM',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ms',
        'iso639_2': 'zsm',
        'name': 'Malaysian',
        'nativeName': 'بهاس مليسيا',
      },
    ],
    'subregion': 'South-Eastern Asia',
    'capital': 'Kuala Lumpur',
    'latlng': [
      2.5,
      112.5,
    ],
    'demonym': 'Malaysian',
  },
  'MV': {
    'population': 344023,
    'timezones': [
      'UTC+05:00',
    ],
    'currencies': [
      {
        'code': 'MVR',
        'name': 'Maldivian rufiyaa',
        'symbol': '.ރ',
      },
    ],
    'languages': [
      {
        'iso639_1': 'dv',
        'iso639_2': 'div',
        'name': 'Divehi',
        'nativeName': 'ދިވެހި',
      },
    ],
    'subregion': 'Southern Asia',
    'capital': 'Malé',
    'latlng': [
      3.25,
      73,
    ],
    'demonym': 'Maldivan',
  },
  'ML': {
    'population': 18135000,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'XOF',
        'name': 'West African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Bamako',
    'latlng': [
      17,
      -4,
    ],
    'demonym': 'Malian',
  },
  'MT': {
    'population': 425384,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'mt',
        'iso639_2': 'mlt',
        'name': 'Maltese',
        'nativeName': 'Malti',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Valletta',
    'latlng': [
      35.83333333,
      14.58333333,
    ],
    'demonym': 'Maltese',
  },
  'MH': {
    'population': 54880,
    'timezones': [
      'UTC+12:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'mh',
        'iso639_2': 'mah',
        'name': 'Marshallese',
        'nativeName': 'Kajin M̧ajeļ',
      },
    ],
    'subregion': 'Micronesia',
    'capital': 'Majuro',
    'latlng': [
      9,
      168,
    ],
    'demonym': 'Marshallese',
  },
  'MQ': {
    'population': 378243,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Fort-de-France',
    'latlng': [
      14.666667,
      -61,
    ],
    'demonym': 'French',
  },
  'MR': {
    'population': 3718678,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'MRO',
        'name': 'Mauritanian ouguiya',
        'symbol': 'UM',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Nouakchott',
    'latlng': [
      20,
      -12,
    ],
    'demonym': 'Mauritanian',
  },
  'MU': {
    'population': 1262879,
    'timezones': [
      'UTC+04:00',
    ],
    'currencies': [
      {
        'code': 'MUR',
        'name': 'Mauritian rupee',
        'symbol': '₨',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Port Louis',
    'latlng': [
      -20.28333333,
      57.55,
    ],
    'demonym': 'Mauritian',
  },
  'YT': {
    'population': 226915,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Mamoudzou',
    'latlng': [
      -12.83333333,
      45.16666666,
    ],
    'demonym': 'French',
  },
  'MX': {
    'population': 122273473,
    'timezones': [
      'UTC-08:00',
      'UTC-07:00',
      'UTC-06:00',
    ],
    'currencies': [
      {
        'code': 'MXN',
        'name': 'Mexican peso',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'North America',
    'capital': 'Mexico City',
    'latlng': [
      23,
      -102,
    ],
    'demonym': 'Mexican',
  },
  'FM': {
    'population': 102800,
    'timezones': [
      'UTC+10:00',
      'UTC+11',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Micronesia',
    'capital': 'Palikir',
    'latlng': [
      6.91666666,
      158.25,
    ],
    'demonym': 'Micronesian',
  },
  'MD': {
    'population': 3553100,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'MDL',
        'name': 'Moldovan leu',
        'symbol': 'L',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ro',
        'iso639_2': 'ron',
        'name': 'Romanian',
        'nativeName': 'Română',
      },
    ],
    'subregion': 'Eastern Europe',
    'capital': 'Chișinău',
    'latlng': [
      47,
      29,
    ],
    'demonym': 'Moldovan',
  },
  'MC': {
    'population': 38400,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Western Europe',
    'capital': 'Monaco',
    'latlng': [
      43.73333333,
      7.4,
    ],
    'demonym': 'Monegasque',
  },
  'MN': {
    'population': 3093100,
    'timezones': [
      'UTC+07:00',
      'UTC+08:00',
    ],
    'currencies': [
      {
        'code': 'MNT',
        'name': 'Mongolian tögrög',
        'symbol': '₮',
      },
    ],
    'languages': [
      {
        'iso639_1': 'mn',
        'iso639_2': 'mon',
        'name': 'Mongolian',
        'nativeName': 'Монгол хэл',
      },
    ],
    'subregion': 'Eastern Asia',
    'capital': 'Ulan Bator',
    'latlng': [
      46,
      105,
    ],
    'demonym': 'Mongolian',
  },
  'ME': {
    'population': 621810,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'sr',
        'iso639_2': 'srp',
        'name': 'Serbian',
        'nativeName': 'српски језик',
      },
      {
        'iso639_1': 'bs',
        'iso639_2': 'bos',
        'name': 'Bosnian',
        'nativeName': 'bosanski jezik',
      },
      {
        'iso639_1': 'sq',
        'iso639_2': 'sqi',
        'name': 'Albanian',
        'nativeName': 'Shqip',
      },
      {
        'iso639_1': 'hr',
        'iso639_2': 'hrv',
        'name': 'Croatian',
        'nativeName': 'hrvatski jezik',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Podgorica',
    'latlng': [
      42.5,
      19.3,
    ],
    'demonym': 'Montenegrin',
  },
  'MS': {
    'population': 4922,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'XCD',
        'name': 'East Caribbean dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Plymouth',
    'latlng': [
      16.75,
      -62.2,
    ],
    'demonym': 'Montserratian',
  },
  'MA': {
    'population': 33337529,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'MAD',
        'name': 'Moroccan dirham',
        'symbol': 'د.م.',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Northern Africa',
    'capital': 'Rabat',
    'latlng': [
      32,
      -5,
    ],
    'demonym': 'Moroccan',
  },
  'MZ': {
    'population': 26423700,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'MZN',
        'name': 'Mozambican metical',
        'symbol': 'MT',
      },
    ],
    'languages': [
      {
        'iso639_1': 'pt',
        'iso639_2': 'por',
        'name': 'Portuguese',
        'nativeName': 'Português',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Maputo',
    'latlng': [
      -18.25,
      35,
    ],
    'demonym': 'Mozambican',
  },
  'MM': {
    'population': 51419420,
    'timezones': [
      'UTC+06:30',
    ],
    'currencies': [
      {
        'code': 'MMK',
        'name': 'Burmese kyat',
        'symbol': 'Ks',
      },
    ],
    'languages': [
      {
        'iso639_1': 'my',
        'iso639_2': 'mya',
        'name': 'Burmese',
        'nativeName': 'ဗမာစာ',
      },
    ],
    'subregion': 'South-Eastern Asia',
    'capital': 'Naypyidaw',
    'latlng': [
      22,
      98,
    ],
    'demonym': 'Burmese',
  },
  'NA': {
    'population': 2324388,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'NAD',
        'name': 'Namibian dollar',
        'symbol': '$',
      },
      {
        'code': 'ZAR',
        'name': 'South African rand',
        'symbol': 'R',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'af',
        'iso639_2': 'afr',
        'name': 'Afrikaans',
        'nativeName': 'Afrikaans',
      },
    ],
    'subregion': 'Southern Africa',
    'capital': 'Windhoek',
    'latlng': [
      -22,
      17,
    ],
    'demonym': 'Namibian',
  },
  'NR': {
    'population': 10084,
    'timezones': [
      'UTC+12:00',
    ],
    'currencies': [
      {
        'code': 'AUD',
        'name': 'Australian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'na',
        'iso639_2': 'nau',
        'name': 'Nauruan',
        'nativeName': 'Dorerin Naoero',
      },
    ],
    'subregion': 'Micronesia',
    'capital': 'Yaren',
    'latlng': [
      -0.53333333,
      166.91666666,
    ],
    'demonym': 'Nauruan',
  },
  'NP': {
    'population': 28431500,
    'timezones': [
      'UTC+05:45',
    ],
    'currencies': [
      {
        'code': 'NPR',
        'name': 'Nepalese rupee',
        'symbol': '₨',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ne',
        'iso639_2': 'nep',
        'name': 'Nepali',
        'nativeName': 'नेपाली',
      },
    ],
    'subregion': 'Southern Asia',
    'capital': 'Kathmandu',
    'latlng': [
      28,
      84,
    ],
    'demonym': 'Nepalese',
  },
  'NL': {
    'population': 17019800,
    'timezones': [
      'UTC-04:00',
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'nl',
        'iso639_2': 'nld',
        'name': 'Dutch',
        'nativeName': 'Nederlands',
      },
    ],
    'subregion': 'Western Europe',
    'capital': 'Amsterdam',
    'latlng': [
      52.5,
      5.75,
    ],
    'demonym': 'Dutch',
  },
  'NC': {
    'population': 268767,
    'timezones': [
      'UTC+11:00',
    ],
    'currencies': [
      {
        'code': 'XPF',
        'name': 'CFP franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Melanesia',
    'capital': 'Nouméa',
    'latlng': [
      -21.5,
      165.5,
    ],
    'demonym': 'New Caledonian',
  },
  'NZ': {
    'population': 4697854,
    'timezones': [
      'UTC-11:00',
      'UTC-10:00',
      'UTC+12:00',
      'UTC+12:45',
      'UTC+13:00',
    ],
    'currencies': [
      {
        'code': 'NZD',
        'name': 'New Zealand dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'mi',
        'iso639_2': 'mri',
        'name': 'Māori',
        'nativeName': 'te reo Māori',
      },
    ],
    'subregion': 'Australia and New Zealand',
    'capital': 'Wellington',
    'latlng': [
      -41,
      174,
    ],
    'demonym': 'New Zealander',
  },
  'NI': {
    'population': 6262703,
    'timezones': [
      'UTC-06:00',
    ],
    'currencies': [
      {
        'code': 'NIO',
        'name': 'Nicaraguan córdoba',
        'symbol': 'C$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Central America',
    'capital': 'Managua',
    'latlng': [
      13,
      -85,
    ],
    'demonym': 'Nicaraguan',
  },
  'NE': {
    'population': 20715000,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'XOF',
        'name': 'West African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Niamey',
    'latlng': [
      16,
      8,
    ],
    'demonym': 'Nigerien',
  },
  'NG': {
    'population': 186988000,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'NGN',
        'name': 'Nigerian naira',
        'symbol': '₦',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Abuja',
    'latlng': [
      10,
      8,
    ],
    'demonym': 'Nigerian',
  },
  'NU': {
    'population': 1470,
    'timezones': [
      'UTC-11:00',
    ],
    'currencies': [
      {
        'code': 'NZD',
        'name': 'New Zealand dollar',
        'symbol': '$',
      },
      {
        'code': '(none)',
        'name': 'Niue dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Polynesia',
    'capital': 'Alofi',
    'latlng': [
      -19.03333333,
      -169.86666666,
    ],
    'demonym': 'Niuean',
  },
  'NF': {
    'population': 2302,
    'timezones': [
      'UTC+11:30',
    ],
    'currencies': [
      {
        'code': 'AUD',
        'name': 'Australian dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Australia and New Zealand',
    'capital': 'Kingston',
    'latlng': [
      -29.03333333,
      167.95,
    ],
    'demonym': 'Norfolk Islander',
  },
  'KP': {
    'population': 25281000,
    'timezones': [
      'UTC+09:00',
    ],
    'currencies': [
      {
        'code': 'KPW',
        'name': 'North Korean won',
        'symbol': '₩',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ko',
        'iso639_2': 'kor',
        'name': 'Korean',
        'nativeName': '한국어',
      },
    ],
    'subregion': 'Eastern Asia',
    'capital': 'Pyongyang',
    'latlng': [
      40,
      127,
    ],
    'demonym': 'North Korean',
  },
  'MP': {
    'population': 56940,
    'timezones': [
      'UTC+10:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'ch',
        'iso639_2': 'cha',
        'name': 'Chamorro',
        'nativeName': 'Chamoru',
      },
    ],
    'subregion': 'Micronesia',
    'capital': 'Saipan',
    'latlng': [
      15.2,
      145.75,
    ],
    'demonym': 'American',
  },
  'NO': {
    'population': 5223256,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'NOK',
        'name': 'Norwegian krone',
        'symbol': 'kr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'no',
        'iso639_2': 'nor',
        'name': 'Norwegian',
        'nativeName': 'Norsk',
      },
      {
        'iso639_1': 'nb',
        'iso639_2': 'nob',
        'name': 'Norwegian Bokmål',
        'nativeName': 'Norsk bokmål',
      },
      {
        'iso639_1': 'nn',
        'iso639_2': 'nno',
        'name': 'Norwegian Nynorsk',
        'nativeName': 'Norsk nynorsk',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Oslo',
    'latlng': [
      62,
      10,
    ],
    'demonym': 'Norwegian',
  },
  'OM': {
    'population': 4420133,
    'timezones': [
      'UTC+04:00',
    ],
    'currencies': [
      {
        'code': 'OMR',
        'name': 'Omani rial',
        'symbol': 'ر.ع.',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Muscat',
    'latlng': [
      21,
      57,
    ],
    'demonym': 'Omani',
  },
  'PK': {
    'population': 194125062,
    'timezones': [
      'UTC+05:00',
    ],
    'currencies': [
      {
        'code': 'PKR',
        'name': 'Pakistani rupee',
        'symbol': '₨',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ur',
        'iso639_2': 'urd',
        'name': 'Urdu',
        'nativeName': 'اردو',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Southern Asia',
    'capital': 'Islamabad',
    'latlng': [
      30,
      70,
    ],
    'demonym': 'Pakistani',
  },
  'PW': {
    'population': 17950,
    'timezones': [
      'UTC+09:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Micronesia',
    'capital': 'Ngerulmud',
    'latlng': [
      7.5,
      134.5,
    ],
    'demonym': 'Palauan',
  },
  'PS': {
    'population': 5159076,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'EGP',
        'name': 'Egyptian pound',
        'symbol': 'E£',
      },
      {
        'code': 'ILS',
        'name': 'Israeli new shekel',
        'symbol': '₪',
      },
      {
        'code': 'JOD',
        'name': 'Jordanian dinar',
        'symbol': 'د.أ',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Ramallah',
    'latlng': [
      31.9,
      35.2,
    ],
    'demonym': 'Palestinian',
  },
  'PA': {
    'population': 3814672,
    'timezones': [
      'UTC-05:00',
    ],
    'currencies': [
      {
        'code': 'PAB',
        'name': 'Panamanian balboa',
        'symbol': 'B/.',
      },
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Central America',
    'capital': 'Panama City',
    'latlng': [
      9,
      -80,
    ],
    'demonym': 'Panamanian',
  },
  'PG': {
    'population': 8083700,
    'timezones': [
      'UTC+10:00',
    ],
    'currencies': [
      {
        'code': 'PGK',
        'name': 'Papua New Guinean kina',
        'symbol': 'K',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Melanesia',
    'capital': 'Port Moresby',
    'latlng': [
      -6,
      147,
    ],
    'demonym': 'Papua New Guinean',
  },
  'PY': {
    'population': 6854536,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'PYG',
        'name': 'Paraguayan guaraní',
        'symbol': '₲',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
      {
        'iso639_1': 'gn',
        'iso639_2': 'grn',
        'name': 'Guaraní',
        'nativeName': "Avañe'ẽ",
      },
    ],
    'subregion': 'South America',
    'capital': 'Asunción',
    'latlng': [
      -23,
      -58,
    ],
    'demonym': 'Paraguayan',
  },
  'PE': {
    'population': 31488700,
    'timezones': [
      'UTC-05:00',
    ],
    'currencies': [
      {
        'code': 'PEN',
        'name': 'Peruvian sol',
        'symbol': 'S/.',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'South America',
    'capital': 'Lima',
    'latlng': [
      -10,
      -76,
    ],
    'demonym': 'Peruvian',
  },
  'PH': {
    'population': 103279800,
    'timezones': [
      'UTC+08:00',
    ],
    'currencies': [
      {
        'code': 'PHP',
        'name': 'Philippine peso',
        'symbol': '₱',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'South-Eastern Asia',
    'capital': 'Manila',
    'latlng': [
      13,
      122,
    ],
    'demonym': 'Filipino',
  },
  'PN': {
    'population': 56,
    'timezones': [
      'UTC-08:00',
    ],
    'currencies': [
      {
        'code': 'NZD',
        'name': 'New Zealand dollar',
        'symbol': '$',
      },
      {
        'code': 'PND',
        'name': 'Pitcairn Islands dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Polynesia',
    'capital': 'Adamstown',
    'latlng': [
      -25.06666666,
      -130.1,
    ],
    'demonym': 'Pitcairn Islander',
  },
  'PL': {
    'population': 38437239,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'PLN',
        'name': 'Polish złoty',
        'symbol': 'zł',
      },
    ],
    'languages': [
      {
        'iso639_1': 'pl',
        'iso639_2': 'pol',
        'name': 'Polish',
        'nativeName': 'język polski',
      },
    ],
    'subregion': 'Central Europe',
    'capital': 'Warsaw',
    'latlng': [
      52,
      20,
    ],
    'demonym': 'Polish',
  },
  'PT': {
    'population': 10374822,
    'timezones': [
      'UTC-01:00',
      'UTC',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'pt',
        'iso639_2': 'por',
        'name': 'Portuguese',
        'nativeName': 'Português',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Lisbon',
    'latlng': [
      39.5,
      -8,
    ],
    'demonym': 'Portuguese',
  },
  'PR': {
    'population': 3474182,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'San Juan',
    'latlng': [
      18.25,
      -66.5,
    ],
    'demonym': 'Puerto Rican',
  },
  'QA': {
    'population': 2587564,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'QAR',
        'name': 'Qatari riyal',
        'symbol': 'ر.ق',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Doha',
    'latlng': [
      25.5,
      51.25,
    ],
    'demonym': 'Qatari',
  },
  'XK': {
    'population': 1733842,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'sq',
        'iso639_2': 'sqi',
        'name': 'Albanian',
        'nativeName': 'Shqip',
      },
      {
        'iso639_1': 'sr',
        'iso639_2': 'srp',
        'name': 'Serbian',
        'nativeName': 'српски језик',
      },
    ],
    'subregion': 'Eastern Europe',
    'capital': 'Pristina',
    'latlng': [
      42.666667,
      21.166667,
    ],
    'demonym': 'Kosovar',
  },
  'RE': {
    'population': 840974,
    'timezones': [
      'UTC+04:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Saint-Denis',
    'latlng': [
      -21.15,
      55.5,
    ],
    'demonym': 'French',
  },
  'RO': {
    'population': 19861408,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'RON',
        'name': 'Romanian leu',
        'symbol': 'lei',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ro',
        'iso639_2': 'ron',
        'name': 'Romanian',
        'nativeName': 'Română',
      },
    ],
    'subregion': 'Eastern Europe',
    'capital': 'Bucharest',
    'latlng': [
      46,
      25,
    ],
    'demonym': 'Romanian',
  },
  'RU': {
    'population': 146599183,
    'timezones': [
      'UTC+03:00',
      'UTC+04:00',
      'UTC+06:00',
      'UTC+07:00',
      'UTC+08:00',
      'UTC+09:00',
      'UTC+10:00',
      'UTC+11:00',
      'UTC+12:00',
    ],
    'currencies': [
      {
        'code': 'RUB',
        'name': 'Russian ruble',
        'symbol': '₽',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ru',
        'iso639_2': 'rus',
        'name': 'Russian',
        'nativeName': 'Русский',
      },
    ],
    'subregion': 'Eastern Europe',
    'capital': 'Moscow',
    'latlng': [
      60,
      100,
    ],
    'demonym': 'Russian',
  },
  'RW': {
    'population': 11553188,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'RWF',
        'name': 'Rwandan franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'rw',
        'iso639_2': 'kin',
        'name': 'Kinyarwanda',
        'nativeName': 'Ikinyarwanda',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Kigali',
    'latlng': [
      -2,
      30,
    ],
    'demonym': 'Rwandan',
  },
  'BL': {
    'population': 9417,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Gustavia',
    'latlng': [
      18.5,
      -63.41666666,
    ],
    'demonym': 'Saint Barthélemy Islander',
  },
  'SH': {
    'population': 4255,
    'timezones': [
      'UTC+00:00',
    ],
    'currencies': [
      {
        'code': 'SHP',
        'name': 'Saint Helena pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Jamestown',
    'latlng': [
      -15.95,
      -5.7,
    ],
    'demonym': 'Saint Helenian',
  },
  'KN': {
    'population': 46204,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'XCD',
        'name': 'East Caribbean dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Basseterre',
    'latlng': [
      17.33333333,
      -62.75,
    ],
    'demonym': 'Kittian and Nevisian',
  },
  'LC': {
    'population': 186000,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'XCD',
        'name': 'East Caribbean dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Castries',
    'latlng': [
      13.88333333,
      -60.96666666,
    ],
    'demonym': 'Saint Lucian',
  },
  'MF': {
    'population': 36979,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'nl',
        'iso639_2': 'nld',
        'name': 'Dutch',
        'nativeName': 'Nederlands',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Marigot',
    'latlng': [
      18.08333333,
      -63.95,
    ],
    'demonym': 'Saint Martin Islander',
  },
  'PM': {
    'population': 6069,
    'timezones': [
      'UTC-03:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Northern America',
    'capital': 'Saint-Pierre',
    'latlng': [
      46.83333333,
      -56.33333333,
    ],
    'demonym': 'French',
  },
  'VC': {
    'population': 109991,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'XCD',
        'name': 'East Caribbean dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Kingstown',
    'latlng': [
      13.25,
      -61.2,
    ],
    'demonym': 'Saint Vincentian',
  },
  'WS': {
    'population': 194899,
    'timezones': [
      'UTC+13:00',
    ],
    'currencies': [
      {
        'code': 'WST',
        'name': 'Samoan tālā',
        'symbol': 'T',
      },
    ],
    'languages': [
      {
        'iso639_1': 'sm',
        'iso639_2': 'smo',
        'name': 'Samoan',
        'nativeName': "gagana fa'a Samoa",
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Polynesia',
    'capital': 'Apia',
    'latlng': [
      -13.58333333,
      -172.33333333,
    ],
    'demonym': 'Samoan',
  },
  'SM': {
    'population': 33005,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'it',
        'iso639_2': 'ita',
        'name': 'Italian',
        'nativeName': 'Italiano',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'City of San Marino',
    'latlng': [
      43.76666666,
      12.41666666,
    ],
    'demonym': 'Sammarinese',
  },
  'ST': {
    'population': 187356,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'STD',
        'name': 'São Tomé and Príncipe dobra',
        'symbol': 'Db',
      },
    ],
    'languages': [
      {
        'iso639_1': 'pt',
        'iso639_2': 'por',
        'name': 'Portuguese',
        'nativeName': 'Português',
      },
    ],
    'subregion': 'Middle Africa',
    'capital': 'São Tomé',
    'latlng': [
      1,
      7,
    ],
    'demonym': 'Sao Tomean',
  },
  'SA': {
    'population': 32248200,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'SAR',
        'name': 'Saudi riyal',
        'symbol': 'ر.س',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Riyadh',
    'latlng': [
      25,
      45,
    ],
    'demonym': 'Saudi Arabian',
  },
  'SN': {
    'population': 14799859,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'XOF',
        'name': 'West African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Dakar',
    'latlng': [
      14,
      -14,
    ],
    'demonym': 'Senegalese',
  },
  'RS': {
    'population': 7076372,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'RSD',
        'name': 'Serbian dinar',
        'symbol': 'дин.',
      },
    ],
    'languages': [
      {
        'iso639_1': 'sr',
        'iso639_2': 'srp',
        'name': 'Serbian',
        'nativeName': 'српски језик',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Belgrade',
    'latlng': [
      44,
      21,
    ],
    'demonym': 'Serbian',
  },
  'SC': {
    'population': 91400,
    'timezones': [
      'UTC+04:00',
    ],
    'currencies': [
      {
        'code': 'SCR',
        'name': 'Seychellois rupee',
        'symbol': '₨',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Victoria',
    'latlng': [
      -4.58333333,
      55.66666666,
    ],
    'demonym': 'Seychellois',
  },
  'SL': {
    'population': 7075641,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'SLL',
        'name': 'Sierra Leonean leone',
        'symbol': 'Le',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Freetown',
    'latlng': [
      8.5,
      -11.5,
    ],
    'demonym': 'Sierra Leonean',
  },
  'SG': {
    'population': 5535000,
    'timezones': [
      'UTC+08:00',
    ],
    'currencies': [
      {
        'code': 'BND',
        'name': 'Brunei dollar',
        'symbol': '$',
      },
      {
        'code': 'SGD',
        'name': 'Singapore dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'ms',
        'iso639_2': 'msa',
        'name': 'Malay',
        'nativeName': 'bahasa Melayu',
      },
      {
        'iso639_1': 'ta',
        'iso639_2': 'tam',
        'name': 'Tamil',
        'nativeName': 'தமிழ்',
      },
      {
        'iso639_1': 'zh',
        'iso639_2': 'zho',
        'name': 'Chinese',
        'nativeName': '中文 (Zhōngwén)',
      },
    ],
    'subregion': 'South-Eastern Asia',
    'capital': 'Singapore',
    'latlng': [
      1.36666666,
      103.8,
    ],
    'demonym': 'Singaporean',
  },
  'SX': {
    'population': 38247,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'ANG',
        'name': 'Netherlands Antillean guilder',
        'symbol': 'ƒ',
      },
    ],
    'languages': [
      {
        'iso639_1': 'nl',
        'iso639_2': 'nld',
        'name': 'Dutch',
        'nativeName': 'Nederlands',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Philipsburg',
    'latlng': [
      18.033333,
      -63.05,
    ],
    'demonym': 'Dutch',
  },
  'SK': {
    'population': 5426252,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'sk',
        'iso639_2': 'slk',
        'name': 'Slovak',
        'nativeName': 'slovenčina',
      },
    ],
    'subregion': 'Central Europe',
    'capital': 'Bratislava',
    'latlng': [
      48.66666666,
      19.5,
    ],
    'demonym': 'Slovak',
  },
  'SI': {
    'population': 2064188,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'sl',
        'iso639_2': 'slv',
        'name': 'Slovene',
        'nativeName': 'slovenski jezik',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Ljubljana',
    'latlng': [
      46.11666666,
      14.81666666,
    ],
    'demonym': 'Slovene',
  },
  'SB': {
    'population': 642000,
    'timezones': [
      'UTC+11:00',
    ],
    'currencies': [
      {
        'code': 'SBD',
        'name': 'Solomon Islands dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Melanesia',
    'capital': 'Honiara',
    'latlng': [
      -8,
      159,
    ],
    'demonym': 'Solomon Islander',
  },
  'SO': {
    'population': 11079000,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'SOS',
        'name': 'Somali shilling',
        'symbol': 'Sh',
      },
    ],
    'languages': [
      {
        'iso639_1': 'so',
        'iso639_2': 'som',
        'name': 'Somali',
        'nativeName': 'Soomaaliga',
      },
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Mogadishu',
    'latlng': [
      10,
      49,
    ],
    'demonym': 'Somali',
  },
  'ZA': {
    'population': 55653654,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'ZAR',
        'name': 'South African rand',
        'symbol': 'R',
      },
    ],
    'languages': [
      {
        'iso639_1': 'af',
        'iso639_2': 'afr',
        'name': 'Afrikaans',
        'nativeName': 'Afrikaans',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'nr',
        'iso639_2': 'nbl',
        'name': 'Southern Ndebele',
        'nativeName': 'isiNdebele',
      },
      {
        'iso639_1': 'st',
        'iso639_2': 'sot',
        'name': 'Southern Sotho',
        'nativeName': 'Sesotho',
      },
      {
        'iso639_1': 'ss',
        'iso639_2': 'ssw',
        'name': 'Swati',
        'nativeName': 'SiSwati',
      },
      {
        'iso639_1': 'tn',
        'iso639_2': 'tsn',
        'name': 'Tswana',
        'nativeName': 'Setswana',
      },
      {
        'iso639_1': 'ts',
        'iso639_2': 'tso',
        'name': 'Tsonga',
        'nativeName': 'Xitsonga',
      },
      {
        'iso639_1': 've',
        'iso639_2': 'ven',
        'name': 'Venda',
        'nativeName': 'Tshivenḓa',
      },
      {
        'iso639_1': 'xh',
        'iso639_2': 'xho',
        'name': 'Xhosa',
        'nativeName': 'isiXhosa',
      },
      {
        'iso639_1': 'zu',
        'iso639_2': 'zul',
        'name': 'Zulu',
        'nativeName': 'isiZulu',
      },
    ],
    'subregion': 'Southern Africa',
    'capital': 'Pretoria',
    'latlng': [
      -29,
      24,
    ],
    'demonym': 'South African',
  },
  'GS': {
    'population': 30,
    'timezones': [
      'UTC-02:00',
    ],
    'currencies': [
      {
        'code': 'FKP',
        'name': 'Falkland Islands Pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'South America',
    'capital': 'King Edward Point',
    'latlng': [
      -54.5,
      -37,
    ],
    'demonym': 'South Georgia and the South Sandwich Islander',
  },
  'KR': {
    'population': 50801405,
    'timezones': [
      'UTC+09:00',
    ],
    'currencies': [
      {
        'code': 'KRW',
        'name': 'South Korean won',
        'symbol': '₩',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ko',
        'iso639_2': 'kor',
        'name': 'Korean',
        'nativeName': '한국어',
      },
    ],
    'subregion': 'Eastern Asia',
    'capital': 'Seoul',
    'latlng': [
      37,
      127.5,
    ],
    'demonym': 'South Korean',
  },
  'SS': {
    'population': 12131000,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'SSP',
        'name': 'South Sudanese pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Middle Africa',
    'capital': 'Juba',
    'latlng': [
      7,
      30,
    ],
    'demonym': 'South Sudanese',
  },
  'ES': {
    'population': 46438422,
    'timezones': [
      'UTC',
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'EUR',
        'name': 'Euro',
        'symbol': '€',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Southern Europe',
    'capital': 'Madrid',
    'latlng': [
      40,
      -4,
    ],
    'demonym': 'Spanish',
  },
  'LK': {
    'population': 20966000,
    'timezones': [
      'UTC+05:30',
    ],
    'currencies': [
      {
        'code': 'LKR',
        'name': 'Sri Lankan rupee',
        'symbol': 'Rs',
      },
    ],
    'languages': [
      {
        'iso639_1': 'si',
        'iso639_2': 'sin',
        'name': 'Sinhalese',
        'nativeName': 'සිංහල',
      },
      {
        'iso639_1': 'ta',
        'iso639_2': 'tam',
        'name': 'Tamil',
        'nativeName': 'தமிழ்',
      },
    ],
    'subregion': 'Southern Asia',
    'capital': 'Sri Jayawardenepura Kotte',
    'latlng': [
      7,
      81,
    ],
    'demonym': 'Sri Lankan',
  },
  'SD': {
    'population': 39598700,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'SDG',
        'name': 'Sudanese pound',
        'symbol': 'ج.س.',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Northern Africa',
    'capital': 'Khartoum',
    'latlng': [
      15,
      30,
    ],
    'demonym': 'Sudanese',
  },
  'SR': {
    'population': 541638,
    'timezones': [
      'UTC-03:00',
    ],
    'currencies': [
      {
        'code': 'SRD',
        'name': 'Surinamese dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'nl',
        'iso639_2': 'nld',
        'name': 'Dutch',
        'nativeName': 'Nederlands',
      },
    ],
    'subregion': 'South America',
    'capital': 'Paramaribo',
    'latlng': [
      4,
      -56,
    ],
    'demonym': 'Surinamer',
  },
  'SJ': {
    'population': 2562,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'NOK',
        'name': 'Norwegian krone',
        'symbol': 'kr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'no',
        'iso639_2': 'nor',
        'name': 'Norwegian',
        'nativeName': 'Norsk',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Longyearbyen',
    'latlng': [
      78,
      20,
    ],
    'demonym': 'Norwegian',
  },
  'SZ': {
    'population': 1132657,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'SZL',
        'name': 'Swazi lilangeni',
        'symbol': 'L',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'ss',
        'iso639_2': 'ssw',
        'name': 'Swati',
        'nativeName': 'SiSwati',
      },
    ],
    'subregion': 'Southern Africa',
    'capital': 'Lobamba',
    'latlng': [
      -26.5,
      31.5,
    ],
    'demonym': 'Swazi',
  },
  'SE': {
    'population': 9894888,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'SEK',
        'name': 'Swedish krona',
        'symbol': 'kr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'sv',
        'iso639_2': 'swe',
        'name': 'Swedish',
        'nativeName': 'svenska',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'Stockholm',
    'latlng': [
      62,
      15,
    ],
    'demonym': 'Swedish',
  },
  'CH': {
    'population': 8341600,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'CHF',
        'name': 'Swiss franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'de',
        'iso639_2': 'deu',
        'name': 'German',
        'nativeName': 'Deutsch',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
      {
        'iso639_1': 'it',
        'iso639_2': 'ita',
        'name': 'Italian',
        'nativeName': 'Italiano',
      },
      {
        'iso639_2': 'roh',
        'name': 'Romansh',
      },
    ],
    'subregion': 'Central Europe',
    'capital': 'Bern',
    'latlng': [
      47,
      8,
    ],
    'demonym': 'Swiss',
  },
  'SY': {
    'population': 18564000,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'SYP',
        'name': 'Syrian pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Damascus',
    'latlng': [
      35,
      38,
    ],
    'demonym': 'Syrian',
  },
  'TW': {
    'population': 23503349,
    'timezones': [
      'UTC+08:00',
    ],
    'currencies': [
      {
        'code': 'TWD',
        'name': 'New Taiwan dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'zh',
        'iso639_2': 'zho',
        'name': 'Chinese',
        'nativeName': '中文 (Zhōngwén)',
      },
    ],
    'subregion': 'Eastern Asia',
    'capital': 'Taipei',
    'latlng': [
      23.5,
      121,
    ],
    'demonym': 'Taiwanese',
  },
  'TJ': {
    'population': 8593600,
    'timezones': [
      'UTC+05:00',
    ],
    'currencies': [
      {
        'code': 'TJS',
        'name': 'Tajikistani somoni',
        'symbol': 'ЅМ',
      },
    ],
    'languages': [
      {
        'iso639_1': 'tg',
        'iso639_2': 'tgk',
        'name': 'Tajik',
        'nativeName': 'тоҷикӣ',
      },
      {
        'iso639_1': 'ru',
        'iso639_2': 'rus',
        'name': 'Russian',
        'nativeName': 'Русский',
      },
    ],
    'subregion': 'Central Asia',
    'capital': 'Dushanbe',
    'latlng': [
      39,
      71,
    ],
    'demonym': 'Tadzhik',
  },
  'TZ': {
    'population': 55155000,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'TZS',
        'name': 'Tanzanian shilling',
        'symbol': 'Sh',
      },
    ],
    'languages': [
      {
        'iso639_1': 'sw',
        'iso639_2': 'swa',
        'name': 'Swahili',
        'nativeName': 'Kiswahili',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Dodoma',
    'latlng': [
      -6,
      35,
    ],
    'demonym': 'Tanzanian',
  },
  'TH': {
    'population': 65327652,
    'timezones': [
      'UTC+07:00',
    ],
    'currencies': [
      {
        'code': 'THB',
        'name': 'Thai baht',
        'symbol': '฿',
      },
    ],
    'languages': [
      {
        'iso639_1': 'th',
        'iso639_2': 'tha',
        'name': 'Thai',
        'nativeName': 'ไทย',
      },
    ],
    'subregion': 'South-Eastern Asia',
    'capital': 'Bangkok',
    'latlng': [
      15,
      100,
    ],
    'demonym': 'Thai',
  },
  'TL': {
    'population': 1167242,
    'timezones': [
      'UTC+09:00',
    ],
    'currencies': [
      {
        'code': 'THB',
        'name': 'Thai baht',
        'symbol': '฿',
      },
    ],
    'languages': [
      {
        'iso639_1': 'pt',
        'iso639_2': 'por',
        'name': 'Portuguese',
        'nativeName': 'Português',
      },
    ],
    'subregion': 'South-Eastern Asia',
    'capital': 'Dili',
    'latlng': [
      -8.83333333,
      125.91666666,
    ],
    'demonym': 'East Timorese',
  },
  'TG': {
    'population': 7143000,
    'timezones': [
      'UTC',
    ],
    'currencies': [
      {
        'code': 'XOF',
        'name': 'West African CFA franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Western Africa',
    'capital': 'Lomé',
    'latlng': [
      8,
      1.16666666,
    ],
    'demonym': 'Togolese',
  },
  'TK': {
    'population': 1411,
    'timezones': [
      'UTC+13:00',
    ],
    'currencies': [
      {
        'code': 'NZD',
        'name': 'New Zealand dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Polynesia',
    'capital': 'Fakaofo',
    'latlng': [
      -9,
      -172,
    ],
    'demonym': 'Tokelauan',
  },
  'TO': {
    'population': 103252,
    'timezones': [
      'UTC+13:00',
    ],
    'currencies': [
      {
        'code': 'TOP',
        'name': 'Tongan paʻanga',
        'symbol': 'T$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'to',
        'iso639_2': 'ton',
        'name': 'Tonga (Tonga Islands)',
        'nativeName': 'faka Tonga',
      },
    ],
    'subregion': 'Polynesia',
    'capital': "Nuku'alofa",
    'latlng': [
      -20,
      -175,
    ],
    'demonym': 'Tongan',
  },
  'TT': {
    'population': 1349667,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'TTD',
        'name': 'Trinidad and Tobago dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Port of Spain',
    'latlng': [
      11,
      -61,
    ],
    'demonym': 'Trinidadian',
  },
  'TN': {
    'population': 11154400,
    'timezones': [
      'UTC+01:00',
    ],
    'currencies': [
      {
        'code': 'TND',
        'name': 'Tunisian dinar',
        'symbol': 'د.ت',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Northern Africa',
    'capital': 'Tunis',
    'latlng': [
      34,
      9,
    ],
    'demonym': 'Tunisian',
  },
  'TR': {
    'population': 78741053,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'TRY',
        'name': 'Turkish lira',
        'symbol': '֏',
      },
    ],
    'languages': [
      {
        'iso639_1': 'tr',
        'iso639_2': 'tur',
        'name': 'Turkish',
        'nativeName': 'Türkçe',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Ankara',
    'latlng': [
      39,
      35,
    ],
  },
  'TM': {
    'population': 4751120,
    'timezones': [
      'UTC+05:00',
    ],
    'currencies': [
      {
        'code': 'TMT',
        'name': 'Turkmenistan manat',
        'symbol': 'm',
      },
    ],
    'languages': [
      {
        'iso639_1': 'tk',
        'iso639_2': 'tuk',
        'name': 'Turkmen',
        'nativeName': 'Türkmen',
      },
      {
        'iso639_1': 'ru',
        'iso639_2': 'rus',
        'name': 'Russian',
        'nativeName': 'Русский',
      },
    ],
    'subregion': 'Central Asia',
    'capital': 'Ashgabat',
    'latlng': [
      40,
      60,
    ],
    'demonym': 'Turkmen',
  },
  'TC': {
    'population': 31458,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Caribbean',
    'capital': 'Cockburn Town',
    'latlng': [
      21.75,
      -71.58333333,
    ],
    'demonym': 'Turks and Caicos Islander',
  },
  'TV': {
    'population': 10640,
    'timezones': [
      'UTC+12:00',
    ],
    'currencies': [
      {
        'code': 'AUD',
        'name': 'Australian dollar',
        'symbol': '$',
      },
      {
        'code': 'TVD[G]',
        'name': 'Tuvaluan dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Polynesia',
    'capital': 'Funafuti',
    'latlng': [
      -8,
      178,
    ],
    'demonym': 'Tuvaluan',
  },
  'UG': {
    'population': 33860700,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'UGX',
        'name': 'Ugandan shilling',
        'symbol': 'Sh',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'sw',
        'iso639_2': 'swa',
        'name': 'Swahili',
        'nativeName': 'Kiswahili',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Kampala',
    'latlng': [
      1,
      32,
    ],
    'demonym': 'Ugandan',
  },
  'UA': {
    'population': 42692393,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'UAH',
        'name': 'Ukrainian hryvnia',
        'symbol': '₴',
      },
    ],
    'languages': [
      {
        'iso639_1': 'uk',
        'iso639_2': 'ukr',
        'name': 'Ukrainian',
        'nativeName': 'Українська',
      },
    ],
    'subregion': 'Eastern Europe',
    'capital': 'Kyiv',
    'latlng': [
      49,
      32,
    ],
    'demonym': 'Ukrainian',
  },
  'AE': {
    'population': 9856000,
    'timezones': [
      'UTC+04',
    ],
    'currencies': [
      {
        'code': 'AED',
        'name': 'United Arab Emirates dirham',
        'symbol': 'د.إ',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Abu Dhabi',
    'latlng': [
      24,
      54,
    ],
    'demonym': 'Emirati',
  },
  'GB': {
    'population': 65110000,
    'timezones': [
      'UTC-08:00',
      'UTC-05:00',
      'UTC-04:00',
      'UTC-03:00',
      'UTC-02:00',
      'UTC',
      'UTC+01:00',
      'UTC+02:00',
      'UTC+06:00',
    ],
    'currencies': [
      {
        'code': 'GBP',
        'name': 'British pound',
        'symbol': '£',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Northern Europe',
    'capital': 'London',
    'latlng': [
      54,
      -2,
    ],
    'demonym': 'British',
  },
  'US': {
    'population': 323947000,
    'timezones': [
      'UTC-12:00',
      'UTC-11:00',
      'UTC-10:00',
      'UTC-09:00',
      'UTC-08:00',
      'UTC-07:00',
      'UTC-06:00',
      'UTC-05:00',
      'UTC-04:00',
      'UTC+10:00',
      'UTC+12:00',
    ],
    'currencies': [
      {
        'code': 'USD',
        'name': 'United States dollar',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Northern America',
    'capital': 'Washington, D.C.',
    'latlng': [
      38,
      -97,
    ],
    'demonym': 'American',
  },
  'UY': {
    'population': 3480222,
    'timezones': [
      'UTC-03:00',
    ],
    'currencies': [
      {
        'code': 'UYU',
        'name': 'Uruguayan peso',
        'symbol': '$',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'South America',
    'capital': 'Montevideo',
    'latlng': [
      -33,
      -56,
    ],
    'demonym': 'Uruguayan',
  },
  'UZ': {
    'population': 31576400,
    'timezones': [
      'UTC+05:00',
    ],
    'currencies': [
      {
        'code': 'UZS',
        'name': "Uzbekistani so'm",
        'symbol': "so'm",
      },
    ],
    'languages': [
      {
        'iso639_1': 'uz',
        'iso639_2': 'uzb',
        'name': 'Uzbek',
        'nativeName': 'Oʻzbek',
      },
      {
        'iso639_1': 'ru',
        'iso639_2': 'rus',
        'name': 'Russian',
        'nativeName': 'Русский',
      },
    ],
    'subregion': 'Central Asia',
    'capital': 'Tashkent',
    'latlng': [
      41,
      64,
    ],
  },
  'VU': {
    'population': 277500,
    'timezones': [
      'UTC+11:00',
    ],
    'currencies': [
      {
        'code': 'VUV',
        'name': 'Vanuatu vatu',
        'symbol': 'Vt',
      },
    ],
    'languages': [
      {
        'iso639_1': 'bi',
        'iso639_2': 'bis',
        'name': 'Bislama',
        'nativeName': 'Bislama',
      },
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Melanesia',
    'capital': 'Port Vila',
    'latlng': [
      -16,
      167,
    ],
    'demonym': 'Ni-Vanuatu',
  },
  'VE': {
    'population': 31028700,
    'timezones': [
      'UTC-04:00',
    ],
    'currencies': [
      {
        'code': 'VEF',
        'name': 'Venezuelan bolívar',
        'symbol': 'Bs S',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'South America',
    'capital': 'Caracas',
    'latlng': [
      8,
      -66,
    ],
    'demonym': 'Venezuelan',
  },
  'VN': {
    'population': 92700000,
    'timezones': [
      'UTC+07:00',
    ],
    'currencies': [
      {
        'code': 'VND',
        'name': 'Vietnamese đồng',
        'symbol': '₫',
      },
    ],
    'languages': [
      {
        'iso639_1': 'vi',
        'iso639_2': 'vie',
        'name': 'Vietnamese',
        'nativeName': 'Tiếng Việt',
      },
    ],
    'subregion': 'South-Eastern Asia',
    'capital': 'Hanoi',
    'latlng': [
      16.16666666,
      107.83333333,
    ],
    'demonym': 'Vietnamese',
  },
  'WF': {
    'population': 11750,
    'timezones': [
      'UTC+12:00',
    ],
    'currencies': [
      {
        'code': 'XPF',
        'name': 'CFP franc',
        'symbol': 'Fr',
      },
    ],
    'languages': [
      {
        'iso639_1': 'fr',
        'iso639_2': 'fra',
        'name': 'French',
        'nativeName': 'français',
      },
    ],
    'subregion': 'Polynesia',
    'capital': 'Mata-Utu',
    'latlng': [
      -13.3,
      -176.2,
    ],
    'demonym': 'Wallis and Futuna Islander',
  },
  'EH': {
    'population': 510713,
    'timezones': [
      'UTC+00:00',
    ],
    'currencies': [
      {
        'code': 'MAD',
        'name': 'Moroccan dirham',
        'symbol': 'د.م.',
      },
      {
        'code': 'DZD',
        'name': 'Algerian dinar',
        'symbol': 'د.ج',
      },
    ],
    'languages': [
      {
        'iso639_1': 'es',
        'iso639_2': 'spa',
        'name': 'Spanish',
        'nativeName': 'Español',
      },
    ],
    'subregion': 'Northern Africa',
    'capital': 'El Aaiún',
    'latlng': [
      24.5,
      -13,
    ],
    'demonym': 'Sahrawi',
  },
  'YE': {
    'population': 27478000,
    'timezones': [
      'UTC+03:00',
    ],
    'currencies': [
      {
        'code': 'YER',
        'name': 'Yemeni rial',
        'symbol': '﷼',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': "Sana'a",
    'latlng': [
      15,
      48,
    ],
    'demonym': 'Yemeni',
  },
  'ZM': {
    'population': 15933883,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'ZMW',
        'name': 'Zambian kwacha',
        'symbol': 'ZK',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Lusaka',
    'latlng': [
      -15,
      30,
    ],
    'demonym': 'Zambian',
  },
  'ZW': {
    'population': 14240168,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'ZMW',
        'name': 'Zambian kwacha',
        'symbol': 'K',
      },
    ],
    'languages': [
      {
        'iso639_1': 'en',
        'iso639_2': 'eng',
        'name': 'English',
        'nativeName': 'English',
      },
      {
        'iso639_1': 'sn',
        'iso639_2': 'sna',
        'name': 'Shona',
        'nativeName': 'chiShona',
      },
      {
        'iso639_1': 'nd',
        'iso639_2': 'nde',
        'name': 'Northern Ndebele',
        'nativeName': 'isiNdebele',
      },
    ],
    'subregion': 'Eastern Africa',
    'capital': 'Harare',
    'latlng': [
      -20,
      30,
    ],
    'demonym': 'Zimbabwean',
  },
  'HK': {
    'population': 5159076,
    'timezones': [
      'UTC+02:00',
    ],
    'currencies': [
      {
        'code': 'EGP',
        'name': 'Egyptian pound',
        'symbol': 'E£',
      },
      {
        'code': 'ILS',
        'name': 'Israeli new shekel',
        'symbol': '₪',
      },
      {
        'code': 'JOD',
        'name': 'Jordanian dinar',
        'symbol': 'د.أ',
      },
    ],
    'languages': [
      {
        'iso639_1': 'ar',
        'iso639_2': 'ara',
        'name': 'Arabic',
        'nativeName': 'العربية',
      },
    ],
    'subregion': 'Western Asia',
    'capital': 'Ramallah',
    'latlng': [
      31.9,
      35.2,
    ],
    'demonym': 'Palestinian',
  },
};
