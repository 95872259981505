<template>
<div class="chart-container">
  <h2 v-if="isMobile">{{ title }}</h2>
  <HorizontalBar v-if="dataReady" :title="title" :labels="colleges" :datasets="datasets" :styles="styles"/>
</div>
</template>

<script>
import { mapState } from 'vuex';
import HorizontalBar from './HorizontalBar.vue';

export default {
  components: { HorizontalBar },
  props: ['activities'],
  data() {
    return {
      title: '',
      colleges: [],
      datasets: [],
      dataReady: false,
      styles: {
        width: '100%',
        height: '',
      },
    };
  },
  computed: {
    ...mapState(['isMobile']),
  },
  mounted() {
    // Map descriptions to desired colleges. Exclude colleges we don't want to display (e.g. Provost)
    const descToCollege = {
      'Business, W. P. Carey School of': 'W.P. Carey School\nof Business',
      'Design and the Arts, Herberger Institute for': 'Herberger Institute for\nDesign & the Arts',
      'Engineering, Ira A. Fulton Schools of': 'Ira A. Fulton Schools\nof Engineering',
      'Global Futures, College of': 'College of Global\nFutures',
      'Global Management, Thunderbird School of': 'Thunderbird School of\nGlobal Management',
      'Health Solutions, College of': 'College of Health\nSolutions',
      'Integrative Sciences and Arts, College of': 'College of Integrative\nSciences and Arts',
      'Interdisciplinary Arts & Sciences, New College of': 'New College of\nInterdisciplinary\nArts & Sciences',
      'Journalism & Mass Communication, Walter Cronkite School of': 'Walter Cronkite School\nof Journalism &\nMass Communication',
      'Liberal Arts & Sciences, The College of': 'The College of Liberal\nArts & Sciences',
      'Nursing and Health Innovation, Edson College of': 'Edson College of\nNursing and Health\nInnovation',
      'Public Service and Community Solutions, Watts College of': 'Watts College of Public\nService and Community\nSolutions',
      'Teachers College, Mary Lou Fulton': 'Mary Lou Fulton\nTeachers College',
      'Law, Sandra Day O\'Connor College of': 'Sandra Day O\'Connor\nCollege of Law',
      'Graduate College': 'Graduate College',
    };
    const totalStudents = this.activities.reduce((acc, activity) => acc + activity.count_distinct_student_id, 0);
    this.title = `Total International Students: ${totalStudents}`;
    const studentsByCollege = this.activities.reduce((acc, activity) => {
      const college = descToCollege[activity.college_short_descr];
      if (college == null) {
        return acc;
      }
      if (!acc[college]) {
        acc[college] = {
          undergrad: 0,
          grad: 0,
          total: 0,
        };
      }
      acc[college].total += activity.count_distinct_student_id;
      if (activity.undergrad_or_gradute_descr.toLowerCase() === 'undergraduate') {
        acc[college].undergrad = activity.count_distinct_student_id;
      } else if (activity.undergrad_or_gradute_descr.toLowerCase() === 'graduate') {
        acc[college].grad = activity.count_distinct_student_id;
      }
      return acc;
    }, {});
    // sort colleges by total number of students, descending
    const sorted = Object.entries(studentsByCollege).sort((a, b) => b[1].total - a[1].total);
    this.colleges = sorted.map(data => data[0]);

    const barThickness = 30;
    this.datasets.push({
      label: 'Undergraduate Students',
      backgroundColor: '#8c1d40',
      barThickness,
      data: sorted.map(data => data[1].undergrad),
    });
    this.datasets.push({
      label: 'Graduate Students',
      backgroundColor: '#ffc627',
      barThickness,
      data: sorted.map(data => data[1].grad),
    });
    // calculate height based on how many horizontal bars will be displayed
    this.styles.height = `${this.colleges.length > 2 ? this.colleges.length * 60 : this.colleges.length * 100}px`;
    this.dataReady = true;
  },
};
</script>

<style scoped>
.chart-container {
  padding: 30px;
}
.chart-container h2 {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}
.doughnut-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
