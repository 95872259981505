<template>
  <div data-test="country-details-bucket-overview">
    <div class="card-container--header" v-if="bucketName === 'Study Abroad'">
      <h2 data-test="country-details-bucket-name">
        {{ bucketData.title }}
      </h2>
      <div class="study-abroad-breakdown">
        <span>{{ totalActivities }} Programs</span>
        <span>{{ totalStudents }} Students</span>
      </div>
    </div>
    <div
      class="card-container--header"
      v-else-if="
        ['Research', 'Locations', 'Partnerships', 'Alumni'].includes(
          bucketName
        ) || featured
      "
    >
      <h2
        data-test="country-details-bucket-name"
        :class="{ 'featured-title': featured }"
      >
        {{ title || bucketData.title || bucketName
        }}<template v-if="!featured">: {{ totalActivities }}</template>
      </h2>
    </div>
    <div class="card-container--header" v-else>
      <h2 data-test="country-details-bucket-name">
        {{ bucketData.title }}:
        {{ totalActivities }}
      </h2>
    </div>
    <hr />
    <SearchResultsItem
      v-for="(activity, i) in activities"
      :key="i"
      :bucket="bucketName"
      :activity="activity"
      class="results--record"
    />
    <div class="bottom-slot">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchResultsItem from "./SearchResultsItem.vue";

export default {
  components: {
    SearchResultsItem,
  },
  // title is optional
  props: ["activities", "bucketData", "bucketName", "title", "featured"],
  computed: {
    totalStudents() {
      return this.activities.reduce((a, v) => a + parseInt(v.students, 10), 0);
    },
    totalActivities() {
      if (this.bucketName === "Research") {
        if (this.activities.length > 0) {
          return this.activities[0].number_of_agreements;
        }
      }
      return this.activities.length;
    },
  },
};
</script>

<style scoped>
.card-container--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px 20px 40px;
  color: black;
}
.study-abroad-breakdown span {
  margin-left: 20px;
  font-weight: 700;
}
.results--record {
  padding: 20px 40px;
}
.results--record:nth-child(even) {
  background: #fafafa;
}
.results--record:last-child {
  padding-bottom: 50px;
  margin-bottom: -30px;
}
.featured-title {
  display: inline-block;
  /* text-decoration: underline; */
  box-shadow: -0.15em 0 0 #ffc627, 0.15em 0 0 #ffc627;
  background: #ffc627;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  line-height: 1.5em;
  padding: 0 4px;
  transform: translateX(-4px);
}
.bottom-slot {
  padding: 0px 40px;
}
</style>
