import 'core-js';
import 'bootstrap';
import Vue from 'vue';
import store from './store';

import App from './App.vue';
import router from './router';
import { renderAsuHeader } from './renderAsuHeader';

renderAsuHeader();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
