<template>
<div class="search-box-wrapper">
  <div class="search-box">
    <i class="fas fa-search search-icon"></i>
    <form action="." v-on:submit.prevent>
      <input
        type="search"
        placeholder="Search Countries"
        v-model="search"
        ref="input"
        aria-label="Search Countries"
        @focus="openDropdown"
        data-test="search-box-input"
      />
    </form>
    <button v-show="search" aria-label="clear icon" @click="clearResults">
      <i class="fas fa-times clear-icon"></i>
    </button>
    <div class="search-dropdown" v-show="showDropdown">
      <hr>
      <div v-if="filteredSearchCountries.length < 1" class="no-results">No Results</div>
      <ul v-else>
        <li v-for="country in filteredSearchCountries" :key="country.item.country" @click="selectCountry(country.item)">
          <div class="country-link-wrapper">
            <button>{{ country.item.country }}</button>
            <i class="fas fa-arrow-right arrow-icon"></i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';

export default {
  // mixins: [validationMixin],
  data() {
    return {
      search: '',
      countrySelected: false,
      showDropdown: false,
    };
  },
  computed: {
    ...mapState(['searchTerm', 'selectedCountryInSearch']),
    ...mapGetters(['filteredSearchCountries']),
  },
  methods: {
    ...mapMutations(['updateSearchTerm', 'updateContinent', 'updateSelectedCountryInSearch']),
    openDropdown() {
      this.showDropdown = this.search.length > 1;
    },
    selectCountry(item) {
      this.updateSelectedCountryInSearch(item);
      this.countrySelected = true;
      this.search = item.country;
      this.showDropdown = false;
    },
    clearResults() {
      this.search = '';
      this.updateSelectedCountryInSearch(null);
    },
  },
  watch: {
    search() {
      this.updateSearchTerm(this.search);
      if (!this.countrySelected) {
        this.openDropdown();
      }
      this.countrySelected = false;
    },
  },
};
</script>

<style scoped>
.search-box-wrapper {
  width: 100%;
  padding: 0 30px;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  border: 1px solid #c6c6c6;
  border-radius: 6px;
  background: #ffffff;
  position: relative;
}
form {
  width: 82%;
}
.search-box input {
  color: black;
  /* width: 82%; */
  width: 100%;
  padding: 10px 0;
  outline: none;
}
.search-icon {
  font-size: 20px;
  margin: 5px 12px;
}
.clear-icon {
  font-size: 16px;
  margin: 0 12px;
  color: black;
}
.search-dropdown {
  position: absolute;
  top: 32px;
  left: -1px;
  right: -1px;
  max-height: 300px;
  overflow: hidden auto;
  background-color: #ffffff;
  border: 1px solid #c6c6c6;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 1;
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.2);
}
.search-dropdown hr {
  background-color: #eddce2;
  border: 0;
  height: 1px;
  margin: 10px;
}
.search-dropdown ul {
  list-style: none;
}
.search-dropdown li {
  display: flex;
  /* border-bottom: 1px solid #eddce2; */
}
.search-dropdown li:last-child {
  border: none;
}
.search-dropdown li:active, .search-dropdown li:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
.search-dropdown li .country-link-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  margin: 0 10px;
  border-bottom: 1px solid #eddce2;
  font-weight: 500;
}
.search-dropdown li:active, .search-dropdown li:hover .country-link-wrapper {
  border-color: #f1f1f1;
}
.arrow-icon {
  font-size: 16px;
}
.no-results {
  padding: 20px 10px;
  font-weight: 500;
}
@media only screen and (max-width: 1000px),
  only screen and (max-device-width: 1000px)
  and (orientation: landscape) {
  form {
    width: 92%;
  }
}
</style>
