<template>
  <div>
    <Spinner v-if="loading" class="progress"/>
    <div v-else :class="{scrollable: !selectedCountry}">
      <BucketSelect v-if="isMobile" />
      <div class="layout-container">
        <SideNavWrapper />
        <div class="map-wrapper" v-if="countryDataLoaded">
          <IntroPanel v-if="!introClosed" @close="closeIntro" />
          <WorldMap />
          <router-link v-if="selectedContinent" :to="'/'" class="zoom-out-button button--primary">
            Zoom Out <i class="fas fa-search-minus" aria-hidden="true"></i>
          </router-link>
          <BucketSelect v-if="!isMobile" />
          <div v-if="totalResults <= 0" class="no-results">Select additional filters!</div>
        </div>
      </div>
    </div>

    <CountryDetails v-if="selectedCountry" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import IntroPanel from './components/IntroPanel.vue';
import WorldMap from './components/WorldMap.vue';
import SideNavWrapper from './components/SideNavWrapper.vue';
import BucketSelect from './components/BucketSelect.vue';
import CountryDetails from './components/CountryDetails.vue';
import Spinner from './components/Spinner.vue';
import { convertDashesToSpaces } from './helpers/helpers';
import { initializeCountries, lookupIsoCode } from './data/countryCodeLookup';
import './normalize.css';

export default {
  name: 'App',
  components: {
    IntroPanel,
    WorldMap,
    SideNavWrapper,
    BucketSelect,
    CountryDetails,
    Spinner,
  },
  data() {
    return {
      countryDataLoaded: false,
    };
  },
  watch: {
    $route() {
      this.$store.dispatch('resolveRouteParams');
    },
  },
  computed: {
    ...mapState([
      'selectedBucket',
      'selectedCountry',
      'selectedContinent',
      'searchTerm',
      'allData',
      'bucketSummaryData',
      'isMobile',
      'introClosed',
    ]),
    ...mapGetters(['totalResults']),
    loading() {
      return !this.allData || !this.bucketSummaryData;
    },
  },
  methods: {
    ...mapMutations([
      'updateCurrentBucket',
      'updateSelectedBuckets',
      'updateContinent',
      'updateCountry',
      'updateSearchTerm',
      'updateIsMobile',
      'updateWindowWidth',
      'updateIntroClosed',
    ]),
    onResize() {
      this.updateWindowWidth(window.innerWidth);
    },
    checkIsMobile() {
      // console.log("WINDOW WIDTH", window.innerWidth);
      if ((window.innerWidth <= 1000 || (window.innerWidth <= 1000 && (window.innerWidth > window.innerHeight))) && !this.$store.state.isMobile) {
        this.updateIsMobile(true);
      } else if ((window.innerWidth > 1000 || (window.innerWidth > 1000 && window.innerWidth <= window.innerHeight)) && this.$store.state.isMobile) {
        this.updateIsMobile(false);
      }
    },
    closeIntro() {
      this.updateIntroClosed(true);
    },
  },
  created() {
    this.$store.dispatch('resolveRouteParams');
    // don't load maps until countries have been fetched from an external API
    initializeCountries().then(() => {
      this.countryDataLoaded = true;
      this.$store.dispatch('getAllData');
    });
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
};
</script>

<style>

:root {
  --asu-maroon: #8c1d40;
  --asu-maroon-hover: #541126;
  --asu-gold: #ffc627;
  --outline-color: #5E9ED6;
  --asu-blue: #00a3e0;
}

.app-container {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: var(--asu-maroon);
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  /* prevent scrolling on safari mobile when country details or search results is open */
  position: fixed;
}
body::-webkit-scrollbar {
  /* fully hide scrollbar in WebKit (scrollbar appears even though overflow is hidden) */
  width: 0;
}
@media (max-width: 1920px) {
  .app-container {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .app-container {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1000px),
  only screen and (max-device-width: 1000px)
  and (orientation: landscape) {
  .app-container {
    font-size: 10px;
  }
}

.button--primary {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  background-color: var(--asu-maroon);
  font-weight: 700;
  color: white;
  height: 40px;
  padding: 0 20px;
  margin: 15px;
  font-size: 14px;
  text-decoration: none;
}

.button--inverted {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--asu-maroon);
  border-radius: 4px;
  background-color: white;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--asu-maroon);
  width: 110px;
  height: 25px;
  padding: 0 10px;
}

.button--learn-more {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  background-color: #000000;
  font-weight: 700;
  color: white;
  width: 110px;
  height: 30px;
  padding: 0 10px;
  text-decoration: none;
}

@media only screen and (max-width: 1000px),
  only screen and (max-device-width: 1000px)
  and (orientation: landscape) {
  .button--inverted {
    font-size: 1.125rem;
  }
}
@media (hover: hover) {
  .button--primary:hover {
    background-color: var(--asu-maroon-hover);
  }
  .button--inverted:hover {
    border: 1px solid white;
    background-color: var(--asu-maroon);
    color: white;
  }

}
@media (hover: none) {
  /* disable hover/focus outlines on touch devices */
  button {
    outline: 0;
  }
}
</style>

<style scoped>
.progress {
  position: fixed;
  top: 50%;
  left: 50%;
}
.layout-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}
.header--link {
  padding: 0 30px;
  color: white;
  text-decoration: none;
}
.map-wrapper {
  height: 100vh;
  width: 75%;
  position: relative;
  /* position: relative to give context to ContinentSummary child, which is position: absolute */
}
.no-results {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  font-weight: 700;
}

.map-wrapper .zoom-out-button {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: none;
  height: 30px;
  padding: 0 15px;
}
.map-wrapper .zoom-out-button i {
  font-size: 12px;
  margin-left: 6px;
}
.zoom-out-svg {
  fill: white;
  width: 14px;
}
@media (max-width: 769px) {
  .map-wrapper {
    width: 60%;
  }
}
@media only screen and (max-width: 1000px),
  only screen and (max-width: 1000px)
  and (orientation: landscape) {
  .scrollable {
    overflow-y: auto;
    height: 100vh;
  }
  .layout-container {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .map-wrapper {
    width: 100%;
    height: 65vh;
  }
  .mobile-header {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .asu-logo {
    height: 60px;
  }
}
</style>
