<template>
  <div class="overlay">
    <div class="country" data-test="country-details-main-div">
      <div v-if="!isMobile" class="country-header">
        <a @click="$router.go(-1)" data-test="country-details-back-arrow">
          <i class="fas fa-arrow-left arrow-icon"></i>
          <h1 class="country-details-back-text">Back</h1>
        </a>
      </div>
      <div v-else class="country-header-mobile">
        <img
          src="../assets/asu_sunburst_rgb_maroongold_150ppi.png"
          class="asu-logo"
        />
      </div>
      <NavBreadcrumbs />
      <div class="country-information" data-scrolls-header>
        <CountryOverview />
        <h2 v-if="noActivitiesFound" class="none-found">
          As part of ASU's global engagement design pillar, the university is
          constantly expanding its global reach. Check back regularly to see
          updates on our global activities.
        </h2>
        <div v-else>
          <div class="bucket-selector" ref="bucketContainer">
            <button
              v-for="bucket in countryBucketSummary"
              :key="bucket[0]"
              ref="bucketButtons"
              @click="onClick(bucket[0])"
              class="bucket"
              :class="{ active: currentBucket === bucket[0] }"
              data-test="country-details-bucket-summary"
            >
              {{ bucket[0] }}
            </button>
          </div>
          <div class="bucket-data">
            <div class="summary-container">
              <hr class="summary-container--line-break" />
              <h5
                class="bucket-description"
                data-test="country-details-bucket-description"
              >
                {{ currentBucket && bucketsData[currentBucket].desc }}
              </h5>
              <p class="bucket-disclaimer">
                *Data shown is comprised of multiple data sets and date ranges.
              </p>
              <p
                v-if="
                  currentBucket === 'Students' && selectedCountryCode === 'BM'
                "
                class="bucket-disclaimer"
              >
                *Represents data for Bermuda and historical ASU data for the
                British Indian Ocean Territory
              </p>
              <p
                v-if="
                  currentBucket === 'Students' && selectedCountryCode === 'AW'
                "
                class="bucket-disclaimer"
              >
                *Represents data for Aruba and historical ASU data for the
                Netherland Antilles
              </p>
              <div
                v-if="
                  currentBucket &&
                  bucketsData[currentBucket].contact &&
                  !isMobile
                "
                class="summary-container--more-info"
              >
                <h5>Looking for more info?</h5>
                <a
                  v-for="contact in bucketsData[currentBucket].contact"
                  :key="contact.label"
                  :href="`mailto:${contact.email}`"
                  class="button--primary"
                >
                  {{ contact.label }}
                </a>
              </div>
            </div>
            <div class="card-container">
              <div
                v-if="
                  ['Travel', 'Students', 'Education and Service'].indexOf(
                    currentBucket
                  ) !== -1
                "
                class="card-container2"
              >
                <TravelChart
                  v-if="currentBucket === 'Travel'"
                  :activities="activitiesInSelectedBucket"
                />
                <StudentsChart
                  v-else-if="currentBucket === 'Students'"
                  :activities="activitiesInSelectedBucket"
                />
                <EducationAndServiceChart
                  v-else-if="currentBucket === 'Education and Service'"
                  :activities="activitiesInSelectedBucket"
                />
              </div>
              <div class="stacked-card-containers" v-else>
                <CountryDetailsBucketOverview
                  v-if="
                    currentBucket === 'Partnerships' &&
                    cintanaPartnersInCountry.length > 0
                  "
                  class="card-container2"
                  title="ASU-Cintana Institutions"
                  :activities="cintanaPartnersInCountry"
                  :bucketData="bucketsData[currentBucket]"
                  :bucketName="currentBucket"
                  featured="true"
                >
                <template #bottom>
                  <a class="cintana-link" href="https://global.asu.edu/what-we-do-/asu-cintana-alliance">Learn More</a>
                </template>
                </CountryDetailsBucketOverview>
                <CountryDetailsBucketOverview
                  class="card-container2"
                  v-if="(currentBucket === 'Partnerships'
                      ? activitiesWithoutCintanaPartners
                      : activitiesInSelectedBucket).length > 0"
                  :activities="
                    currentBucket === 'Partnerships'
                      ? activitiesWithoutCintanaPartners
                      : activitiesInSelectedBucket
                  "
                  :bucketData="bucketsData[currentBucket]"
                  :bucketName="currentBucket"
                />
              </div>
            </div>
            <div
              v-if="
                currentBucket &&
                bucketsData[currentBucket].contactEmail &&
                isMobile
              "
              class="summary-container--more-info"
            >
              <h5>Looking for more info?</h5>
              <a
                :href="`mailto:${bucketsData[currentBucket].contactEmail}`"
                class="button--primary"
                >Contact Us</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { convertSpacesToDashes } from "../helpers/helpers";
import CountryOverview from "./CountryOverview.vue";
import NavBreadcrumbs from "./NavBreadcrumbs.vue";
import TravelChart from "./charts/TravelChart.vue";
import StudentsChart from "./charts/StudentsChart.vue";
import EducationAndServiceChart from "./charts/EducationAndServiceChart.vue";
import CountryDetailsBucketOverview from "./CountryDetailsBucketOverview.vue";

export default {
  components: {
    CountryOverview,
    NavBreadcrumbs,
    TravelChart,
    StudentsChart,
    EducationAndServiceChart,
    CountryDetailsBucketOverview,
  },
  computed: {
    ...mapState([
      "selectedCountry",
      "selectedCountryCode",
      "selectedContinent",
      "bucketSummaryData",
      "allData",
      "buckets",
      "bucketsData",
      "currentBucket",
      "isMobile",
      "cintanaPartners",
    ]),
    ...mapGetters(["isCintanaPartner"]),
    countryBucketSummary() {
      const buckets = [];
      if (this.bucketSummaryData) {
        Object.keys(this.bucketSummaryData).forEach((bucket) => {
          const numActivities =
            this.bucketSummaryData[bucket].continents[this.selectedContinent]
              .countries[this.selectedCountryCode];
          if (numActivities > 0) {
            buckets.push([bucket, numActivities]);
          }
        });
      }
      return buckets;
    },
    activitiesByBucket() {
      const parsed = {};
      // add buckets from bucket array so they will always be in the same order
      this.buckets.forEach((bucket) => {
        parsed[bucket] = [];
      });
      this.allData.forEach((record) => {
        if (record.country_code === this.selectedCountryCode) {
          parsed[record.bucket].push(record);
        }
      });
      return parsed;
    },
    cintanaPartnersInCountry() {
      return this.activitiesInSelectedBucket.filter(this.isCintanaPartner);
    },
    activitiesWithoutCintanaPartners() {
      return this.activitiesInSelectedBucket.filter(
        (activity) => !this.isCintanaPartner(activity)
      );
    },
    activitiesInSelectedBucket() {
      if (this.currentBucket === "Research") {
        if (this.activitiesByBucket[this.currentBucket].length > 1) {
          // eslint-disable-next-line
          this.activitiesByBucket[this.currentBucket].length = 1;
        }
      }
      return this.activitiesByBucket[this.currentBucket];
    },
    filteredActivitiesByBucket() {
      const filtered = {};
      Object.keys(this.activitiesByBucket).forEach((bucket) => {
        if (this.activitiesByBucket[bucket].length > 0) {
          filtered[bucket] = [];
          filtered[bucket].push(...this.activitiesByBucket[bucket]);
        }
      });
      return filtered;
    },
    noActivitiesFound() {
      if (
        this.countryBucketSummary.length <= 0 ||
        Object.keys(this.activitiesByBucket).length === 0
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    countryBucketSummary: function () {
      this.resolveBucketPath();
    },
  },
  mounted() {
    this.resolveBucketPath();
    this.$nextTick(() => {
      const firstActiveBucket = this.$refs.bucketButtons?.find((bucket) =>
        bucket.classList.contains("active")
      );

      if (firstActiveBucket) {
        this.$refs.bucketContainer.scrollLeft = firstActiveBucket.offsetLeft;
      }
    });
  },
  methods: {
    resolveBucketPath() {
      if (this.countryBucketSummary.length > 0) {
        if (
          !this.currentBucket ||
          !this.countryBucketSummary.some((b) => b[0] === this.currentBucket)
        ) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              ...this.$router.currentRoute.query,
              activity: this.countryBucketSummary[0][0],
            },
          });
        }
      }
    },
    onClick(bucket) {
      if (bucket !== this.currentBucket) {
        this.$router.replace({
          path: `/${convertSpacesToDashes(
            this.selectedContinent
          )}/${convertSpacesToDashes(this.selectedCountry)}`,
          query: {
            activity: bucket,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #ffffff;
}
.country {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.country-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  padding-left: 40px;
  background: #ffffff;
}
.country-header h1 {
  margin: 25px;
  font-weight: 500;
}
.country-header a {
  cursor: pointer;
}
.country-details-back-text {
  display: inline-block;
}
.arrow-icon {
  font-size: 25px;
}
.country-information {
  overflow: hidden;
  overflow-y: scroll;
}
.none-found {
  display: flex;
  justify-content: center;
  margin: 40px;
  color: #000000;
}
.bucket-selector {
  display: flex;
  padding-top: 40px;
  border-bottom: 1px solid #eddce2;
  box-sizing: border-box;
  overflow-x: auto;
  background: #ffffff;
}
.bucket {
  padding: 15px 0;
  margin: 0 20px;
  font-weight: 500;
  font-size: 1.125rem;
  color: #000000;
  white-space: nowrap;
}
.bucket.active {
  border-bottom: 3px solid var(--asu-maroon);
  padding-bottom: 12px;
  outline: none;
  color: var(--asu-maroon);
  font-weight: 700;
}
.bucket.active:hover {
  cursor: default;
}
.bucket-data {
  padding: 40px;
  display: flex;
  background-color: #ffffff;
  background-image: url("../assets/imgs/topo_white.png");
  background-repeat: repeat;
}
.summary-container {
  width: 20%;
  min-width: 230px;
  max-width: 300px;
}
.summary-container--line-break {
  background-color: var(--asu-gold);
  border: 0;
  width: 40px;
  height: 5px;
  margin: 0;
}
.bucket-description {
  color: #000000;
  font-size: 18px;
  margin-top: 20px;
}
.bucket-disclaimer {
  color: var(--asu-maroon);
  font-size: 12px;
  margin-top: 20px;
}
.summary-container--more-info {
  margin-top: 180px;
}
.summary-container--more-info h5 {
  font-size: 14px;
  color: #000000;
  background-color: var(--asu-gold);
  display: inline-block;
  padding: 8px;
}
.summary-container--more-info a {
  height: 30px;
  width: fit-content;
  border-radius: 15px;
  margin: 20px 0;
}
.card-container {
  width: 100%;
  margin-left: 40px;
}
.card-container2 {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 30px;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
}
.stacked-card-containers {
  display: flex;
  flex-direction: column;
}
.stacked-card-containers > * {
  margin-bottom: 20px;
}
hr {
  height: 1px;
  border: 0;
  background-color: #eddce2;
  margin: 0 45px;
}
.card-container svg {
  width: 25px;
  fill: #ffc627;
  margin: 20px;
}
.card-container h2 {
  font-weight: 400;
}

.cintana-link {
  color: var(--asu-maroon);
}

@media only screen and (max-width: 1000px),
  only screen and (max-device-width: 1000px) and (orientation: landscape) {
  .overlay {
    overflow-y: auto;
  }
  .asu-logo {
    height: 46px;
    margin: 7px 0;
  }
  .country-header-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 100%;

    background-color: #ffffff;
  }
  .bucket-selector {
    padding-top: 30px;
    background: linear-gradient(90deg, #ffffff 0%, #ffffff 60%, #eddce2 100%);
  }
  .bucket {
    margin: 0 10px;
    font-size: 1.5rem;
  }
  .bucket-data {
    flex-direction: column;
    padding: 10px;
  }
  .summary-container {
    /* margin-top: 30px; */
    width: 100%;
    min-width: 180px;
    max-width: 500px;
    margin: 30px 10px 0;
  }
  .card-container {
    margin: 20px 0 0;
  }
  .summary-container--more-info {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .summary-container--more-info h5 {
    font-size: 18px;
    padding: 8px 15px;
  }
}
</style>
