import cloneDeep from 'lodash.clonedeep';

import { isoCodeToCountry } from './countryCodeLookup';
import { continentNames, continentLatLong } from './continentData';

const continents = Object.values(continentNames);

function continentToGeoJSONPoint(continent, continentProps) {
  if (!continentLatLong[continent]) {
    throw new Error(`Missing Long and Lat for: ${continent}`);
  }

  return {
    type: 'Feature',
    properties: {
      name: continent,
      total: continentProps.total,
    },
    geometry: {
      type: 'Point',
      coordinates: continentLatLong[continent],
    },
  };
}

function countryToGeoJSONPoint(isoCode, total) {
  const countryInfo = isoCodeToCountry[isoCode];
  if (!countryInfo || !countryInfo.latLong) {
    // throw new Error(`Missing Long and Lat for: ${country}`);
    console.log(`Missing Long and Lat code for: ${isoCode}`);
    return null;
  }

  const { latLong, name } = countryInfo;

  // reverse the coordinates since they were in the wrong position for mapbox
  const [lat, long] = latLong;
  const coordinates = [long, lat];

  return {
    type: 'Feature',
    properties: {
      name,
      total,
    },
    geometry: {
      type: 'Point',
      coordinates,
    },
  };
}

function buildContinentMarkers(storeRef) {
  const features = [];
  let continentDetails;

  if (storeRef.getters.selectedSummaryData && storeRef.state.selectedBuckets.length > 0) {
    continentDetails = storeRef.getters.selectedSummaryData;
  }
  if (continentDetails) {
    Object.keys(continentDetails).forEach((continent) => {
      if (continentDetails[continent].total > 0) {
        features.push(continentToGeoJSONPoint(continent, continentDetails[continent]));
      }
    });
  }
  return {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
    },
    features,
  };
}

function buildCountryMarkers(storeRef) {
  const features = [];
  const countryDetails = storeRef.getters.selectedSummaryData;
  if (countryDetails) {
    Object.keys(countryDetails).forEach((countryCode) => {
      if (countryDetails[countryCode] > 0) {
        const GeoJSONPoint = countryToGeoJSONPoint(countryCode, countryDetails[countryCode]);
        if (GeoJSONPoint) {
          features.push(GeoJSONPoint);
        }
      }
    });
  }
  return {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
    },
    features,
  };
}

function buildSummaryData(data, buckets) {
  const summaryData = {};
  const continentsObj = {};

  continents.forEach((continent) => {
    continentsObj[continent] = {
      total: 0,
      countries: {},
    };
  });

  buckets.forEach((bucket) => {
    summaryData[bucket] = {
      total: 0,
      continents: cloneDeep(continentsObj),
    };
  });

  data.forEach((record) => {
    if (
      !summaryData[record.bucket] ||
      !summaryData[record.bucket].continents[record.region_name] ||
      !summaryData[record.bucket].continents[record.region_name].countries
    ) {
      console.log('Error with record', record);
      // throw new Error("ERROR IN DATA")
    } else {
      let numActivities = 1;
      if (record.bucket === 'Global Launch') {
        numActivities = record.student_count;
      } else if (record.bucket === 'Students') {
        numActivities = record.count_distinct_student_id;
      } else if (record.bucket === 'Research') {
        numActivities = record.number_of_agreements;
      } else if (record.bucket === 'Education and Service') {
        numActivities = record.learners_count;
      }
      summaryData[record.bucket].total += numActivities;
      summaryData[record.bucket].continents[record.region_name].total += numActivities;
      const { countries } = summaryData[record.bucket].continents[record.region_name];

      if (!countries[record.country_code]) {
        countries[record.country_code] = numActivities;
      } else {
        countries[record.country_code] += numActivities;
      }
    }
  });

  return summaryData;
}

/**
 * Builds an object of Cintana partners by region
 * example:
 *
 * {
 *  total: 10, // total number of cintana partners
 *  continents: {
 *   Africa: {
 *    total: 2, // total number of cintana partners in Africa
 *    countries: {
 *      'South Africa': 2, // total number of cintana partners in South Africa
 *    }
 *   }
 * }
 *
 */
function buildCintanaPartnersByRegion(allData, cintanaPartners) {
  const result = {
    total: 0,
    continents: {},
  };

  continents.forEach((continent) => {
    result.continents[continent] = {
      total: 0,
      countries: {},
    };
  });

  allData.forEach((record) => {
    if (record.bucket !== 'Partnerships' || !record.in_cintana_alliance) {
      return
    }
    result.total += 1;
    if (!result.continents[record.region_name]) {
      result.continents[record.region_name] = {
        total: 0,
        countries: {},
      };
    }
    result.continents[record.region_name].total += 1;
    const { countries } = result.continents[record.region_name];

    if (!countries[record.country_code]) {
      countries[record.country_code] = 1;
    } else {
      countries[record.country_code] += 1;
    }
  });
  return result;
}

export {
  buildContinentMarkers,
  buildCountryMarkers,
  buildSummaryData,
  buildCintanaPartnersByRegion,
};
